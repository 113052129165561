import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import { useMediaQuery } from "react-responsive"; // Import useMediaQuery from react-responsive

const FilterComponent = ({
  selectedFilter,
  setSelectedFilter,
  setStartDate,
  startDate,
  endDate,
  setEndDate,
  getUserPayments,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile view breakpoint (you can adjust the value as needed)

  const applyDefaultFilter = () => {
    const now = new Date();
    const start = new Date();
    start.setDate(now.getDate() - 7);
    const startISOString = start.toISOString();
    const endISOString = now.toISOString();
    setStartDate(startISOString);
    setEndDate(endISOString);
    setSelectedFilter("thisWeek");
    getUserPayments(startISOString, endISOString);
    setIsModalVisible(false);
  };

  const handleFilterChange = (filter) => {
    const now = new Date();
    let start = null,
      end = null;

    switch (filter) {
      case "thisWeek":
        start = new Date();
        start.setDate(now.getDate() - 7);
        end = now;
        break;
      case "lastWeek":
        start = new Date();
        start.setDate(now.getDate() - 14);
        end = new Date();
        end.setDate(now.getDate() - 7);
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = now;
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "all":
        break;
      default:
        return;
    }

    const startISOString = start ? start.toISOString() : null;
    const endISOString = end ? end.toISOString() : null;
    setStartDate(startISOString);
    setEndDate(endISOString);
    setSelectedFilter(filter);
    getUserPayments(startISOString, endISOString);
    setIsModalVisible(false);
  };

  const handleCustomDateSearch = () => {
    if (!startDate || !endDate) {
      message.error("Please select both start and end dates.");
      return;
    }
    getUserPayments(startDate, endDate);
    setIsModalVisible(false);
  };

  return (
    <>
      {/* Button to trigger the modal, only visible on mobile view */}
      {isMobile && (
        <button  onClick={() => setIsModalVisible(true)} className="btn btn-sm btn-dark mb-3 w-100 p-2">
          Filter Options
        </button>
      )}

      {/* Filter modal, only visible on mobile view */}
      {isMobile && (
        <Modal
          title="Filter Payments"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <div className="d-flex flex-column">
            <select
              className="form-select mb-3"
              value={selectedFilter}
              onChange={(e) => handleFilterChange(e.target.value)}
            >
              <option value="thisWeek">This Week</option>
              <option value="lastWeek">Last Week</option>
              <option value="thisMonth">This Month</option>
              <option value="lastMonth">Last Month</option>
              <option value="all">All</option>
            </select>
            <input
              type="date"
              className="form-control mb-3"
              value={startDate ? new Date(startDate).toISOString().substring(0, 10) : ""}
              onChange={(e) => setStartDate(e.target.value ? new Date(e.target.value).toISOString() : null)}
            />
            <input
              type="date"
              className="form-control mb-3"
              value={endDate ? new Date(endDate).toISOString().substring(0, 10) : ""}
              onChange={(e) => setEndDate(e.target.value ? new Date(e.target.value).toISOString() : null)}
            />
            <div className="d-flex justify-content-between">
              <button className="btn btn-sm btn-primary" onClick={handleCustomDateSearch}>
                Search
              </button>
              <button className="btn btn-sm btn-danger" onClick={applyDefaultFilter}>
                Clear Filters
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* Directly visible filter options on desktop */}
      {!isMobile && (
        <div className="filters d-flex align-items-center mb-3">
            <select
                className="form-select me-3"
                value={selectedFilter}
                onChange={(e) => handleFilterChange(e.target.value)}
            >
                <option value="thisWeek">This Week</option>
                <option value="lastWeek">Last Week</option>
                <option value="thisMonth">This Month</option>
                <option value="lastMonth">Last Month</option>
                <option value="all">All</option>
            </select>
            <input
                type="date"
                className="form-control me-2 w-50"
                value={startDate ? new Date(startDate).toISOString().substring(0, 10) : ""}
                onChange={(e) => setStartDate(e.target.value ? new Date(e.target.value).toISOString() : null)}
            />
            <input
                type="date"
                className="form-control me-2 w-50"
                value={endDate ? new Date(endDate).toISOString().substring(0, 10) : ""}
                onChange={(e) => setEndDate(e.target.value ? new Date(e.target.value).toISOString() : null)}
            />
            <button className="btn btn-primary me-2" onClick={handleCustomDateSearch}>
                Search
            </button>
            <button className="btn btn-danger" onClick={applyDefaultFilter}>
                Clear
            </button>
        </div>
      )}
    </>
  );
};

export default FilterComponent;
