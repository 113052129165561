import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useSelector } from "react-redux";
import "./Wallet.css";
import axios from "axios";
import { message, Spin } from "antd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HistoryIcon from "@mui/icons-material/History";
import SavingsIcon from '@mui/icons-material/Savings';
import { submitPaymentForm } from "../pages/ProductInfoMobile/submitPaymentForm";
import FilterComponent from "./components/FilterComponent";

const Wallet = () => {
  const { balance } = useSelector((state) => state.data);
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(0);
  const [form, setForm] = useState(null);
  const [payments, setPayments] = useState(null);
  const [cashbackHistory, setCashbackHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [gateway, setGatewat] = useState("BARCODE");


  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("thisWeek");

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
  
    setOrderId(orderId);
  };;

  const getUserPayments = async (start, end) => {
    setLoading(true);
    try {
      const res = await axios.post(
        "/api/payment/get-user-payments",
        {
          email: user?.email,
          startDate: start,
          endDate: end,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setPayments((res.data.data).reverse());
      } else {
        setPayments(null);
        message.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
      setPayments(null);
      message.error("Failed to fetch payments.");
    } finally {
      setLoading(false);
    }
  };

  async function getUserCashbackHistory() {
    setLoading(true);
    try {
      const res = await axios.post(
        "/api/payment/get-user-cashback-history",
        {
          email: user?.email,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setCashbackHistory((res.data.data).reverse());
      } 
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    generateOrderId();
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [e.target.name]: e.target.value });
    if (name === "amount") {
      if (value < 50) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }

  useEffect(() => {
    if (user?.email) {
      setForm((prev) => ({
        ...prev,
        email: user?.email,
        mobile: user?.mobile,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (user && tab === 0) {
      applyDefaultFilter();
    }
    if(user && tab === 2){
      getUserCashbackHistory();
    }
  }, [user, tab]);

  function handleGateway() {  
    if (gateway === "BARCODE") {
      handleBarcode();
    } else if (gateway === "PAYU") {
      handlePayu();
    }
  }

  async function handlePayu() {
    try {
      setLoading(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: form?.amount,
        product_name: user?.sponsor || "noSponsor",
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        success_URL: "https://topupplayground.com/api/wallet/success",
        failure_URL: "https://topupplayground.com/api/wallet/failure",
        // success_URL: "http://localhost:8080/api/wallet/success",
        // failure_URL: "http://localhost:8080/api/wallet/failure",
        udf1: "",
        udf2: "",
        udf3: "",
        udf4: "",
        udf5: "",
      };

      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoading);
      }, 1500);
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoading(false);
    }
  }

  async function handleBarcode() {
    if (form?.amount < 1) {
      return message.error("Enter amount greater than 20");
    }
    setLoading(true);
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: form?.amount,
        txn_note: "Wallet Topup Note",
        product_name: user?.sponsor || "noSponsor",
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/wallet/status?orderId=${orderId}`,
      };
      const response = await axios.post("/api/wallet/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        console.log(response.data.message);
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally {
      setLoading(false);
    }
  }

  const applyDefaultFilter = () => {
    const now = new Date();
    const start = new Date();
    start.setDate(now.getDate() - 7);
    const startISOString = start.toISOString();
    const endISOString = now.toISOString();
    setStartDate(startISOString);
    setEndDate(endISOString);
    setSelectedFilter("thisWeek");
    getUserPayments(startISOString, endISOString);
  };

  return (
    <Layout>
      <DashboardLayout>
        <div className="wallet-dash">
          <div className="wallet-dash-card text-white fs-2 fw-bold bg-transparent border w-100 wallet-dash-card-one">
            Balance: {parseFloat(balance).toFixed(2)}
          </div>
          <div className="shadow wallet-dash-card" onClick={() => setTab(1)}>
            <AddCircleIcon className="icon me-2" />
            Add Money
          </div>
          <div className="shadow wallet-dash-card" onClick={() => setTab(0)}>
            <HistoryIcon className="icon me-2" />
            Transaction History
          </div>
          <div className="shadow wallet-dash-card" onClick={() => setTab(2)}>
            <SavingsIcon className="icon me-2" />
            Cashback History
          </div>
        </div>

        {tab !== 1 && <FilterComponent
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            getUserPayments={getUserPayments}
        />}

        {/* USER HISTORY */}
        {tab === 0 && (
          <div className="rounded-4 p-2 bg-white overflow-scroll">
              {!loading?
                (payments?
                  <table className="table table-rounded w-100">
                    <thead>
                      <tr>
                        <th className="no-wrap">Sr No</th>
                        <th>OrderId</th>
                        <th>Product</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments &&
                        payments.map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.orderId}</td>
                              <td>{item?.paymentType === "wallet"? "Add Money" : item?.productInfo}</td>
                              <td className="no-wrap">
                                {new Date(item?.createdAt).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                })}
                              </td>
                              <td>{item?.amount}</td>
                              <td>{item?.paymentType}</td>
                              <td
                                className={
                                  item?.status === "pending"
                                    ? "text-warning"
                                    : item?.status === "approved"
                                    ? "text-success"
                                    : item?.status === "rejected"
                                    ? "text-danger"
                                    : ""
                                }
                              >
                                {item?.status}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  :
                  <div className="center py-5 text-secondary">No wallet history available</div>
                )
              :
                <div className="py-5 center"><Spin size="large" /></div>
              }
          </div>
        )}

        {/* CASHBACK HISTORY */}
        {tab === 2 && (
          <div className="rounded-4 p-2 bg-white overflow-scroll">
                {!loading?
                  (cashbackHistory?
                  <table className="table table-rounded w-100">
                    <thead>
                      <tr>
                        <th className="no-wrap">#</th>
                        <th className="no-wrap">Cashback ID</th>
                        <th className="no-wrap">Referral Name</th>
                        {/* <th>Before</th> */}
                        <th>Amount</th>
                        {/* <th>After</th> */}
                        <th>Type</th>
                        <th>Date</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cashbackHistory &&
                        cashbackHistory.map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td><small>{item?.orderId}</small></td>
                              <td className="no-wrap"><small>{item?.referralName}</small></td>
                              {/* <td>{item?.balanceBefore}</td> */}
                              <td className="fw-bold text-success">+{item?.price}</td>
                              {/* <td>{item?.balanceAfter}</td> */}
                              <td className="fw-bold text-success"><small>{item?.p_info}</small></td>
                              <td className="no-wrap">
                                <small>{new Date(item?.createdAt).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                })}</small>
                              </td>
                              <td><button className="btn btn-sm btn-secondary no-wrap">View</button></td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  :
                  <div className="center py-5 text-secondary">No cashback history available</div>
                  )
                :
                  <div className="py-5 center"><Spin size="large" /></div>
                }
                
            
          </div>
        )}

        {/* BARCODE  */}
        {tab === 1 && (
          <div className="add-money w-100">
            <div className="w-100 center">
              <div className="w-100 border p-4 bg-light rounded-4">
                <div className="">
                  <label className="text-dark fw-bold mb-3" htmlFor="">
                    Amount
                  </label>
                  <input
                    name="amount"
                    value={form?.amount}
                    onChange={handleChange}
                    className={`form-control p-3 ${form.amount < 20 && "border-danger"}`}
                    placeholder="Enter Amount"
                    type="number"
                  />
                  {error && form?.amount < 20 && (
                    <div className="text-danger mt-3">
                      Enter amount greater or equal to 20rs
                    </div>
                  )}
                </div>
                <button
                  onClick={handleGateway}
                  className="btn btn-success mt-3 p-3"
                  disabled={!form?.amount || loading}
                >
                  {loading? "Loading..." : "Pay Online"}
                  {/* Wallet is Temporarily Disabled */}
                </button>
              </div>
            </div>
          </div>
        )}
      </DashboardLayout>
    </Layout>
  );
};

export default Wallet;
