import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminQueries.css";
import axios from "axios";
import { message, Modal } from "antd";
import { Tooltip } from "antd";

const AdminPayments = () => {
  const [originalPaymentsData, setOriginalPaymentsData] = useState(null);
  const [allPayments, setAllPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [searchEmail, setSearchEmail] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");

  const currentDate = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState(currentDate);
  const [toDate, setToDate] = useState(currentDate);
  
  // Modal visibility states
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false); // Filter modal visibility for mobile

  function handleClearFilter() {
    setAllPayments(originalPaymentsData);
    setOrderId("");
    setSearchEmail("");
    setSelectedMonth("");
    setFromDate(currentDate);
    setToDate(currentDate);
  }

  const getAllPaymentByDateRange = async (fromDate, toDate) => {
    setLoading(true);
    try {
      const query = `?fromDate=${fromDate}&toDate=${toDate}`;
      const res = await axios.get(`/api/admin/get-all-payments${query}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
  
      if (res.data.success) {
        setAllPayments(res.data.data.reverse());
        setOriginalPaymentsData(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error("Failed to load payments");
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    // Fetch current date's data on component load
    getAllPaymentByDateRange(fromDate, toDate);
  }, []); // Only runs once when the component mounts

  useEffect(() => {
    // Automatically fetch data when fromDate or toDate changes
    if (fromDate && toDate) {
      getAllPaymentByDateRange(fromDate, toDate);
    }
  }, [fromDate, toDate]);

  const fetchAllPaymentsByMonth = async (month) => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/admin/admin-get-all-orders?month=${month}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
  
      if (res.data.success) {
        setAllPayments(res.data.data.reverse());
        setOriginalPaymentsData(res.data.data);
      } else {
        message.error(res.data.message || "No data found for the selected month.");
      }
    } catch (error) {
      message.error("Failed to fetch data for the selected month.");
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    if (month) {
      fetchAllPaymentsByMonth(month);
    } else {
      // Reset to fromDate and toDate filtering
      getAllPaymentByDateRange(fromDate, toDate);
    }
  };

  //! Search
  const handleSearch = () => {
    if (originalPaymentsData) {
      const filteredUsers = originalPaymentsData.filter((order) => {
        const orderIdMatch = order.orderId && order.orderId.includes(orderId);
        const emailMatch =
          order.email &&
          order.email.toLowerCase().includes(searchEmail.toLowerCase());
        
        return (
          orderIdMatch && emailMatch
        );
      });
      setAllPayments(filteredUsers);
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [orderId, searchEmail]);

  if (loading)
    return (
      <AdminLayout>
        <div className="w-100 center">
          <div className="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </AdminLayout>
  );

  return (
    <AdminLayout>
      <div className="page-title px-3">
        <h3 className="m-0">Payments</h3>
        <h6>Total Payments - {allPayments?.length}</h6>
      </div>
      <hr />
      {/* Mobile Filter Button */}
      <div className="d-block d-md-none mb-3">
        <button
          className="btn btn-primary w-100"
          onClick={() => setIsFilterModalVisible(true)}
        >
          Filters
        </button>
      </div>

      {/* Desktop Filter Inputs */}
      <div className="d-none d-md-flex gap-3 mb-3">
        <input
          type="text"
          placeholder="Order ID"
          className="form-control"
          value={orderId}
          onChange={(e) => setOrderId(e.target.value)}
        />
        <input
          type="text"
          placeholder="Email"
          className="form-control"
          value={searchEmail}
          onChange={(e) => setSearchEmail(e.target.value)}
        />
        {/* From Date Input */}
        <input
          type="date"
          className="form-control"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
        {/* To Date Input */}
        <input
          type="date"
          className="form-control"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        />
        <select
          className="form-control"
          value={selectedMonth}
          onChange={(e) => handleMonthChange(e.target.value)}
        >
          <option value="">Select Month</option>
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i + 1} value={i + 1}>
              {new Date(0, i).toLocaleString("default", { month: "long" })}
            </option>
          ))}
        </select>
        <button
          className="btn btn-secondary w-100"
          onClick={handleClearFilter}
        >
          Clear Filters
        </button>
      </div>

      {/* Mobile Filter Modal */}
      <Modal
        title="Filters"
        visible={isFilterModalVisible}
        onCancel={() => setIsFilterModalVisible(false)}
        footer={null}
        width={300}
        centered
      >
        <div className="d-flex flex-column gap-3">
          <input
            type="text"
            placeholder="Order ID"
            className="form-control"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
          />
          <input
            type="text"
            placeholder="Email"
            className="form-control"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
          />
          {/* From Date Input */}
          <input
            type="date"
            className="form-control"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          {/* To Date Input */}
          <input
            type="date"
            className="form-control"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
          <select
            className="form-control"
            value={selectedMonth}
            onChange={(e) => handleMonthChange(e.target.value)}
          >
            <option value="">Select Month</option>
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {new Date(0, i).toLocaleString("default", { month: "long" })}
              </option>
            ))}
          </select>
          <button
            className="btn btn-secondary w-100"
            onClick={handleClearFilter}
          >
            Clear Filters
          </button>
        </div>
      </Modal>

      <div className="admin-queries">
        <table className="table query-table">
          <thead>
            <tr>
              <th>TxnId</th>
              <th>Email</th>
              <th>Amount</th>
              <th>Mobile</th>
              <th>Date</th>
              <th>Product</th>
              <th>Type</th>
              <th>Status</th>
            </tr>
          </thead>
          {loading ? (
            <div className="loader-container text-center">
              <span className="loader"></span>
            </div>
          ) : (
            <tbody>
              {allPayments &&
                allPayments
                  ?.filter((item) =>
                    item?.email.includes(search?.toLowerCase())
                  )
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <small>
                            {item?.orderId}
                          </small>
                        </td>
                        <td>
                          <small>{item?.email}</small>
                        </td>
                        <td>
                          <span className="fw-bold">{item?.amount}₹</span>
                        </td>
                        <td>
                          <small>{item?.mobile}</small>
                        </td>
                        <td className="no-wrap">
                          <small>
                            {new Date(item?.createdAt).toLocaleString(
                              "default",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )}
                          </small>
                        </td>
                        <td className="no-wrap ">
                          <small>{item?.paymentType === "wallet"? "Add Money" : item?.productInfo}</small>
                        </td>
                        <td>{item?.paymentType}</td>
                        <td
                          className={`${
                            (item?.status)?.toLowerCase() === "success"
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          {(item?.status?.toLowerCase())}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          )}
        </table>
      </div>
    </AdminLayout>
  );
};

export default AdminPayments;
