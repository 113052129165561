import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import { setUser } from "../redux/features/userSlice";
import "./ReferAndEarn.css";
import getUserData from "../utils/userDataService";

const ReferAndEarn = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [inviteCode, setInviteCode] = useState("");
  const [amount, setAmount] = useState("");
  const [rechargeAmount, setRechargerAmount] = useState("");
  const [loading, setLoading] = useState(false);

  async function getAmount() {
    try {
      const res = await axios.get("/api/refer/get-amount", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAmount(res.data.data.amount);
        setRechargerAmount(res.data.data.rechargeAmount);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAmount();
  }, []);

  function generateReferCode(length) {
    const name = user?.fname; // Customer ka naam
    const firstName = name?.split(" ")[0]; // Space se naam ko split karke pehla part le lo
    const capitalFirstName = firstName && firstName.toUpperCase().slice(0, 4); // Pehla naam capital case mein
  
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = capitalFirstName; // Capitalize pehla naam ko result mein add karo
  
    // Baaki ka random refer code generate karna
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
  
    setInviteCode(result); // Invite code set karo
  }

  useEffect(() => {
    if (!user?.inviteCode) {
      generateReferCode(8);
    }
  }, []);

  function handleCopy() {
    try {
      const inviteCode = user?.inviteCode && user?.inviteCode;
      if (!inviteCode) return;

      const tempInput = document.createElement("input");
      tempInput.style.position = "absolute";
      tempInput.style.left = "-9999px";
      tempInput.value = inviteCode;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      alert("Copied to clipboard: " + inviteCode);
    } catch (error) {
      console.log(error);
    }
  }

  async function saveInviteCode() {
    setLoading(true);
    try {
      const res = await axios.post("/api/user/save-invite-code", {
        email: user?.email,
        inviteCode: inviteCode,
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        getUserData(dispatch)
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Layout>
      <DashboardLayout>
        <div className="refer-container">
          <div className="flex-wrap bg-light p-3 rounded-4">
            <h4 className="fw-bold text-dark mb-4">Your Invite Code</h4>
            <input
              className="form-control rounded p-3"
              type="text"
              disabled={loading || user?.inviteCode}
              value={
                user?.inviteCode ? user?.inviteCode : "Click on Generate Code"
              }
            />
            {user?.inviteCode ? (
              <button className="btn btn-success p-3 px-4 mt-3" onClick={handleCopy}>Copy</button>
            ) : (
              <button disabled={loading} className="btn btn-success p-3 px-4 mt-3" onClick={saveInviteCode}>
                {loading? "Loading..." : "Generate"}
              </button>
            )}
          </div>
          <div className="my-3 ">
            <p className="mb-0 highlight-reward my-1 text-dark" style={style}>You will earn 1% cashback on every transaction made by your referral.</p>
            <p className="mb-0 highlight-reward my-1 text-danger" style={style}><strong className="text-dark">Example:</strong> If your referral places an order worth ₹800, you will receive ₹8 as cashback.</p>
          </div>
          <div className="notice">
            <ul>
              <h4>Important:</h4>
              <li>1) Create your unique referral code</li>
              <li>2) Copy referral code</li>
              <li>
                3) Refer your friend(user) and let them sign up using your
                referral code
              </li>
              <li>
                4) When the user adds {rechargeAmount}₹ or more balance for the first time in wallet 
                then<span className="highlight-reward mx-2">you will get {amount}₹ as reward</span>
              </li>
              <li>
                Note - Only 1st time additions in wallet will be counted. For
                the second time it will not work.
                <br />
                I.e 1st payment to add money on wallet should be equal or
                greater than {rechargeAmount}₹
              </li>
            </ul>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

const style = {
  letterSpacing: '-0.2px',
  fontSize: '18px',
  fontWeight: '500'
}
export default ReferAndEarn;
