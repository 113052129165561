import { message, Modal } from "antd";
import AdminViewOrder from "./AdminViewOrder";
import AdminLayout from "./components/AdminLayout";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const AdminWalletHistory = () => {
  const navigate = useNavigate();
  const [allAdminWalletHistory, setAllAdminWalletHistory] = useState(null);
  const [nonFilteredWalletHistoryData, setNonFilteredWalletHistoryData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orderId, SetOrderId] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [selectedMonth, setSelectedMonth] = useState("");

  // Modal visibility states
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false); // Filter modal visibility for mobile

  function handleClearFilter() {
    setAllAdminWalletHistory(nonFilteredWalletHistoryData);
    SetOrderId("");
    setSearchEmail("");
    setSelectedDate("");
    setSelectedMonth("");
  }

  const getAllWalletHistory = async (date = null) => {
    setLoading(true);
    try {
      const query = date ? `?date=${date}` : ""; // Append date if provided
      const res = await axios.get(`/api/admin/admin-get-all-wallet-history${query}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllAdminWalletHistory(res.data.data.reverse());
        setNonFilteredWalletHistoryData(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error("Failed to load orders");
    } finally {
      setIsFilterModalVisible(false);
      setLoading(false);
    }
  };

  const handleViewOrder = (orderId) => {
    setSelectedOrderId(orderId);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedOrderId(null);
  };

  const handleSearch = () => {
    if (nonFilteredWalletHistoryData) {
      const filteredUsers = nonFilteredWalletHistoryData.filter((data) => {
        const orderIdMatch = data.orderId && data.orderId.includes(orderId);
        
        const emailMatch =
        data.email &&
        data.email.toLowerCase().includes(searchEmail.toLowerCase());
        const dateMatch =
        data.created &&
          (!selectedDate ||
            new Date(data.created).toISOString().split("T")[0] ===
              new Date(selectedDate).toISOString().split("T")[0]);
        const monthMatch =
        data.created &&
          (!selectedMonth ||
            new Date(data.created).getMonth() === Number(selectedMonth) - 1);

        return (
          orderIdMatch && emailMatch && dateMatch && monthMatch
        );
      });
      setAllAdminWalletHistory(filteredUsers);
    }
  };

  useEffect(() => {
    // Fetch orders based on selectedDate or current date by default
    const date = selectedDate;
    getAllWalletHistory(date);
  }, [selectedDate]);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [orderId, searchEmail, selectedDate, selectedMonth]);

  if (loading)
    return (
      <AdminLayout>
        <div className="w-100 center">
          <div className="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </AdminLayout>
    );

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="d-flex justify-content-between align-items-center px-3">
          <h3 className="m-0">Wallet History</h3>
          <h6 className="mb-0">Total Wallet History - {allAdminWalletHistory?.length}</h6>
        </div>
        <hr />

        {/* Mobile Filter Button */}
        <div className="d-block d-md-none mb-3">
          <button
            className="btn btn-primary w-100"
            onClick={() => setIsFilterModalVisible(true)}
          >
            Filters
          </button>
        </div>

        {/* Desktop Filter Inputs */}
        <div className="d-none d-md-flex gap-3 mb-3">
          <input
            type="text"
            placeholder="Order ID"
            className="form-control"
            value={orderId}
            onChange={(e) => SetOrderId(e.target.value)}
          />
          
          <input
            type="text"
            placeholder="Email"
            className="form-control"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
          />
          
          <input
            type="date"
            className="form-control"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
          <select
            className="form-control"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <option value="">Select Month</option>
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {new Date(0, i).toLocaleString("default", { month: "long" })}
              </option>
            ))}
          </select>
          <button
            className="btn btn-secondary w-100"
            onClick={handleClearFilter}
          >
            Clear Filters
          </button>
        </div>

        {/* Mobile Filter Modal */}
        <Modal
          title="Filters"
          visible={isFilterModalVisible}
          onCancel={() => setIsFilterModalVisible(false)}
          footer={null}
          width={300}
          centered
        >
          <div className="d-flex flex-column gap-3">
            <input
              type="text"
              placeholder="Order ID"
              className="form-control"
              value={orderId}
              onChange={(e) => SetOrderId(e.target.value)}
            />
            
            <input
              type="text"
              placeholder="Email"
              className="form-control"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
            />
           
            <input
              type="date"
              className="form-control"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
            <select
              className="form-control"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <option value="">Select Month</option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(0, i).toLocaleString("default", { month: "long" })}
                </option>
              ))}
            </select>
            <button
              className="btn btn-secondary w-100"
              onClick={handleClearFilter}
            >
              Clear Filters
            </button>
          </div>
        </Modal>

        <div className="overflow-auto bg-light">
          <table className="table user-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Product</th>
                <th>Amount</th>
                <th>Email</th>
                <th>Date</th>
                <th>before</th>
                <th>after</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {allAdminWalletHistory &&
                allAdminWalletHistory?.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <small>{data?.orderId}</small>
                    </td>
                    <td className="no-wrap">
                      <small className="no-wrap">
                        {data?.p_info}
                      </small>
                      {" "}
                      {data?.admin && <small className='mb-0 fw-bold'>(admin)</small>}
                    </td>
                    <td className="fw-bold">
                      {(data?.p_info === "Add Money" || data?.p_info === "noSponsor" || data?.p_info === "Refund" || data?.p_info === "Reward" || data?.p_info === "Referral Amount") ?                  
                          <span className="text-success no-wrap">+ {data?.price}₹</span>
                          :
                          <span className="text-danger no-wrap">- {data?.price}₹</span>
                      }
                      
                    </td>
                    <td className="">
                      <small>
                        {data?.email}
                      </small>
                    </td>
                    <td className="no-wrap">
                      <small>
                        {new Date(data?.created).toLocaleString("default", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </small>
                    </td>
                    
                    <td className="fw-bold">{data?.balanceBefore}₹</td>
                    <td className="fw-bold">{data?.balanceAfter}₹</td>
                    <td className="fw-bold">
                      {(data?.p_info === "Add Money" || 
                        data?.p_info === "noSponsor" || 
                        data?.p_info === "Refund" || 
                        data?.p_info === "Referral Amount" || 
                        data?.p_info === "Reward") ? (
                          "Null"
                        ) : (
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => handleViewOrder(data?.orderId)}
                          >
                            View
                          </button>
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Modal
          title={`Order Details`}
          visible={isModalVisible}
          onCancel={handleCloseModal}
          footer={null}
          width={800}
        >
          <AdminViewOrder
            orderId={selectedOrderId}
            onClose={handleCloseModal}
          />
        </Modal>
      </div>
    </AdminLayout>
  );
};

export default AdminWalletHistory;
