import { message, Modal } from "antd";
import AdminViewOrder from "./AdminViewOrder";
import AdminLayout from "./components/AdminLayout";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const AdminUsers = () => {
  const navigate = useNavigate();
  const [allUser, setAllUser] = useState(null);
  const [originalUserData, setOriginalUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orderId, SetOrderId] = useState("");
  const [userId, setUserId] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const currentDate = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState(currentDate);
  const [toDate, setToDate] = useState(currentDate);

  const [selectedMonth, setSelectedMonth] = useState("");

  // Modal visibility states
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false); // Filter modal visibility for mobile

  function handleClearFilter() {
    setAllUser(originalUserData);
    SetOrderId("");
    setSearchEmail("");
    setSelectedType("");
    setSelectedMonth("");
    setFromDate(currentDate);
    setToDate(currentDate);
  }

  const getOrdersByDateRange = async (fromDate, toDate) => {
    setLoading(true);
    try {
      const query = `?fromDate=${fromDate}&toDate=${toDate}`;
      const res = await axios.get(`/api/admin/admin-get-all-orders${query}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllUser(res.data.data.reverse());
        setOriginalUserData(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error("Failed to load orders");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch current date's data on component load
    getOrdersByDateRange(fromDate, toDate);
  }, []); // Only runs once when the component mounts

  useEffect(() => {
    // Automatically fetch data when fromDate or toDate changes
    if (fromDate && toDate) {
      getOrdersByDateRange(fromDate, toDate);
    }
  }, [fromDate, toDate]);

  const fetchOrdersByMonth = async (month) => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/admin/admin-get-all-orders?month=${month}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
  
      if (res.data.success) {
        setAllUser(res.data.data.reverse());
        setOriginalUserData(res.data.data);
      } else {
        message.error(res.data.message || "No data found for the selected month.");
      }
    } catch (error) {
      message.error("Failed to fetch data for the selected month.");
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    if (month) {
      fetchOrdersByMonth(month);
    } else {
      // Reset to fromDate and toDate filtering
      getOrdersByDateRange(fromDate, toDate);
    }
  };

  const handleViewOrder = (orderId) => {
    setSelectedOrderId(orderId);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedOrderId(null);
  };

  const handleSearch = () => {
    if (originalUserData) {
      const filteredUsers = originalUserData.filter((order) => {
        const orderIdMatch = order.orderId && order.orderId.includes(orderId);
        const userIdMatch = order.userId && order.userId.includes(userId);
        const emailMatch =
          order.email &&
          order.email.toLowerCase().includes(searchEmail.toLowerCase());
        const typeMatch =
          selectedType === ""
            ? true
            : order.api &&
              order.api === (selectedType === "yes" ? "yes" : "no");
        return (
          orderIdMatch && userIdMatch && emailMatch && typeMatch
        );
      });
      setAllUser(filteredUsers);
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [orderId, userId, searchEmail, selectedType]);

  const getStatusClass = (status) => {
    switch (status?.toLowerCase()) {
      case "pending":
        return "text-warning";
      case "success":
        return "text-success";
      case "failed":
        return "text-danger";
      case "cancelled":
        return "text-dark";
      case "refunded":
        return "text-primary";
      default:
        return ""; // Default class if no match
    }
  };

  if (loading)
    return (
      <AdminLayout>
        <div className="w-100 center">
          <div className="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </AdminLayout>
    );

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="d-flex justify-content-between align-items-center px-3">
          <h3 className="m-0">Orders</h3>
          <h6 className="mb-0">Total Orders - {allUser?.length}</h6>
        </div>
        <hr />

        {/* Mobile Filter Button */}
        <div className="d-block d-md-none mb-3">
          <button
            className="btn btn-primary w-100"
            onClick={() => setIsFilterModalVisible(true)}
          >
            Filters
          </button>
        </div>

        {/* Desktop Filter Inputs */}
        <div className="d-none d-md-flex gap-3 mb-3">
          <input
            type="text"
            placeholder="Order ID"
            className="form-control"
            value={orderId}
            onChange={(e) => SetOrderId(e.target.value)}
          />
          <input
            type="text"
            placeholder="User Id"
            className="form-control"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <input
            type="text"
            placeholder="Email"
            className="form-control"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
          />
          <select
            className="form-control"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <option value="">Type</option>
            <option value="yes">API</option>
            <option value="no">Manual</option>
          </select>
          {/* From Date Input */}
          <input
            type="date"
            className="form-control"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          {/* To Date Input */}
          <input
            type="date"
            className="form-control"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
          <select
            className="form-control"
            value={selectedMonth}
            onChange={(e) => handleMonthChange(e.target.value)}
          >
            <option value="">Select Month</option>
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {new Date(0, i).toLocaleString("default", { month: "long" })}
              </option>
            ))}
          </select>
          <button
            className="btn btn-secondary w-100"
            onClick={handleClearFilter}
          >
            Clear Filters
          </button>
        </div>

        {/* Mobile Filter Modal */}
        <Modal
          title="Filters"
          visible={isFilterModalVisible}
          onCancel={() => setIsFilterModalVisible(false)}
          footer={null}
          width={300}
          centered
        >
          <div className="d-flex flex-column gap-3">
            <input
              type="text"
              placeholder="Order ID"
              className="form-control"
              value={orderId}
              onChange={(e) => SetOrderId(e.target.value)}
            />
            <input
              type="text"
              placeholder="User Id"
              className="form-control"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              className="form-control"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
            />
            <select
              className="form-control"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value="">Type</option>
              <option value="yes">API</option>
              <option value="no">Manual</option>
            </select>
            {/* From Date Input */}
            <input
              type="date"
              className="form-control"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
            {/* To Date Input */}
            <input
              type="date"
              className="form-control"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
            <select
              className="form-control"
              value={selectedMonth}
              onChange={(e) => handleMonthChange(e.target.value)}
            >
              <option value="">Select Month</option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(0, i).toLocaleString("default", { month: "long" })}
                </option>
              ))}
            </select>
            <button
              className="btn btn-secondary w-100"
              onClick={handleClearFilter}
            >
              Clear Filters
            </button>
          </div>
        </Modal>

        <div className="overflow-auto bg-light">
          <table className="table user-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Type</th>
                <th>Product</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Email</th>
                <th>Service ID</th>
                <th>Status</th>
                <th>Count</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {allUser &&
                allUser?.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <small>{data?.orderId}</small>
                    </td>
                    <td>
                      <small>{data?.api === "no" ? "Manual" : "Api"}</small>
                    </td>
                    <td className="no-wrap">
                      <small>{data?.productinfo}</small>
                    </td>
                    <td className="fw-bold">{data?.amount}₹</td>
                    <td className="no-wrap">
                      <small>
                        {new Date(data?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </small>
                    </td>
                    <td>
                      <small>{data?.email}</small>
                    </td>
                    <td className="no-wrap">
                      <small>
                        {data?.mid && `mid: ${data?.mid}`}
                        {data?.sid && `sid: ${data?.sid}`}
                        {data?.iid && `iid: ${data?.iid}`}
                        {data?.pid && `pid: ${data?.pid}`}
                      </small>
                    </td>
                    <td>
                      <small className={`pointer fw-bold ${getStatusClass(data?.status)}`}>
                        {data?.status}
                      </small>
                    </td>
                    <td>
                      <small className="no-wrap">{data?.loopCount}</small>
                    </td>
                    <td align="center">
                      <small className={`pointer ${getStatusClass(data?.status)}`}>
                        <RemoveRedEyeIcon
                          onClick={() => handleViewOrder(data?.orderId)}
                        />
                      </small>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* Modal for viewing order details */}
        <Modal
          title={`Order Details`}
          visible={isModalVisible}
          onCancel={handleCloseModal}
          footer={null}
          width={800}
        >
          <AdminViewOrder
            orderId={selectedOrderId}
            onClose={handleCloseModal}
          />
        </Modal>
      </div>
    </AdminLayout>
  );
};

export default AdminUsers;
