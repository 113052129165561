import React from 'react'

const ReportsHeader = ({onClose, setSideMenu, sideMenu, title="All Reports"}) => {
  return (
    <div className="account-modal-container-content">
      <div className="top-left-content">
        <div className="box-1 center" onClick={onClose}>
          <svg
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
            />
          </svg>
        </div>
        <h2 className="mb-0">{title}</h2>
      </div>
      <div
        className="top-right-content box-2 center"
        onClick={() => setSideMenu(!sideMenu)}
      >
        <svg
          width="18"
          height="18"
          fill="currentColor"
          className="bi bi-list text-white"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
          />
        </svg>
      </div>
    </div>
  )
}

export default ReportsHeader
