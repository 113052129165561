import React, { useEffect, useRef, useState } from 'react';
import './UserWallet.css';
import { message } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setActiveButton } from '../../redux/features/footerMenuSlice';
import Layout from '../../components/Layout/Layout';
import SideMenu from '../../components/Header/SideMenu';
import HeroSvg from '../../components/Layout/HeroSvg';
import { MdWallet } from "react-icons/md";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import getUserData from '../../utils/userDataService';
import WalletTransactionModal from './WalletTransactionModal';
import WalletButtons from './component/WalletButtons';
import WalletHistory from './component/WalletHistory';
import WalletHeader from './component/WalletHeader';
import AddMoneyToWallet from './component/AddMoneyToWallet';
import RedeemCodeToWallet from './component/RedeemCodeToWallet';
import TransferWalletMoney from './component/TransferWalletMoney';

const UserWallet = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const submitButtonRef = useRef(null);
    const [sideMenu, setSideMenu] = useState(false);
    const { user } = useSelector((state) => state.user);
    const { balance } = useSelector((state) => state.data);
    const [walletHistory, setWalletHistory] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [toggle, setToggle] = useState('history');

    async function getWalletHistory() {
        setLoading(true);
        try {
            const res = await axios.post("/api/wallet/get-wallet-history",{
                email: user?.email
            },{
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                }
            });
            if (res.data.success) {
                setWalletHistory(res.data.data.reverse());
            } else {
                message.error(res.data.message);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (user !== null) {
            getWalletHistory();
            getUserData(dispatch);
        }
        // eslint-disable-next-line
    }, []);

    const onClose = () => {
        dispatch(setActiveButton('home'));
        return navigate('/') 
    }

    const copyToClipboard = (event, text) => {
        event.stopPropagation();
        navigator.clipboard
          .writeText(text)
          .then(() => {
            message.success("Wallet ID copied to clipboard!");
          })
          .catch((err) => {
            message.error("Failed to copy!");
            console.error("Failed to copy: ", err);
          });
    };

    const openModal = (data) => {
        setSelectedTransaction(data);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setSelectedTransaction(null);
    };

  return (
    <>
      <Layout>
        <div className={`account-modal-container d-lg-none d-md-none `}>
            <HeroSvg />
            <WalletHeader onClose={onClose} sideMenu={sideMenu} setSideMenu={setSideMenu}/>

            <div className="user-wallet-container px-3 w-100">
                <div className="user-wallet-content px-3 py-3 mb-3">
                    <div className='d-flex justify-content-start align-items-start gap-3'>
                        <div className='user-wallet-icon p-3 rounded-pill'>
                            <MdWallet className='user-wallet-icon-img'/>
                        </div>
                        <div className='user-wallet-balance-content mt-1'>
                            <p className='mb-0'>Balance</p>
                            <div className='user-wallet-balance'>₹{(balance && user)? parseFloat(balance).toFixed(2) : parseFloat('0').toFixed(2)}</div>
                        </div>
                    </div>
                </div>

                <WalletButtons setToggle={setToggle} toggle={toggle}/>
                
                <div className={`user-wallet-history ${toggle === 'history' ? 'visible' : ''}`}>
                    {toggle === 'history' && (
                        <WalletHistory loading={loading} walletHistory={walletHistory} openModal={openModal} copyToClipboard={copyToClipboard}/>
                    )}
                </div>
                <div className={`user-wallet-history ${toggle === 'add' ? 'visible' : ''}`}>
                    {toggle === 'add' && (
                        <AddMoneyToWallet walletHistory={walletHistory} setWalletHistory={setWalletHistory}/>
                    )}
                </div>
                <div className={`user-wallet-history ${toggle === 'redeem' ? 'visible' : ''}`}>
                    {toggle === 'redeem' && (
                        <RedeemCodeToWallet walletHistory={walletHistory} setWalletHistory={setWalletHistory}/>
                    )}
                </div>
                <div className={`user-wallet-history ${toggle === 'transfer' ? 'visible' : ''}`}>
                    {toggle === 'transfer' && (
                        <TransferWalletMoney walletHistory={walletHistory} setWalletHistory={setWalletHistory}/>
                    )}
                </div>
                <span className="center mt-4">
                    <small>
                        <p className='mb-0 fw-bold text-dark'>All Rights Reserved © 2024 | TOPUP PLAYGROUND</p>
                        <p className='mb-0'>
                        <span className='text-dark'>Website designed & developed by</span>
                        <Link
                            target="_blank"
                            className="text-danger"
                            to="https://wa.me/918624887338"
                        >
                            ~@waleedsdev
                        </Link>
                        </p>
                    </small>
                </span>  
            </div>
        </div>
        <WalletTransactionModal visible={isModalVisible} onClose={closeModal} data={selectedTransaction} />
        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
      </Layout>
    </>
  );
};

export default UserWallet;
