import React, { useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import "./AdminViewOrder.css";

const AdminViewOrder = ({ orderId, onClose }) => {
  const [singleOrder, setSingleOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "/api/admin/update-order",
        {
          status,
          id: singleOrder?._id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        onClose(); // Close the modal after update
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOrderById = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        "/api/order/get-order-by-id",
        {
          orderId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setSingleOrder(res.data.data);
        setStatus(res.data.data.status);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      getOrderById();
    }
    // eslint-disable-next-line
  }, [orderId]);

  if (loading) return <div className="w-100 center ">
    <div className="spinner-border spinner-border-sm" role="status">
    </div>
  </div>

  return (
    <div className="view-order-container">
      <h3>Order ID: {singleOrder?.orderId}</h3>
      <h5>Email: {singleOrder?.email}</h5>
      <div className="order-details overflow-auto">
        <table className="table mb-0 border">
          <thead>
            <tr>
              <th>Product</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Mode</td>
              <td>{singleOrder?.paymentMode}</td>
            </tr>
            <tr>
              <td>Game</td>
              <td>{singleOrder?.productinfo}</td>
            </tr>
            <tr>
              <td>Price</td>
              <td>Rs. {singleOrder?.amount}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{singleOrder?.status}</td>
            </tr>
            <tr>
              <td>Amount (Qty)</td>
              <td>{singleOrder?.orderDetails}</td>
            </tr>
            {singleOrder?.loopCount && <tr>
              <td>Success Quantity</td>
              <td>{singleOrder?.loopCount}</td>
            </tr>}
            <tr>
              <td>PlayerId/UserId/Other</td>
              <td>{singleOrder?.userId}</td>
            </tr>
            <tr>
              <td>ZoneId</td>
              <td>{singleOrder?.zoneId || "No Data"}</td>
            </tr>
            <tr>
              <td>Date</td>
              <td>
                {singleOrder?.createdAt
                  ? new Date(singleOrder.createdAt).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                      }
                    )
                  : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <form onSubmit={handleUpdate} className="mt-3">
        <div className="form-group">
          <label>Status</label>
          <select
            className="form-control"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="pending">Pending</option>
            <option value="processing">Processing</option>
            <option value="success">Success</option>
            <option value="cancelled">Cancelled</option>
            <option value="refunded">Refunded</option>
            <option value="failed">Failed</option>
          </select>
        </div>
        <button type="submit" className="btn btn-secondary rounded-4 mt-3">
          Update Status
        </button>
      </form>
    </div>
  );
};

export default AdminViewOrder;
