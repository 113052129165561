import React from "react";

const UserIdImageModal = ({ show, onClose, imgLink }) => {
  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      style={{ display: show ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
      aria-hidden={!show}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ zIndex: 1050 }}
      >
        <div className="modal-content">
          <div className="modal-body position-relative p-3">
            {/* Close Icon */}
            <button
              type="button"
              className="btn-close position-absolute bg-white border border-2 border-dark rounded-pill shadow-sm text-white"
              style={{ top: "5px", right: "5px", zIndex: 1055 }}
              aria-label="Close"
              onClick={onClose}
            ></button>
            {/* Image */}
            <img
              src={imgLink}
              alt="Modal Content"
              className="img-fluid rounded"
              style={{ maxHeight: "90vh", objectFit: "contain" }}
            />
          </div>
        </div>
      </div>
      {show && (
        <div
          className="modal-backdrop fade show"
          style={{ zIndex: 1040 }}
          onClick={onClose}
        ></div>
      )}
    </div>
  );
};

export default UserIdImageModal;
