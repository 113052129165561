import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setShowSearchModal } from "../redux/features/footerMenuSlice";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import "./SearchContainer.css";

const SearchContainer = () => {
  const { showSearchModal } = useSelector((state) => state.footerMenu);
  const { products } = useSelector((state) => state.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [noResult, setNoResult] = useState(false);

  // Search logic
  useEffect(() => {
    const searchText = query.toLowerCase();
    const results = products?.filter((item) =>
      item.name.toLowerCase().includes(searchText)
    );
    setFilteredProducts(results);
    setNoResult(searchText !== "" && results.length === 0);
  }, [query, products]);

  return (
    <div className={`search-container ${showSearchModal ? "active" : ""}`}>
      <div className="search-tool-main">
        <div className="search-tool container px-3 gap-3 p-lg-4 p-md-4">
          <SearchIcon className="icon d-none d-lg-block d-md-block" />
          <div
            className="box-1 center"
            onClick={() => dispatch(setShowSearchModal(!showSearchModal))}
          >
            <svg
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-left text-dark d-lg-none d-md-none"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
              />
            </svg>
          </div>
          <input
            placeholder="Search"
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <CloseIcon
            className="icon d-none d-lg-block d-md-block pointer"
            onClick={() => dispatch(setShowSearchModal(!showSearchModal))}
          />
          <SearchIcon className="d-lg-none d-md-none" />
        </div>
      </div>
      <div className="search-box-main w-100 overflow-scroll">
        <div className="container">
          <div className="search-box py-lg-4 pb-5">
            {filteredProducts?.map((item, index) => (
              <div
                key={index}
                className="search-product text-center bg-light p-2 rounded-4 shadow"
                onClick={() => {
                  navigate(`/product/${item.name}`);
                  dispatch(setShowSearchModal(false)); // Close modal after product selection
                }}
              >
                <img
                  src={`https://topupplayground.com/${item.image}`}
                  alt={`productimage`}
                  className="product-image"
                  loading="lazy"
                />
                <div className="product-name text-center mt-2">
                  <h6 className="mb-0 text-dark">{item.name}</h6>
                </div>
              </div>
            ))}
            {noResult && (
              <div className="text-center">
                <h2>No Result Found</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchContainer;
