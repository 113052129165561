import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import { message, Spin } from "antd";
import "./Register.css";

const Register = () => {
  const navigate = useNavigate();
  const { sponsor } = useParams(); // Extract sponsor from URL
  const [form, setForm] = useState({ sponsor: sponsor || "" }); 
  const [tab, setTab] = useState(0);
  const [otp, setOtp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userEnteredOtp, setUserEnteredOtp] = useState("");
  const [isCheckingSponsor, setIsCheckingSponsor] = useState(false);
  const [sponsorName, setSponsorName] = useState('');
  const [hasCheckedSponsor, setHasCheckedSponsor] = useState(false); // Track if sponsor is already checked
  //EMAIL
  const [emailOtp, setEmailOtp] = useState(null);
  const [userEnteredEmailOtp, setUserEnteredEmailOtp] = useState("");


  // Check sponsor when the component mounts or when sponsor changes
  useEffect(() => {
    if (sponsor && !hasCheckedSponsor) { // Check if sponsor is present and hasn't been checked yet
      checkSponsor(); // Automatically check sponsor if it's available in the URL
      setHasCheckedSponsor(true); // Mark as checked
    }
  }, [sponsor, hasCheckedSponsor]); // Dependency on sponsor and hasCheckedSponsor

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const checkSponsor = async () => {
    if (!form?.sponsor) {
      message.error("Please enter a sponsor code.");
      return;
    }

    if(sponsorName){
      setSponsorName('');
      setForm({ ...form, sponsor: '' });
      return null
    }

    setIsCheckingSponsor(true);
    setSponsorName(''); // Reset any previous sponsor details

    try {
      const res = await axios.post("/api/user/check-sponsor", {
        sponsor: form.sponsor,
      });

      if (res.data.success) {
        setSponsorName(res.data.name);
        message.success("Sponsor verified successfully!");
      } else {
        message.error(res.data.message || "Please enter valid sponsor code");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsCheckingSponsor(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the full name
    if (!form.fname || form.fname.trim() === "") {
      return message.error("Please enter your full name.");
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(form?.email)) {
      return message.error("Invalid email format.");
    }

    // Validate mobile number length (10 digits)
    if (!form?.mobile || form?.mobile.length !== 10) {
      return message.error("Please enter a valid 10-digit mobile number.");
    }

    // Validate password
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!form?.password || !passwordRegex.test(form?.password)) {
      return message.error("Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, and one number.");
    }

    // Optional: Validate sponsor code if entered
    if (form?.sponsor && sponsorName === '') {
      return message.error("Please verify the sponsor code.");
    }
    setLoading(true);
    try {
      const res = await axios.post("/api/user/send-mobile-otp", form);
      if (res.data.success) {
        setTab(1);

        const {
          otp: encryptedOTP,
          // emailOtp: encryptedEmailOTP,
          key: encryptedKey,
          iv: encryptedIv,
        } = res.data.data;

        const key = CryptoJS.enc.Hex.parse(encryptedKey);
        const iv = CryptoJS.enc.Hex.parse(encryptedIv);

        // MOBILE OTP
        const decryptedOTP = CryptoJS.AES.decrypt(
          { ciphertext: CryptoJS.enc.Hex.parse(encryptedOTP) },
          key,
          { iv: iv }
        ).toString(CryptoJS.enc.Utf8);
        setOtp(decryptedOTP);

      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  async function handleRegister(e) {
    e.preventDefault();

    if (isNaN(userEnteredOtp)) {
      return message.error("Enter only numbers");
    }

    if (otp !== userEnteredOtp) {
      return message.error("Incorrect Mobile Otp");
    }

    setLoading(true);
    try {
      const res = await axios.post("/api/user/register", form);
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/login");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Layout>
      <div className="register-container-main">
        <div className="container-fluid register-container my-5">
          <div className="row w-100">
            <div className="d-block m-auto col-12 col-sm-12 col-md-12 col-lg-12 border rounded-4 p-3 p-lg-5 p-md-4 shadow-sm bg-white w-100">
              {tab === 0 && (
                <form className="register-form" onSubmit={handleSubmit}>
                  <h2 className="text-dark fw-bold mb-4">Create Account</h2>
                  <div className="form-fields mb-3">
                    <input
                      onChange={handleChange}
                      value={form?.fname}
                      name="fname"
                      type="text"
                      className="form-control rounded-3"
                      placeholder="Full Name"
                      required
                    />
                  </div>
                  <div className="form-fields mb-3">
                    <input
                      onChange={handleChange}
                      value={form?.email}
                      name="email"
                      type="email"
                      className="form-control rounded-3"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="form-fields mb-3">
                    <input
                      onChange={handleChange}
                      value={form?.mobile}
                      name="mobile"
                      type="text"
                      className="form-control rounded-3"
                      placeholder="Mobile"
                      required
                    />
                  </div>
                  <div className={`form-fields ${form?.sponsor? "mb-1" : "mb-3"} position-relative`}>
                    <input
                      onChange={handleChange}
                      value={form?.sponsor}
                      name="sponsor"
                      type="text"
                      className="form-control rounded-3"
                      placeholder="Enter sponsor code (Optional)"
                      disabled={(form.sponsor && sponsorName) || (sponsor && sponsorName)} // Disable if sponsor is in URL
                    />
                    {form?.sponsor &&
                      <button
                        type="button"
                        className={`check-btn btn ${sponsorName? "btn-danger" : isCheckingSponsor? "btn-light" : "btn-success"} btn-sm ms-2 position-absolute top-50 end-0 me-2 translate-middle-y`}
                        disabled={isCheckingSponsor || !form?.sponsor}
                        onClick={checkSponsor}
                      >
                        {isCheckingSponsor ? <span className="spinner-border spinner-border-sm" aria-hidden="true"></span> : sponsorName? "Remove" : "Verify"}
                      </button>
                    }
                  </div>
                  {form.sponsor && (
                    <div className={`sponsor-details ${sponsorName? "text-success" : "text-danger"} my-2 mb-3`}>
                      <small>Sponsor Name: {sponsorName? sponsorName : isCheckingSponsor? <Spin size="small" /> : "NA"}</small>
                    </div>
                  )}
                  <div className="form-fields mb-3">
                    <input
                      onChange={handleChange}
                      value={form?.password}
                      name="password"
                      type="text"
                      className="form-control rounded-3"
                      placeholder="Password"
                      required
                    />
                  </div>
                  <button disabled={loading} className="register-btn rounded-3 mt-3 border-0 btn btn-success shadow-sm">
                    {loading? <span className="spinner-border spinner-border-sm" aria-hidden="true"></span> : "Register now"}
                  </button>
                  <div className="forgot-pass d-flex justify-content-between">
                    <h6 className="text-center text-dark mt-4">
                      Already a Customer? <Link to="/login">Click here</Link>
                    </h6>
                  </div>
                </form>
              )}
              {tab === 1 && (
                <form className="register-form">
                  <h1 className="text-dark">Verification</h1>
                  <div className="form-fields mb-3">
                    <label className="form-label text-start text-dark d-block">
                      Enter Mobile OTP
                    </label>
                    <input
                      onChange={(e) => setUserEnteredOtp(e.target.value)}
                      value={userEnteredOtp}
                      type="text"
                      className="form-control rounded-3"
                      placeholder="Enter 4 digits Mobile OTP"
                    />
                  </div>
                  <button disabled={loading} className="register-btn rounded-3 mt-3 border-0 btn btn-success shadow-sm" onClick={handleRegister}>
                    {loading? <span className="spinner-border spinner-border-sm" aria-hidden="true"></span> : "Verify and Register"}
                  </button>
                  <div className="forgot-pass d-flex justify-content-between">
                    <h6 className="text-center text-dark mt-4">
                      Already a Customer? <Link to="/login">Click here</Link>
                    </h6>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
