import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ShopIcon from "@mui/icons-material/Shop";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LogoutIcon from "@mui/icons-material/Logout";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear("token");
    navigate("/login");
  };

  const handleLinkClick = (link) => {
    navigate(link);
  }

  return (
    <div className="dashboard-main">
      <div className="container dashboard-container">
        <div className="dashboard-menu shadow">
          <h4 className="text-dark fw-bold">Menu</h4>
          <ul>
            <li
              className={`${location.pathname === "/user-dashboard" && "active"}`}
              onClick={()=>{handleLinkClick("/user-dashboard")}}
            >
              <Link to="/user-dashboard">
                <DashboardIcon className="me-2" />
                Dashboard
              </Link>
            </li>
            <li
              className={`${location.pathname === "/wallet" && "active"}`}
              onClick={()=>{handleLinkClick("/wallet")}}
            >
              <Link to="/wallet">
                <AccountBalanceWalletIcon className="me-2" />
                Wallet
              </Link>
            </li>
            <li
              className={`${location.pathname === "/payments" && "active"}`}
              onClick={()=>{handleLinkClick("/payments")}}
            >
              <Link to="/payments">
                <AccountBalanceWalletIcon className="me-2" />
                Payments
              </Link>
            </li>
            <li
              className={`${location.pathname === "/orders" && "active"}`}
              onClick={()=>{handleLinkClick("/orders")}}
            >
              <Link to="/orders">
                <ShopIcon className="me-2" />
                Orders
              </Link>
            </li>
            <li
              className={`${location.pathname === "/my-account" && "active"}`}
              onClick={()=>{handleLinkClick("/my-account")}}
            >
              <Link to="/my-account">
                <AccountCircleIcon className="me-2" />
                Account Details
              </Link>
            </li>
            <li
              className={`${location.pathname === "/refer-and-earn" && "active"}`}
              onClick={()=>{handleLinkClick("/refer-and-earn")}}
            >
              <Link to="/refer-and-earn">
                <CurrencyExchangeIcon className="me-2" />
                Refer & Earn
              </Link>
            </li>
            <li
              className={`${location.pathname === "/query" && "active"}`}
              onClick={()=>{handleLinkClick("/query")}}
            >
              <Link to="/query">
                <QuestionMarkIcon className="me-2" />
                Query
              </Link>
            </li>
            <li
              className="text-dark"
              style={{ cursor: "pointer" }}
              onClick={handleLogout}
            >
              <LogoutIcon className="me-2" />
              Logout
            </li>
          </ul>
        </div>
        <div className="dashboard-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
