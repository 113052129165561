import React, { useEffect, useState } from "react";
import "./Reports.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { message, Modal } from "antd";
import Layout from "../../components/Layout/Layout";
import HeroSvg from "../../components/Layout/HeroSvg";
import SideMenu from "../../components/Header/SideMenu";
import { setActiveButton } from "../../redux/features/footerMenuSlice";
import PaymentReports from "./PaymentReports";
import PurchaseReports from "./PurchaseReports";
import ReportsTabControl from "./ReportsTabControl";
import ReportsHeader from "./ReportsHeader";
import DateRangeFilter from "./DateRangeFilter";
import AllAddedMoneyReports from "./AllAddedMoneyReports";

const Reports = ({ show }) => {
  const { singlePurchase } = useSelector((state) => state.data);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const queryParams = new URLSearchParams(location.search);
  const [sideMenu, setSideMenu] = useState(false);
  const [payments, setPayments] = useState(null);
  const [allPurchase, setAllPurchase] = useState(null);
  const initialType = queryParams.get("type") || "payment";
  const [activeTab, setActiveTab] = useState(initialType);
  const [loading, setLoading] = useState(false);
  const [allAddedMoney, setAllAddedMoney] = useState(null);

  // Filter state
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filteredPayments, setFilteredPayments] = useState(null);
  const [filteredPurchases, setFilteredPurchases] = useState(null);
  const [filteredAllAddedMoney, setFilteredAllAddedMoney] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Close modal and navigate to home
  const onClose = () => {
    dispatch(setActiveButton("home"));
    return navigate("/");
  };

  // Handle tab click
  const handleTabClick = (type) => {
    setActiveTab(type);
  };

  // Fetch user payments
  async function getUserPayments() {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/payment/get-user-payments",
        {
          email: user?.email,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      setLoading(false);
      if (res.data.success) {
        setPayments(res.data.data.reverse());
        setFilteredPayments(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  // Fetch all user orders
  const getAllUserOrders = async () => {
    try {
      const res = await axios.post(
        "/api/order/get-user-orders",
        { email: user?.email },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setAllPurchase(res.data.data.reverse());
        setFilteredPurchases(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function getAllAddedMoneyToWallet() {
    try {
      const res = await axios.post(
        "/api/order/get-all-added-money-wallet",
        { email: user?.email },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setAllAddedMoney(res.data.data.reverse());
        setFilteredAllAddedMoney(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user) {
      getUserPayments();
      getAllAddedMoneyToWallet();
      getAllUserOrders();
    }
    // eslint-disable-next-line
  }, [user]);

  // Handle date change for filtering
  const handleDateChange = () => {
    filterData(activeTab);
  };

  // Clear filters
  const clearFilters = () => {
    setStartDate("");
    setEndDate("");
    setFilteredPayments(payments);
    setFilteredPurchases(allPurchase);
    setFilteredAllAddedMoney(allAddedMoney);
    setIsModalVisible(false);
  };

  // Filter data based on date range
  const filterData = (type) => {
    if (!startDate) {
      message.error("Start date is not set");
      return;
    }
    if (!endDate) {
      message.error("End date is not set");
      return;
    }
    setIsModalVisible(false);

    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0); // Set to midnight

    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999); // End of day

    if (type === "payment" && payments) {
      const filtered = payments.filter((item) => {
        const date = new Date(item.createdAt);
        return date >= start && date <= end;
      });
      setFilteredPayments(filtered);
    } else if (type === "purchase" && allPurchase) {
      const filtered = allPurchase.filter((item) => {
        const date = new Date(item.createdAt);
        return date >= start && date <= end;
      });
      setFilteredPurchases(filtered);
    } else if (type === "ledger" && allAddedMoney) {
      const filtered = allAddedMoney.filter((item) => {
        const date = new Date(item.date);
        return date >= start && date <= end;
      });
      setFilteredAllAddedMoney(filtered);
    }
  };

  //Update Order status if order is from indian smart pannel
  const updatePurchaseOrder = () => {
    if (!filteredPurchases) return;

    const updatedPurchases = filteredPurchases.map((order) =>
      order.orderId === singlePurchase.orderId ? singlePurchase : order
    );

    setFilteredPurchases(updatedPurchases);
  };
  // ========= CLOSE ============

  const ResetButton = () => (
    <button
      className="filter-button-reports clear-button m-0 center gap-1"
      type="primary"
      onClick={clearFilters}
    >
      <span>Reset</span>
    </button>
  );
  return (
    <>
      <Layout>
        <div className={`account-modal-container d-lg-none d-md-none `}>
          <HeroSvg />
          <ReportsHeader
            setSideMenu={setSideMenu}
            sideMenu={sideMenu}
            onClose={onClose}
          />

          <div className="all-reports-container px-3">
            <div className="all-reports-content px-3 py-3">
              <ReportsTabControl
                handleTabClick={handleTabClick}
                activeTab={activeTab}
              />

              <div className="py-1 pe-1 ps-3 mt-2 border all-reports-content-filter-container mb-2">
                <div>
                  {activeTab === "purchase" && (
                    <h5 className="total-reports">
                      Total Purchase:{" "}
                      {filteredPurchases ? filteredPurchases?.length : "0"}
                    </h5>
                  )}
                  {activeTab === "payment" && (
                    <h5 className="total-reports">
                      Total Payments:{" "}
                      {filteredPayments ? filteredPayments?.length : "0"}
                    </h5>
                  )}
                  {activeTab === "ledger" && (
                    <h5 className="total-reports">
                      Total Data: {allAddedMoney ? allAddedMoney?.length : "0"}
                    </h5>
                  )}
                </div>

                <div className="center gap-1">
                  <button
                    className="filter-button-reports m-0 center gap-1"
                    type="primary"
                    onClick={() => {
                      setIsModalVisible(true);
                    }}
                  >
                    <span>Filter</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-filter"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
                    </svg>
                  </button>
                  {activeTab === "payment" &&
                    payments?.length !== filteredPayments?.length && (
                      <ResetButton />
                    )}
                  {activeTab === "purchase" &&
                    allPurchase?.length !== filteredPurchases?.length && (
                      <ResetButton />
                    )}
                  {activeTab === "ledger" &&
                    allAddedMoney?.length !== filteredAllAddedMoney?.length && (
                      <ResetButton />
                    )}
                </div>
              </div>

              {/* PAYMENT TAB */}
              {activeTab === "payment" && (
                <>
                  {filteredPayments && filteredPayments?.length > 0 ? (
                    <div className="active-reports-tab">
                      {filteredPayments?.map((item, index) => (
                        <PaymentReports key={index} item={item} />
                      ))}
                    </div>
                  ) : (
                    <div className="text-center active-reports-tab">
                      <div className="add-money center my-4">
                        No record found
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* PURCHASE TAB */}
              {activeTab === "purchase" && (
                <>
                  {filteredPurchases && filteredPurchases?.length > 0 ? (
                    <div className="active-reports-tab">
                      {filteredPurchases?.map((item, index) => (
                        <PurchaseReports
                          key={index}
                          item={item}
                          updatePurchaseOrder={updatePurchaseOrder}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="text-center active-reports-tab">
                      <div className="add-money center my-4">
                        No record found
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* LEDGER TAB */}
              {activeTab === "ledger" && (
                <>
                  {filteredAllAddedMoney &&
                  filteredAllAddedMoney?.length > 0 ? (
                    <div className="active-reports-tab">
                      {filteredAllAddedMoney?.map((item, index) => (
                        <AllAddedMoneyReports item={item} />
                      ))}
                    </div>
                  ) : (
                    <div className="text-center active-reports-tab">
                      <div className="add-money center my-4">
                        No record found
                      </div>
                    </div>
                  )}
                </>
              )}

              {loading && (
                <div className="center py-3">
                  <div className="spinner-border m-5 text-center" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>

            <span className="center mt-4">
              <small>
                <p className="mb-0 fw-bold">
                  All Rights Reserved © 2024 | TOPUP PLAYGROUND
                </p>
                <p className="mb-0">
                  <span>Website designed & developed by</span>
                  <Link
                    target="_blank"
                    className="text-danger"
                    to="https://aashirdigital.com/"
                  >
                    ~@aashirdigital
                  </Link>
                </p>
              </small>
            </span>
          </div>
        </div>

        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />

        {isModalVisible && (
          <DateRangeFilter
            onClose={() => {
              setIsModalVisible(false);
            }}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            clearFilters={clearFilters}
            handleDateChange={handleDateChange}
          />
        )}
      </Layout>
    </>
  );
};

export default Reports;
