import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProductInfoFinalPopup from "./ProductInfoFinalPopup";
import axios from "axios";

const ProductInfoContinueButton = ({
  loader,
  userId,
  checkPlaceOrder,
  selectedPrice,
  product,
  playerCheck,
  amount,
  setMode,
  setShowConfirmModal,
  showConfirmModal,
  allowUser,
  setShowUpdateMobileModal,
  setWebsite
}) => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const onButtonClick = async () => {
    await getWebsite()
    if(!user.mobile){
      setShowUpdateMobileModal();
    } else{
      setShowConfirmModal(true);
    }
  };

  async function getWebsite() {
    try {
      const res = await axios.get("/api/admin/get-website");
      if (res.data.success) {
        setWebsite(res.data.data.website);
      } 
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="Product-Info-Continue-Button-container d-md-none d-lg-none px-2 mb-3">
        {selectedPrice?
          <div className="Product-Info-Continue-Button-content p-2">
            {!user ? (
              <button
                className="continue-button w-100"
                onClick={() => navigate("/login")}
              >
                PLEASE LOGIN TO CONTINUE WITH {selectedPrice}₹
              </button>
            ) : product?.stock === "No" ? (
              <button
                className="continue-button w-100"
                style={{ opacity: "0.7" }}
              >
                OUT OF STOCK
              </button>
            ) : product?.apiName === "smileOne" && playerCheck && !allowUser ? (
              <button disabled={true} className="continue-button w-100">
                THIS ID NOT ALLOWED
              </button>
            ) : product?.apiName === "smileOne" && playerCheck !== null ? (
              <button onClick={onButtonClick} className="continue-button w-100">
                CONTINUE WITH ₹{selectedPrice}
              </button>
            ) : product?.apiName === "moogold" &&
              product?.fields?.length === 2 &&
              typeof product?.servers === "string" ? (
              <button onClick={onButtonClick} className="continue-button w-100">
                CONTINUE WITH ₹{selectedPrice}
              </button>
            ) : (
              <button onClick={onButtonClick} className="continue-button w-100">
                CONTINUE WITH ₹{selectedPrice}
              </button>
            )}
          </div>
          : 
          <div className="Product-Info-Continue-Button-content p-2">
            {!user ? 
              <button
                className="continue-button w-100"
                onClick={() => navigate("/login")}
              >
                PLEASE LOGIN TO CONTINUE
              </button>
              : 
              <button
                className="continue-button w-100"
                style={{ opacity: "0.7" }}
                disabled
              >
                SELECY ANY PACKAGE TO CONTINUE
              </button>
            }
          </div>
        }
      </div>
      <ProductInfoFinalPopup
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        product={product}
        playerCheck={playerCheck}
        selectedPrice={selectedPrice}
        amount={amount}
        setMode={setMode}
        checkPlaceOrder={checkPlaceOrder}
        loader={loader}
        userId={userId}
      />
    </>
  );
};

export default ProductInfoContinueButton;
