import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Footer/Footer.css";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useDispatch, useSelector } from "react-redux";
import { LinkSvg } from "../AllSvgModulesExport/LoginSvg";
import { setShowSupportModal } from "../../redux/features/footerMenuSlice";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleContactUsClick = () => {
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      dispatch(setShowSupportModal(true));
    } else {
      navigate("/contact");
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <div className="container footer-container-content">
          {/* Help Section */}
          <div className="col-lg-3 px-2 footer-content">
            <h4 className="fw-bold text-light">Need help?</h4>
            <div className="center">
              <button
                className="contact-us-btn "
                onClick={handleContactUsClick}
              >
                <LinkSvg />
                <span className="fw-bold">Contact us</span>
              </button>
            </div>
          </div>

          {/* Quick Links */}
          <div className="col-lg-3 px-2 footer-content">
            <h4 className="fw-bold text-light">Quick Links</h4>
            <div className="d-flex gap-2 center">
              <button
                className="link-btn"
                onClick={() => navigate("/login")}
              >
                <span className="text-dark fw-bold">Login</span>
                <LinkSvg />
              </button>
              <button
                className="link-btn"
                onClick={() => navigate("/register")}
              >
                <span className="text-dark fw-bold">Register</span>
                <LinkSvg />
              </button>
            </div>
          </div>

          {/* Social Media & Contact */}
          <div className="col-lg-3 px-2 footer-content mb-0">
            <h4 className="fw-bold text-light">Stay updated with us</h4>
            <div className="social-icons text-dark d-flex gap-2 center">
              <InstagramIcon
                className="social-icon"
                onClick={() => window.location.href = "https://www.instagram.com/topup_playground/"}
              />
              <WhatsAppIcon
                className="social-icon"
                onClick={() => window.location.href = "https://wa.me/917350712254"}
              />
              <EmailIcon
                className="social-icon"
                onClick={() => window.location.href = "mailto:topupplaygroundofficial.com"}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-container-bottom">
        <div className="container footer-container-bottom-content center p-0">
          <div className="footer-container-bottom-data gap-2 m-0 text-center">
            <span className="copyright text-light center">
              <span>Developed by</span>
              <Link
                target="_blank"
                className="text-danger"
                to="https://aashirdigital.com/"
              >
                ~@aashirdigital
              </Link>
            </span>
            <span className="dot">•</span>
            <span className="copyright text-light">
              <Link target="_blank" className="text-light" to="/terms-and-conditions">
                Terms and Conditions
              </Link>
            </span>
            <span className="dot">•</span>
            <span className="copyright text-light">
              <Link target="_blank" className="text-light" to="/privacy-policy">
                Privacy Policy
              </Link>
            </span>
            <span className="dot">•</span>
            <span className="copyright text-light">
              <Link target="_blank" className="text-light" to="/refund-policy">
                Refund Policy
              </Link>
            </span>
            <span className="dot">•</span>
             <span className="copyright text-light">
              © Copyright <span className="fw-bold">Topup Playground</span>
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
