import React, { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import "./WalletHistory.css";
import { Modal } from "antd";
import { ChevronRightIcon } from "../../../components/Header/AccountModalSvg";

const WalletHistory = ({ walletHistory, openModal, copyToClipboard, loading }) => {
  const [filteredData, setFilteredData] = useState([]); // Start with an empty array
  const [sortBy, setSortBy] = useState("All"); // Default to "All"
  const [showDropdown, setShowDropdown] = useState(false); // Dropdown closed by default

  // Helper function to get the start of the day
  const getStartOfDay = (date) => new Date(date.setHours(0, 0, 0, 0));

  // Filter data based on the selected criteria
  const handleSort = (criteria) => {
    setSortBy(criteria);
    setShowDropdown(false);

    const today = new Date();
    let startDate, endDate;

    switch (criteria) {
      case "Today":
        startDate = getStartOfDay(new Date());
        endDate = new Date();
        break;
      case "Yesterday":
        startDate = getStartOfDay(new Date(today.setDate(today.getDate() - 1)));
        endDate = getStartOfDay(new Date());
        break;
      case "This Week":
        startDate = getStartOfDay(new Date(today.setDate(today.getDate() - today.getDay())));
        endDate = new Date();
        break;
      case "Last Week":
        const lastWeekStart = new Date(today.setDate(today.getDate() - today.getDay() - 7));
        const lastWeekEnd = new Date(today.setDate(today.getDate() - today.getDay()));
        startDate = getStartOfDay(lastWeekStart);
        endDate = getStartOfDay(lastWeekEnd);
        break;
      case "This Month":
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date();
        break;
      case "Last Month":
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case "All":
        setFilteredData(walletHistory); // Show all data
        return;
      default:
        return;
    }

    const filtered = walletHistory.filter((data) => {
      const transactionDate = new Date(data.created);
      return transactionDate >= startDate && transactionDate <= endDate;
    });

    setFilteredData(filtered);
  };

  // Load all data on the first render or when walletHistory changes
  useEffect(() => {
    if (walletHistory) {
      setFilteredData(walletHistory);
    }
  }, [walletHistory]);

  return (
    <>
    <div className="user-wallet-content w-100">
      <div className="position-relative">
        <p className="mb-0 border bg-light user-wallet-content-heading default-font">
          <span>Transactions History</span>
          <span
            className="pointer dropdown-toggle "
            onClick={() => setShowDropdown((prev) => !prev)}
          >
            Filter by: {sortBy}
            
          </span>
        </p>
      </div>
      <div className="d-flex justify-content-start align-items-start gap-3 w-100">
        <div className="user-wallet-transaction-content mt-1 w-100">
          <div className="user-wallet-transaction-history-data">
            {!loading ? (

              (filteredData.length  > 0 ?
                <div>
                  {filteredData.map((data) => (
                    <div
                      key={data?._id}
                      className="user-wallet-transaction-history-data-card border-bottom w-100"
                    >
                      <div className="center gap-2">
                        <div
                          className={`user-wallet-transaction-history-add-or-remove-icon center ${
                            (data?.p_info === "Add Money" ||
                              data?.p_info === "noSponsor" ||
                              data?.p_info === "Refund" ||
                              data?.p_info === "Reward") &&
                            "active"
                          }`}
                        >
                          {(data?.p_info === "Add Money" ||
                          data?.p_info === "noSponsor" ||
                          data?.p_info === "Refund" ||
                          data?.p_info === "Reward")
                            ? "+"
                            : "-"}
                        </div>
                        <div className="user-wallet-transaction-history-heading">
                          {!data?.admin && (
                            <h3 className="mb-0">
                              {data?.p_info === "noSponsor"
                                ? "Add Money"
                                : data?.p_info}
                            </h3>
                          )}
                          {data?.admin && (
                            <h3 className="mb-0">
                              {data?.p_info} <small>(admin)</small>
                            </h3>
                          )}
                          <span
                            className="d-flex justify-content-start align-items-center rounded gap-1 mt-1"
                            onClick={(event) => {
                              copyToClipboard(event, `Wallet Id: ${data?.orderId}`);
                            }}
                          >
                            <ContentCopyIcon className="fs-6 icon" />
                            <span className="">
                              {data?.orderId || "id unavailable"}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="user-wallet-transaction-history-data-balance">
                        {data?.p_info === "Add Money" ||
                        data?.p_info === "noSponsor" ||
                        data?.p_info === "Refund" ||
                        data?.p_info === "Referral Amount" ||
                        data?.p_info === "Reward" ? (
                          <p
                            className="text-success center py-1 border border-success fw-bold rounded-pill mb-0"
                            onClick={() => {
                              openModal(data);
                            }}
                          >
                            + ₹{data?.price}
                          </p>
                        ) : (
                          <p
                            className="text-danger center py-1 border border-danger fw-bold rounded-pill mb-0"
                            onClick={() => {
                              openModal(data);
                            }}
                          >
                            - ₹{data?.price}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                :  
                <div className="p-5 center">No history found</div>
              )


            ) : (
              <div className="text-center py-5">
                <div className="spinner-border text-secondary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    <Modal
      title="Filters"
      visible={showDropdown}
      onCancel={() => setShowDropdown(false)}
      footer={null}
      width={320}
      centered
    >
      <div className="wallet-transaction-dropdown-menu show">
            {/* Close Icon */}
            <ul>
              {["Today", "Yesterday", "This Week", "Last Week", "This Month", "Last Month", "All"].map((option) => (
                <li
                  className={`py-2 mb-2 border px-3 rounded-5 d-flex justify-content-between align-items-center ${sortBy === option? "bg-secondary text-white fw-bold" : "bg-light text-dark"}`}
                  key={option}
                  onClick={() => handleSort(option)}
                >
                  <span>{option}</span>
                  <ChevronRightIcon fill={`${sortBy === option? " #fff" : "#000"}`}/>
                </li>
              ))}
            </ul>
          </div>
    </Modal>
    </>
  );
};

export default WalletHistory;
