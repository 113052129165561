import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useSelector } from "react-redux";
import "./Wallet.css";
import axios from "axios";
import { message, Spin, DatePicker } from "antd";
import moment from "moment";
import FilterComponent from "./components/FilterComponent";

const Payments = () => {
  const { user } = useSelector((state) => state.user);
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("thisWeek");

  const getUserPayments = async (start, end) => {
    setLoading(true);
    try {
      const res = await axios.post(
        "/api/payment/get-user-payments",
        {
          email: user?.email,
          startDate: start,
          endDate: end,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setPayments((res.data.data).reverse());
      } else {
        setPayments(null);
        message.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
      setPayments(null);
      message.error("Failed to fetch payments.");
    } finally {
      setLoading(false);
    }
  };

  const applyDefaultFilter = () => {
    const now = new Date();
    const start = new Date();
    start.setDate(now.getDate() - 7);
    const startISOString = start.toISOString();
    const endISOString = now.toISOString();
    setStartDate(startISOString);
    setEndDate(endISOString);
    setSelectedFilter("thisWeek");
    getUserPayments(startISOString, endISOString);
  };

  useEffect(() => {
    if (user) {
      applyDefaultFilter();
    }
  }, [user]);

  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "text-warning";
      case "failed":
      case "failure":
      case "rejected":
        return "text-danger";
      case "success":
      case "approved":
        return "text-success";
      default:
        return "";
    }
  };

  return (
    <Layout>
      <DashboardLayout>
        <h4 className="mb-3 fw-bold text-white">All Payments</h4>
        <FilterComponent
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            getUserPayments={getUserPayments}
        />
        <div className="rounded-4 p-2 bg-white overflow-scroll">
            {!loading ? (
                payments ? (
                <table className="table table-rounded w-100">
                    <thead>
                    <tr>
                        <th className="no-wrap">#</th>
                        <th>
                        <small>OrderId</small>
                        </th>
                        <th>
                        <small>Product</small>
                        </th>
                        <th>
                        <small>Date</small>
                        </th>
                        <th>
                        <small>Amount</small>
                        </th>
                        <th>
                        <small>Type</small>
                        </th>
                        <th>
                        <small>Status</small>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payments.map((item, index) => (
                        <tr key={index}>
                        <td>
                            <small>{index + 1}</small>
                        </td>
                        <td>
                            <small>{item?.orderId}</small>
                        </td>
                        <td className="fw-bold no-wrap">
                            <small>
                            {item?.paymentType === "wallet"
                                ? "Add Money"
                                : item?.productInfo}
                            </small>
                        </td>
                        <td className="no-wrap">
                            <small>
                            {new Date(item?.createdAt).toLocaleString("default", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                            })}
                            </small>
                        </td>
                        <td className="fw-bold">
                            <small>{item?.amount}</small>
                        </td>
                        <td>
                            <small>{item?.paymentType}</small>
                        </td>
                        <td className={getStatusClass(item?.status.toLowerCase())}>
                            <small>{item?.status}</small>
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                ) : (
                <div className="center py-5 text-secondary">
                    No payment history available
                </div>
                )
            ) : (
                <div className="py-5 center">
                <Spin size="large" />
                </div>
            )}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Payments;
