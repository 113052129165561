import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import "./Register.css";
import IMAGES from "../img/image";
import { useDispatch } from "react-redux";
import { setActiveButton } from "../redux/features/footerMenuSlice";
import getUserData from "../utils/userDataService";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState({});

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form?.email === "aashirdigital@gmail.com") {
      try {
        const res = await axios.post("/api/user/admin", form);
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          navigate("/admin-dashboard");
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await axios.post("/api/user/login", form);
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          dispatch(setActiveButton("home"));
          navigate("/");
          getUserData(dispatch);
          // window.location.href = '/'
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
        message.error("Something went wrong");
      }
    }
  };

  return (
    <Layout>
      <div className="register-container-main">
        <div className="container-fluid register-container my-5">
          <div className="row w-100">
            <div className="d-block m-auto col-12 col-sm-12 col-md-6 col-lg-6 border rounded-4 p-3 p-lg-5 p-md-4 shadow-sm bg-white w-100">
              <form className="register-form" onSubmit={handleSubmit}>
                <h2 className="text-dark fw-bold mb-4">Login</h2>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.email}
                    name="email"
                    type="email"
                    className="form-control rounded-3"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.password}
                    name="password"
                    type="text"
                    className="form-control rounded-3"
                    placeholder="Password"
                    required
                  />
                </div>
                <button className="register-btn text-white text-left rounded-3 mt-3 btn btn-success border-0 shadow-sm fw-bold fs-5">
                  Login
                </button>
                <div className="forgot-pass d-flex justify-content-between">
                  <h6 className="text-center text-dark mt-4">
                    New Customer? <Link to="/register">Sign Up</Link>
                  </h6>
                  <h6 className="text-center text-dark mt-4">
                    Forgot Password? <Link to="/forgot-password">Click Here</Link>
                  </h6>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
