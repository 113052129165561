import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./AddBlog.css";
import { useSelector } from "react-redux";

const AddProductPackagesCategory = () => {
  const { products } = useSelector((state) => state.data);
  const [loading, setLoading] = useState(false);
  const [allProductsPackagesCategory, setAllProductPackagesCategory] = useState([]);
  const [category, setCategory] = useState('');
  const [link, setLink] = useState('');
  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [position, setPosition] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);

  // Fetch categories
  const getAllProductsPackagesCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/admin/get-all-products-packages-categories', {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (response.data.success) {
        setAllProductPackagesCategory(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching all packages list:', error);
      message.error("Error fetching all packages list");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllProductsPackagesCategories();
  }, []);

  // Handle form submission for adding/updating a category
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const url = editMode
        ? `/api/admin/update-products-packages-categories/${editId}`
        : '/api/admin/add-products-packages-categories';
      const method = editMode ? 'PUT' : 'POST';

      const response = await axios({
        method,
        url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        data: { category, link, productId, productName, position },
      });

      if (response.data.success) {
        message.success(response.data.message);
        getAllProductsPackagesCategories();
        resetForm();
      } else {
        message.error("Failed to save category");
      }
    } catch (error) {
      console.error('Error saving category:', error);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle category edit
  const handleEdit = (category) => {
    setEditMode(true);
    setEditId(category._id);
    setCategory(category.category);
    setLink(category.link);
    setProductId(category.productId);
    setProductName(category.productName);
    setPosition(category.position);
  };

  // Handle category deletion
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      setLoading(true);
      try {
        const response = await axios.delete(`/api/admin/delete-products-packages-categories/${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (response.data.success) {
          message.success("Category deleted successfully.");
          getAllProductsPackagesCategories();
        } else {
          message.error("Failed to delete category.");
        }
      } catch (error) {
        console.error("Error deleting category:", error);
        message.error("An error occurred while deleting the category.");
      } finally {
        setLoading(false);
      }
    }
  };

  // Reset form and edit mode
  const resetForm = () => {
    setCategory('');
    setLink('');
    setProductId('');
    setProductName('');
    setPosition('');
    setEditMode(false);
    setEditId(null);
  };

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title mb-3">
          <h3 className="m-0">{editMode ? "Edit Packages Category" : "Add Packages Category"}</h3>
        </div>
        {!loading ? (
          <div className="bg-light p-3 add-blog">
            <div>
              <form className="row p-0" onSubmit={handleSubmit}>
                <div className="col-12 col-lg-3">
                  <input
                    type="text"
                    placeholder="Add Packages Category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    required
                  />
                </div>
                <div className="col-12 col-lg-3">
                  <input
                    type="text"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    placeholder="Add Packages image link"
                  />
                </div>
                <div className="col-12 col-lg-3">
                  <input
                    type="text"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    placeholder="Set Position"
                  />
                </div>
                <div className="col-12 col-lg-3">
                  <select
                    value={productId}
                    onChange={(e) => {
                      const selectedProduct = products.find(product => product._id === e.target.value);
                      setProductId(selectedProduct._id);
                      setProductName(selectedProduct.name);
                    }}
                  >
                    <option value="">Select Product</option>
                    {products?.map((product) => (
                      <option key={product._id} value={product._id}>
                        {product.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-lg-4 d-flex flex-wrap gap-2">
                  <button type="submit">{editMode ? "Update Category" : "Add Category"}</button>
                  {editMode && <button onClick={resetForm} className="ml-2">Cancel</button>}
                </div>
              </form>
            </div>

            {/* Display category list in a table */}
            {allProductsPackagesCategory && (
                <div className="overflow-scroll w-100">
                    <table className="table table-border mt-4 mx-0 w-100 ">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Position</th>
                        <th>Category</th>
                        <th>PName</th>
                        <th>Link</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {allProductsPackagesCategory.map((category, index) => (
                        <tr key={category._id}>
                        <td>{index + 1}</td>
                        <td>{category.position}</td>
                        <td>{category.category}</td>
                        <td>{category.productName}</td>
                        <td>{category.link}</td>
                        <td className="d-flex gap-2">
                            <button onClick={() => handleEdit(category)}>
                            <EditIcon />
                            </button>
                            <button onClick={() => handleDelete(category._id)}>
                            <DeleteIcon />
                            </button>
                        </td>
                        </tr>
                    ))}
                    </tbody>
                    </table>
                </div>
            )}
          </div>
        ) : (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default AddProductPackagesCategory;
