import React from 'react';
import './WalletTransactionModal.css';
import { AiOutlineClose } from 'react-icons/ai';
import { message, Modal } from 'antd';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const WalletTransactionModal = ({ visible, onClose, data }) => {
    const handleOverlayClick = (e) => {
        if (e.target.className === 'wallet-transaction-modal-overlay') {
            onClose();
        }
    };

    const copyToClipboard = (event, text) => {
        event.stopPropagation();
        navigator.clipboard
          .writeText(text)
          .then(() => {
            message.success("Wallet ID copied to clipboard!");
          })
          .catch((err) => {
            message.error("Failed to copy!");
            console.error("Failed to copy: ", err);
          });
    };

    if (!visible) return null;

    return (
        <Modal
            title="Transaction Details"
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={320}
            centered
        >
            <div className="wallet-transaction-modal">
                <div className="modal-header">
                    <AiOutlineClose className="close-icon" onClick={onClose} />
                </div>
                <div className="modal-content">
                    <div className="modal-row">
                        <span className="label">Info:</span>
                        {!data?.admin && <span className='mb-0'>{data?.p_info === "noSponsor"? "Add Money" : data?.p_info}</span>}
                        {data?.admin && <span className='mb-0'>{data?.p_info} <span>(admin)</span></span>}
                    </div>
                    <div className="modal-row">
                        <span className="label center">Wallet ID:</span>
                        <span
                            className="d-flex justify-content-start align-items-center rounded gap-1 mt-1"
                            onClick={(event) => {
                            copyToClipboard(event, `Wallet Id: ${data?.orderId}`);
                            }}
                            style={{ display: 'inline-flex' }} // Add this line
                        >
                            <ContentCopyIcon className="fs-6 ticket-id" />
                            <span className="ticket-id">{data?.orderId || 'id unavailable'}</span> {/* Adjusted to use the dynamic ticketId */}
                        </span>
                    </div>
                    <div className="modal-row">
                        <span className="label">Price:</span>
                        {(data?.p_info === "Add Money" || data?.p_info === "noSponsor" || data?.p_info === "Refund" || data?.p_info === "Reward") ? 
                                                
                            <span className='fw-bold text-success'>+ ₹{data?.price}</span>
                            :
                            <span className='fw-bold text-danger'>- ₹{data?.price}</span>
                        }
                    </div>
                    <div className="modal-row">
                        <span className="label">Balance After:</span>
                        <span>₹{data?.balanceAfter}</span>
                    </div>
                    <div className="modal-row">
                        <span className="label">Date:</span>
                        <span>
                            {new Date(data?.created).toLocaleString("en-GB", {
                            weekday: "short", // Optional: To show the day of the week
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true, // This will show AM/PM
                            })}
                        </span>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default WalletTransactionModal;
