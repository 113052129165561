import React, { useEffect, useState } from "react";
import "./ReferAndEarnMobile.css";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setActiveButton } from "../redux/features/footerMenuSlice";
import { useNavigate } from "react-router-dom";
import HeroSvg from "../components/Layout/HeroSvg";
import SideMenu from "../components/Header/SideMenu";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import referimage from "../img/support/refer.png";
import { setUser } from "../redux/features/userSlice";
import getUserData from "../utils/userDataService";

const ReferAndEarnMobile = ({ show }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sideMenu, setSideMenu] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [inviteCode, setInviteCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [rechargeAmount, setRechargeAmount] = useState("");

  const onClose = () => {
    dispatch(setActiveButton("home"));
    return navigate("/");
  };

  async function getAmount() {
    try {
      const res = await axios.get("/api/refer/get-amount", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        setRechargeAmount(res.data.data.rechargeAmount);
        setAmount(res.data.data.amount);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAmount();
  }, []);

  function generateReferCode(length) {
    const name = user?.fname; // Customer ka naam
    const firstName = name?.split(" ")[0]; // Space se naam ko split karke pehla part le lo
    const capitalFirstName = firstName && firstName.toUpperCase().slice(0, 4); // Pehla naam capital case mein
  
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = capitalFirstName; // Capitalize pehla naam ko result mein add karo
  
    // Baaki ka random refer code generate karna
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
  
    setInviteCode(result); // Invite code set karo
  }

  useEffect(() => {
    if (!user?.inviteCode) {
      generateReferCode(8);
    }
  }, [user]);

  function handleCopy() {
    try {
      const inviteCode = user?.inviteCode && user?.inviteCode;
      if (!inviteCode) return;

      const tempInput = document.createElement("input");
      tempInput.style.position = "absolute";
      tempInput.style.left = "-9999px";
      tempInput.value = inviteCode;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      alert("Copied to clipboard: " + inviteCode);
    } catch (error) {
      console.log(error);
    }
  }

  async function saveInviteCode() {
    setLoading(true);
    try {
      const res = await axios.post("/api/user/save-invite-code", {
        email: user?.email,
        inviteCode: inviteCode,
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        getUserData(dispatch)
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      if(user?.inviteCode){
        setLoading(false)
      }
    }
  }

  const handleInvite = async () => {
    const inviteMessage = `🎉 *Join Topupplayground and Get Rewarded!* 🎉
    
Invite your friends to experience the best in gaming top-ups and earn *₹${amount}* cashback when they recharge their account with *₹${rechargeAmount}* worth of R Coins.
  
🔑 *Use My Invite Code: ${user?.inviteCode}*
  
👉 *Join Now and Start Earning!*`;
  
    try {
      const shareData = {
        title: "Topupplayground Invitation",
        text: inviteMessage,
        url: `https://topupplayground.com/register/${user?.inviteCode}`,
      };

      await navigator.share(shareData);
    } catch (error) {
      console.error("Error sharing invitation: ", error);
    }
  };
  

  return (
    <>
      <Layout>
        <div className={`account-modal-container d-lg-none d-md-none `}>
          <HeroSvg />
          <div className="account-modal-container-content">
            <div className="top-left-content">
              <div className="box-1 center" onClick={onClose}>
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
              </div>
              <h2 className="mb-0">Refer & Earn</h2>
            </div>
            <div
              className="top-right-content box-2 center"
              onClick={() => setSideMenu(!sideMenu)}
            >
              <svg
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-list text-white"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                />
              </svg>
            </div>
          </div>

          <div className="refer-and-earn-container px-3">
            <div className="bg-white text-center w-100 rounded-5 mb-3 py-4">
              <img
                src={referimage}
                width={260}
                className="refer-and-earn-image"
                alt="img"
                loading="lazy"
              />
            </div>
            <div className="refer-and-earn-content px-3">
              <h1 className="text-center py-2">Earn ₹{amount}</h1>
              {user?.inviteCode && (
                <h3
                className="text-center invite-code-input bg-light border-dotted p-3 center gap-2"
                onClick={handleCopy}
                >
                  {user?.inviteCode}
                  <span><ContentCopyIcon /></span>
                </h3>
              )}
              <p style={style}>You will earn 1% cashback on every transaction made by your referral.</p>
              <p style={style}><strong>Example:</strong> If your referral places an order worth ₹800, you will receive ₹8 as cashback.</p>

              {!user?.inviteCode ? (
                <button
                  className="generate-code-button mt-2"
                  disabled={loading}
                  onClick={saveInviteCode}
                >
                  {loading ? "Loading..." : "GENERATE INVITE CODE"}
              </button>
              ) : (
                <button
                  className="generate-code-button mt-2 center gap-2"
                  onClick={handleInvite}
                >
                  <svg width="16" height="16" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5"/>
                  </svg>
                  REFER NOW
                </button>
              )}
              <div className="important text-start p-2 mt-3">
                <p style={style}>1-Generate and copy your referral code.</p>
                <p style={style}>
                  2-Invite friends to sign up with your code and earn {amount}₹ when they add {rechargeAmount}₹ or more to their wallet for the first time.
                </p>
                <p style={style}>
                  Note: Only the first wallet addition of {rechargeAmount}₹ or more is eligible. Subsequent transactions won’t count.
                </p>
              </div>

            </div>
          </div>
        </div>

        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
      </Layout>
    </>
  );
};

const style = {
  letterSpacing: '-0.2px',
  fontSize: '14px',
  color: 'gray',
  fontWeight: '400'
}

export default ReferAndEarnMobile;
