import React, { useEffect, useState } from 'react';
import AdminLayout from './components/AdminLayout';
import { message, Modal, Button } from 'antd';
import axios from 'axios';

const AdminAddMoney = () => {
  const [form, setForm] = useState({ email: "", amount: "", category: "", desc: "" });
  const [itemId, setItemId] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState('');

  const [allAddedMoney, setAllAddedMoney] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);


  const [allRewardList, setAllRewardList] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDesc, setSelectedDesc] = useState("");
  const [currentTab, setCurrentTab] = useState("addMoney"); // Tab state

  // Generate unique item ID
  const generateItemId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    setItemId(`${year}${month}${day}${hours}${minutes}${seconds}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const validateForm = () => {
    if (!form.email || !form.amount || !form.category || !form.desc) {
      message.error("All fields are required");
      return false;
    }
    if (!/^\S+@\S+\.\S+$/.test(form.email)) {
      message.error("Invalid email format");
      return false;
    }
    if (isNaN(form.amount) || form.amount <= 0) {
      message.error("Amount should be a positive number");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const res = await axios.post(
        "/api/admin/add-money-user-wallet",
        { ...form, itemId },
        { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setForm({ email: "", amount: "", category: "", desc: "" });
        getAllAddedMoneyToUserWallet();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to add money to user wallet");
    } finally {
      setLoading(false);
      generateItemId();
    }
  };

  const getAllAddedMoneyToUserWallet = async (page = 1) => {
    try {
      const res = await axios.get(`/api/admin/get-all-added-money-user-wallet?page=${page}&limit=50`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (res.data.success) {
        setAllAddedMoney(res.data.data);
        setTotalPages(res.data.totalPages);
        setCurrentPage(res.data.page);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to fetch data");
    }
  };
  

  const getAllTop20RecentRewardList = async () => {
    try {
      const res = await axios.get("/api/admin/get-all-top-20-recent-reward-list", {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (res.data.success) {
        const sortedRewards = res.data.data.sort((a, b) => a.position - b.position);
        setAllRewardList(sortedRewards);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateRewardStatus = async (rewardId) => {
    setLoadingId(rewardId);
    try {
      const res = await axios.put(
        `/api/admin/update-reward-status/${rewardId}`,
        { status: "delivered" },
        { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
      );
      if (res.data.success) {
        message.success(res.data.message);
        getAllTop20RecentRewardList();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to update reward status");
    } finally{
      setLoadingId('');
    }
  };

  const showModal = (desc) => {
    setSelectedDesc(desc);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    generateItemId();
    getAllAddedMoneyToUserWallet();
    getAllTop20RecentRewardList();
  }, []);

  return (
    <AdminLayout>
      {currentTab === "addMoney" ? (
        <div className="page-title mb-3">
          <h4 className="m-0 no-wrap">Add Money</h4>
          <div className='d-flex justify-content-between align-items-center gap-2'>
            <h6 className='mb-0'>Total - {allAddedMoney?.length}</h6>
            <button className="btn btn-success btn-sm" onClick={() => setCurrentTab("recentRewards")}>
              Recent Reward List
            </button>
          </div>
        </div>
        ): (
          <button className="btn btn-success mb-3" onClick={() => setCurrentTab("addMoney")}>
            Back
          </button>
        )
      }
      {currentTab === "addMoney" ? (
        <div className="noti-container">
          <form className="form-fields" onSubmit={handleSubmit}>
            <input
              type="text"
              name="email"
              className="form-control mb-3"
              placeholder="Enter User Email Id"
              onChange={handleChange}
              value={form.email}
            />
            <input
              type="number"
              name="amount"
              className="form-control mb-3"
              placeholder="Enter Amount"
              onChange={handleChange}
              value={form.amount}
            />
            <select
              name="category"
              className="form-control mb-3"
              value={form.category}
              onChange={handleChange}
            >
              <option value="">Select Category</option>
              <option value="Refund">Refund</option>
              <option value="Add Money">Add Money</option>
              <option value="Reward">Reward</option>
            </select>
            <input
              type="text"
              name="desc"
              className="form-control mb-3"
              placeholder="Reason"
              onChange={handleChange}
              value={form.desc}
            />
            <button type="submit" className="btn btn-primary">
              {loading ? "Loading..." : "Submit"}
            </button>
          </form>
          <div className="my-5 overflow-auto">
            {allAddedMoney.length !== 0 ? (<table className="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Item ID</th>
                  <th>Email</th>
                  <th>Before</th>
                  <th>Amount</th>
                  <th>After</th>
                  <th>Date</th>
                  <th>Desc</th>
                  <th>Category</th>
                </tr>
              </thead>
              <tbody>
                {allAddedMoney?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.itemId}</td>
                    <td>{item.email}</td>
                    <td>{item.beforeBalance}</td>
                    <td>{item.amount}</td>
                    <td>{item.afterBalance}</td>
                    <td className='no-wrap'>{new Date(item.date).toLocaleString()}</td>
                    <td className='no-wrap center'>
                      <button className="btn btn-secondary p-0 px-1" onClick={() => showModal(item.desc)}>
                        <small>View</small>
                      </button>
                    </td>
                    <td className='no-wrap'>{item.category}</td>
                  </tr>
                ))}
              </tbody>
              </table>) : (
              <div className='center h-100'>
                <div className="spinner-border text-secondary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
              )
            }

            <div className="pagination gap-2">
              {/* Previous Button */}
              <button
                className="btn btn-primary btn-sm"
                disabled={currentPage === 1}
                onClick={() => getAllAddedMoneyToUserWallet(currentPage - 1)}
              >
                &lt;
              </button>

              {/* Page Numbers */}
              {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => {
                // Show only a few pages and the last pages for better UX
                const isEllipsisBefore = page === 2 && currentPage > 4;
                const isEllipsisAfter = page === totalPages - 1 && currentPage < totalPages - 3;
                const isWithinRange =
                  page === 1 ||
                  page === totalPages ||
                  Math.abs(currentPage - page) < 3;

                if (isEllipsisBefore) return <span className='rounded' key={page}>...</span>;
                if (isEllipsisAfter) return <span className='rounded' key={page}>...</span>;

                return (
                  isWithinRange && (
                    <button
                      key={page}
                      className={`btn btn-sm ${page === currentPage ? "active" : ""}`}
                      onClick={() => getAllAddedMoneyToUserWallet(page)}
                    >
                      {page}
                    </button>
                  )
                );
              })}

              {/* Next Button */}
              <button
                className="btn btn-primary btn-sm"
                disabled={currentPage === totalPages}
                onClick={() => getAllAddedMoneyToUserWallet(currentPage + 1)}
              >
                &gt;
              </button>
            </div>

          </div>
        </div>
        ):
        <div className="noti-container">
          <div className="overflow-scroll">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Reward</th>
                  <th>Position</th>
                  <th>Total Spent</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {allRewardList?.map((reward, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className='no-wrap'>{reward.name}</td>
                    <td>{reward.email}</td>
                    <td className='no-wrap'>{reward.reward}</td>
                    <td>{reward.position}</td>
                    <td>{reward.totalSpent}</td>
                    <td>{reward.status}</td>
                    <td>
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => updateRewardStatus(reward._id)}
                        disabled={reward.status === "delivered"}
                      >
                        {loadingId === reward._id ? "Loading.." : "Update"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      }
      <Modal title="Description" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>{selectedDesc}</p>
      </Modal>
    </AdminLayout>
  );
};

export default AdminAddMoney;
