import React from 'react';
import { CloseSvg } from '../components/Header/AccountModalSvg';

const HomePageMobileAlert = ({popupData, onClose}) => {
  return (
    <>
    <div className="mobile-alert-container d-lg-none d-md-none px-3 mt-2">
        <div className="mobile-alert-content rounded-4 d-flex justify-content-between align-items-center p-2 gap-3">
            <div className="d-flex justify-content-between align-items-center h-100 gap-2">
              <img className="mobile-alert-content-image rounded-3" src={popupData.image} alt="img" loading="lazy" />
              <div>
                <h2 className='mb-0'>{popupData.title}</h2>
                <p className="mb-0" onClick={()=>{window.location.href = popupData.link}}><small>{popupData.desc}</small></p>
              </div>
            </div>
            <div onClick={onClose} className='text-secondary pe-2'>
              <CloseSvg />
            </div>
        </div>
    </div>
    </>
  )
}

export default HomePageMobileAlert
