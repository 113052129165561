import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: null,
  banners: null,
  notification: {
    image: "",
    title: "",
    desc: "",
    link: "",
    display: ""
  },
  packageCategory: '',
  allOrders: null,
  singlePurchase: null,
  balance: "",
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setBanners: (state, action) => {
      state.banners = action.payload;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setAllOrders: (state, action) => {
      state.allOrders = action.payload;
    },
    setSinglePurchase: (state, action) => {
      state.singlePurchase = action.payload;
    },
    setPackageCategory: (state, action) => {
      state.packageCategory = action.payload;
    },
  },
});

export const { setProducts, setBanners, setBalance, setAllOrders, setSinglePurchase, setNotification, setPackageCategory } =
  dataSlice.actions;

export default dataSlice.reducer;
