import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import CryptoJS from "crypto-js";
import "./AdminUsers.css";
import "./AdminAddProduct.css";

const AdminAddProduct = () => {
  const params = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [yokcash, setYokcash] = useState(null);
  const [yokcashData, setYokcashData] = useState(null);
  const [moogold, setMoogold] = useState(null);
  const [indian, setIndian] = useState(null);
  const [prime, setPrime] = useState(null);
  const [servers, setServers] = useState(null);
  const [list, setList] = useState(null);
  const [packagesCategories, setPackagesCategories] = useState([]);
  const [form, setForm] = useState({
    name: "",
    desc: "",
    productCategory: "",
    api: "",
    apiName: "",
    gameName: "",
    region: "",
    stock: "Yes",
    categoryId: "",
    servers: [{}],
    images: [],
    seq: "",
    userIdImgLink: "",
    use: "",
    fields: "",
    fieldOne: "",
    fieldTwo: "",
    playerCheckBtn: "no",
  });

  const getProduct = async () => {
    try {
      const res = await axios.post(
        "/api/product/get-product",
        {
          id: params.id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setForm(res.data.data);
        setCost(res.data.data.cost);
        const sortedPackagesCategories = res.data.categories.sort(
          (a, b) => a.position - b.position
        );
        setPackagesCategories(sortedPackagesCategories);
        setSelectedFile(res.data.data.images);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);

  // YOKCASH
  const uniqueCategories = Array.from(
    new Set(yokcash?.map((item) => item.kategori))
  );

  const [cost, setCost] = useState([
    {
      id: "",
      amount: "",
      price: "",
      pimg: "",
      resPrice: "",
      profit: "",
      category: "",
      tag: "",
      discount: "",
    },
  ]);

  const handleAddCostField = (index) => {
    const updatedCost = [...cost];
    updatedCost.splice(index + 1, 0, {
      id: "",
      amount: "",
      price: "",
      pimg: "",
      resPrice: "",
      profit: "",
      category: "",
      tag: "",
      discount: "",
    });
    setCost(updatedCost);
  };
  const handleRemoveCostField = (index) => {
    const updatedCost = [...cost];
    updatedCost.splice(index, 1);
    setCost(updatedCost);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setForm({ ...form, [name]: e.target.files });
    } else if (
      name.startsWith("id") ||
      name.startsWith("amount") ||
      name.startsWith("price") ||
      name.startsWith("pimg") ||
      name.startsWith("resPrice") ||
      name.startsWith("category") ||
      name.startsWith("tag") ||
      name.startsWith("discount") ||
      name.startsWith("profit")
    ) {
      const index = parseInt(name.split("-")[1]);
      const updatedCost = [...cost];
      const property = name.startsWith("amount")
        ? "amount"
        : name.startsWith("price")
        ? "price"
        : name.startsWith("pimg")
        ? "pimg"
        : name.startsWith("resPrice")
        ? "resPrice"
        : name.startsWith("profit")
        ? "profit"
        : name.startsWith("category")
        ? "category"
        : name.startsWith("tag")
        ? "tag"
        : name.startsWith("discount")
        ? "discount"
        : "id";
      updatedCost[index][property] = value;
      setCost(updatedCost);
    } else if (name === "gameName") {
      const selectedCategory = e.target.value;
      setForm({
        ...form,
        gameName: selectedCategory,
      });
      const filteredData = yokcash?.filter(
        (item) => item.kategori === selectedCategory
      );
      setYokcashData(filteredData);
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleAddProduct = async () => {
    const formData = new FormData();
    formData.append("name", form?.name);
    formData.append("api", form?.api);
    formData.append("apiName", form?.apiName);
    formData.append("fields", form?.fields);
    formData.append("fieldOne", form?.fieldOne);
    formData.append("fieldTwo", form?.fieldTwo);
    formData.append("gameName", form?.gameName);
    formData.append("region", form?.region);
    formData.append("stock", form?.stock);
    formData.append("desc", form?.desc);
    formData.append("productCategory", form?.productCategory);
    formData.append("categoryId", form?.categoryId);
    formData.append("playerCheckBtn", form?.playerCheckBtn);
    formData.append("servers", JSON.stringify(form.servers)); // Stringify the cost array
    formData.append("cost", JSON.stringify(cost)); // Stringify the cost array
    formData.append("image", selectedFile);
    formData.append("seq", form?.seq);
    formData.append("userIdImgLink", form?.userIdImgLink);
    formData.append("use", form?.use);

    setLoading(true);

    try {
      const res = await axios.post("/api/product/add-product", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        navigate("/admin-products");
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading files:", error);
    }
  };

  const getMobileLegendGame = async () => {
    try {
      const res = await axios.post(
        "/api/product/get-mobile-legend",
        { region: form?.region },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.success) {
        setData(res.data.data.product);
      } else {
        message.error("Api Error, Try after sometime");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (form?.region !== "") {
      getMobileLegendGame();
    }
    // eslint-disable-next-line
  }, [form?.region]);

  const fetchYokcashServices = async () => {
    try {
      const res = await axios.get("/api/yokcash/service", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setYokcash(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchMoogoldList = async () => {
    try {
      const res = await axios.post(
        "/api/moogold/list-product",
        {
          categoryId: form?.categoryId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setList(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    if (form?.categoryId !== "") {
      fetchMoogoldList();
    }
    // eslint-disable-next-line
  }, [form?.categoryId]);

  const fetchMoogoldServices = async () => {
    try {
      const res = await axios.post(
        "/api/moogold/moogold-product",
        {
          product_id: form?.gameName,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setMoogold(res.data.data.Variation);
        setForm((prevFormData) => ({
          ...prevFormData,
          fields: res.data.data.fields,
        }));
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchMoogoldServers = async () => {
    try {
      const res = await axios.post(
        "/api/moogold/moogold-servers",
        {
          product_id: form?.gameName,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setServers(res.data.data);
        setForm((prevFormData) => ({
          ...prevFormData,
          servers: res.data.data,
        }));
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchIndianServices = async () => {
    try {
      const res = await axios.get("/api/indian/packages", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setIndian(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchPrimeSmmServices = async () => {
    try {
      const res = await axios.get("/api/prime/packages", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setPrime(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    if (form?.apiName === "yokcash") {
      fetchYokcashServices();
    } else if (form?.apiName === "moogold" && form?.gameName !== "") {
      fetchMoogoldServices();
      fetchMoogoldServers();
    } else if (form?.apiName === "indianSmart") {
      fetchIndianServices();
    } else if (form?.apiName === "primeSmm") {
      fetchPrimeSmmServices();
    }
  }, [form.apiName, form?.gameName]);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Add Product</h3>
        </div>
        <hr />
        <div className="add-product-container">
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="image"
              required
              multiple
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              name="name"
              onChange={handleChange}
              value={form?.name}
              type="text"
              placeholder="Enter name"
            />
          </div>
          <div className="form-fields mb-3">
            <textarea
              style={{ border: "1px solid #000" }}
              name="desc"
              id=""
              cols="30"
              rows="3"
              placeholder="Description"
              className="form-control"
              onChange={handleChange}
              value={form?.desc}
            ></textarea>
          </div>

          <div className="form-fields mb-3">
            <label className="text-dark">
              <small>Select Category</small>
            </label>
            <select
              onChange={handleChange}
              value={form?.productCategory}
              name="productCategory"
              className="w-100"
            >
              <option value="">Category</option>
              <option value="Mobile Games">Mobile Games</option>
              <option value="Pc Games">Pc Games</option>
              <option value="Game Vouchers">Game Vouchers</option>
              <option value="OTT Accounts">OTT Accounts</option>
              <option value="Social Media Services">
                Social Media Services
              </option>
            </select>
          </div>

          <div className="form-fields mb-3">
            <input
              className="w-100"
              name="seq"
              onChange={handleChange}
              value={form?.seq}
              type="text"
              placeholder="Sequence"
            />
          </div>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              name="userIdImgLink"
              onChange={handleChange}
              value={form?.userIdImgLink}
              type="text"
              placeholder="Add User Id Image Link"
            />
          </div>

          <div className="form-fields mb-3">
            <label className="text-dark">
              <small>Select API</small>
            </label>
            <select
              onChange={handleChange}
              value={form?.api}
              name="api"
              className="w-100"
            >
              <option value="">API BASED?</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          {form?.api === "yes" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.apiName}
                name="apiName"
                className="w-100"
              >
                <option value="">Select API</option>
                <option value="smileOne">Smile One Api</option>
                <option value="moogold">Moogold</option>
                <option value="indianSmart">Indian Smart Panel</option>
                <option value="primeSmm">Prime Smm</option>
                <option value="yokcash">Yokcash Api</option>
              </select>
            </div>
          )}

          {form?.api === "yes" && form?.apiName === "yokcash" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.gameName}
                name="gameName"
                className="w-100"
              >
                <option value="">Select Game</option>
                {uniqueCategories?.map((item, index) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
            </div>
          )}

          {form?.api === "yes" && form?.apiName === "primeSmm" && (
            <div className="form-fields mb-3">
              <select
                value={form?.gameName}
                onChange={handleChange}
                className="w-100"
                name="gameName"
              >
                <option value="">Select Category</option>
                {prime &&
                  [...new Set(prime?.map((item) => item.category))]?.map(
                    (category, index) => {
                      return (
                        <option key={index} value={category}>
                          {category}
                        </option>
                      );
                    }
                  )}
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "indianSmart" && (
            <div className="form-fields mb-3">
              <select
                value={form?.gameName}
                onChange={handleChange}
                className="w-100"
                name="gameName"
              >
                <option value="">Select Category</option>
                {indian &&
                  [...new Set(indian?.map((item) => item.category))]?.map(
                    (category, index) => {
                      return (
                        <option key={index} value={category}>
                          {category}
                        </option>
                      );
                    }
                  )}
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "moogold" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.categoryId}
                name="categoryId"
                className="w-100"
              >
                <option value="">Select Moogold Category</option>
                <option value="50">Direct-top up products</option>
                <option value="51">Other Gift Cards</option>
                <option value="1391">Amazon Gift Cards</option>
                <option value="1444">Apple Music</option>
                <option value="755">Garena Shells</option>
                <option value="538">Google Play</option>
                <option value="2433">iTunes Gift Card</option>
                <option value="1223">League of Legends</option>
                <option value="874">Netflix</option>
                <option value="765">PSN</option>
                <option value="451">Razer Gold</option>
                <option value="1261">Riot Access Code</option>
                <option value="992">Spotify</option>
                <option value="993">Steam</option>
                <option value="2377">Apex Legends</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "moogold" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.gameName}
                name="gameName"
                className="w-100"
              >
                <option value="">Select Game</option>
                {list &&
                  list?.map((item, index) => {
                    return <option value={item.ID}>{item?.post_title}</option>;
                  })}
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "smileOne" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.region}
                name="region"
                className="w-100"
              >
                <option value="">Select Region</option>
                <option value="brazil">Brazil</option>
                <option value="philippines">Philippines</option>
              </select>
            </div>
          )}
          {form?.apiName === "smileOne" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.use}
                name="use"
                className="w-100"
              >
                <option value="">Allow User</option>
                <option value="a">a</option>
                <option value="b">b</option>
                <option value="c">c</option>
              </select>
            </div>
          )}
          <div className="form-fields mb-3">
            <label htmlFor="" className="text-dark">
              <small>Stock</small>
            </label>
            <select
              onChange={handleChange}
              value={form?.stock}
              name="stock"
              className="w-100"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          {cost &&
            cost?.map((item, index) => (
              <div className="d-flex form-fields mb-3" key={index}>
                <input
                  className="w-100"
                  name={`id-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.id || ""}
                  type="text"
                  placeholder="Enter id"
                />
                <input
                  className="w-100"
                  name={`amount-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.amount || ""}
                  type="text"
                  placeholder="Enter Amount"
                />
                <input
                  className="w-100"
                  name={`profit-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.profit || ""}
                  type="text"
                  placeholder="Enter Buying Price"
                />
                <input
                  className="w-100"
                  name={`price-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.price || ""}
                  type="text"
                  placeholder="Enter Price"
                />
                <input
                  className="w-100"
                  name={`resPrice-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.resPrice || ""}
                  type="text"
                  placeholder="Enter Reseller Price"
                />
                <select
                  className="w-100"
                  name={`category-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.category || ""}
                  type="text"
                  placeholder="Select Category"
                >
                  <option value="">Select Product</option>
                  {packagesCategories.map((data) => (
                    <option key={data._id} value={data.category}>
                      {data.category}
                    </option>
                  ))}
                </select>
                <input
                  className="w-100"
                  name={`tag-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.tag || ""}
                  type="text"
                  placeholder="Add Tag"
                />
                <input
                  className="w-100"
                  name={`discount-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.discount || ""}
                  type="text"
                  placeholder="Add Discount"
                />
                <input
                  className="w-100"
                  name={`pimg-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.pimg || ""}
                  type="text"
                  placeholder="Enter Image"
                />
                <button onClick={() => handleAddCostField(index)}>+</button>
                {index > 0 && (
                  <button onClick={() => handleRemoveCostField(index)}>
                    -
                  </button>
                )}
              </div>
            ))}

          <div className="form-fields mb-3">
            <select
              onChange={handleChange}
              value={form?.fields}
              name="fields"
              className="w-100"
            >
              <option value="">Fields</option>
              <option value="1">1 (USER ID/PlayerId/Link)</option>
              <option value="2">2 (USERID ZONEID)</option>
              <option value="3">3 (USERID AND SELECT SERVER)</option>
            </select>
          </div>

          {(form?.fields === "1" ||
            form?.fields === "2" ||
            form?.fields === "3") && (
            <div className="form-fields mb-3">
              <input
                className="w-100"
                name="fieldOne"
                onChange={handleChange}
                value={form?.fieldOne}
                type="text"
                placeholder="Enter field one"
              />
            </div>
          )}
          {(form?.fields === "2" || form?.fields === "3") && (
            <div className="form-fields mb-3">
              <input
                className="w-100"
                name="fieldTwo"
                onChange={handleChange}
                value={form?.fieldTwo}
                type="text"
                placeholder="Enter field two"
              />
            </div>
          )}

          <div className="form-fields mb-3">
            <label className="text-dark">
              <small>Player Check Button</small>
            </label>
            <select
              onChange={handleChange}
              value={form?.playerCheckBtn}
              name="playerCheckBtn"
              className="w-100"
            >
              <option value="">Player Check Button</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          <button className="w-100 py-3" onClick={handleAddProduct}>
            Add
          </button>
        </div>
      </div>

      {/* API PRO LIST  */}
      {form?.apiName === "smileOne" && data && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>ID</th>
              <th>SPU</th>
              <th>PRICE</th>
              <th>COST PRICE</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr>
                  <td>{item?.id}</td>
                  <td>{item.spu}</td>
                  <td>{item.price}</td>
                  <td>{item.cost_price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {form?.apiName === "moogold" && moogold && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>PRICE</th>
            </tr>
          </thead>
          <tbody>
            {moogold &&
              moogold?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.variation_id}</td>
                    <td>{item?.variation_name}</td>
                    <td>{item.variation_price}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      {form?.apiName === "indianSmart" && indian && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>RATE</th>
              <th>MIN</th>
              <th>MAX</th>
              <th>CATEGORY</th>
              <th>TYPE</th>
            </tr>
          </thead>
          <tbody>
            {indian &&
              indian
                ?.filter((item) => item.category === form?.gameName)
                ?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.service}</td>
                      <td>{item?.name}</td>
                      <td>{item.rate}</td>
                      <td>{item.min}</td>
                      <td>{item.max}</td>
                      <td>{item.category}</td>
                      <td>{item.type}</td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      )}

      {form?.apiName === "primeSmm" && prime && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>RATE</th>
              <th>MIN</th>
              <th>MAX</th>
              <th>CATEGORY</th>
              <th>TYPE</th>
            </tr>
          </thead>
          <tbody>
            {prime &&
              prime
                ?.filter((item) => item.category === form?.gameName)
                ?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.service}</td>
                      <td>{item?.name}</td>
                      <td>{item.rate}</td>
                      <td>{item.min}</td>
                      <td>{item.max}</td>
                      <td>{item.category}</td>
                      <td>{item.type}</td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      )}

      {form?.apiName === "yokcash" && yokcashData && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>Product Id</th>
              <th>Name</th>
              <th>Category</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {yokcashData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.id}</td>
                  <td>{item?.nama_layanan}</td>
                  <td>{item?.kategori}</td>
                  <td>{item?.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </AdminLayout>
  );
};

export default AdminAddProduct;
