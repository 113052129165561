import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useSelector } from "react-redux";
import axios from "axios";
import { Button, message, Spin } from "antd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./Query.css";

const Query = () => {
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(0);
  const [queries, setQueries] = useState(null);
  const [singleQuery, setSingleQuery] = useState(null);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState("");

  async function handleSubtmit(id, e) {
    e.preventDefault();
    if (!msg.trim()) {
      message.error("Please write a query before submitting.");
      return; // Prevent the form submission if the message is empty
    }
    try {
      const res = await axios.post("/api/contact/update-query", {
        id: id,
        msg: msg,
        person: "user",
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        message.success(res.data.message);
        getUserQuery(id);
        setMsg("");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getUserQuery(id) {
    setLoading(true);
    try {
      const res = await axios.post("/api/contact/get-user-query", {
        email: user?.email,
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        setQueries(res.data.data.reverse());

        if (id) {
          // Search for the query with the given id
          const singleQuery = res.data.data.find(query => query._id === id);
          if (singleQuery) {
            setSingleQuery(singleQuery); // Set the single query if found
          } else {
            message.error("Query with the given ID not found.");
          }
        }
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user !== null) {
      getUserQuery();
    }
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="title">
          <h4 className="mb-3 fw-bold">All Queries</h4>
          {tab === 0 &&
            <div className="rounded-4 p-2 bg-white overflow-scroll">
              {!loading?
                <table className="table ">
                  <thead>
                    <tr>
                      <th className="no-wrap">#</th>
                      <th>TicketId</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th className="center">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {queries?.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            <small>{index + 1}</small>
                          </td>
                          <td>
                            <small>{item.ticketId}</small>
                          </td>
                          <td>
                            <small>{item?.type}</small>
                          </td>
                          <td className={`${item?.status === "seen"? "text-danger" : "text-success"} fw-bold text-sm`}>
                            <small>{item?.status === "seen"? "CLOSED": "ACTIVE"}</small>
                          </td>
                          <td className="center">
                            <button
                              onClick={() => {
                                setTab(1);
                                setSingleQuery(item);
                              }}
                              className={`btn btn-sm ${item?.status === "seen" ? "btn-danger" : "btn-success"} text-white rounded-2 px-2`}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                :
                <div className="my-5 center">
                  <Spin size="large" />
                </div>
              }
            </div>
          }
          {tab === 1 && (
            <>
            <div className="rounded-4 p-3 bg-white overflow-scroll">
              <div className="text-dark gap-2 d-flex justify-content-between align-items-center border-bottom pb-2" >
                <div className="center gap-2 pointer" onClick={() => setTab(0)}>
                  <ArrowBackIcon className="icon text-dark" />
                  Back
                </div>
                <div className="pointer" onClick={()=>{getUserQuery(singleQuery?._id)}}>
                  {loading?  <Spin size="small" /> : <button className="btn btn-success rounded-2 px-2 py-0 m-0 pb-1"><small>Refresh</small></button>}
                  
                </div>
              </div>
              <div className="query-reply-container overflow-scroll">
                {singleQuery?.msg?.map((item, index) => {
                  return (
                    <div
                      className={`query-msg rounded-4 ${
                        item?.person === "user" && "active"
                      }`}
                    >
                      {item?.msg}
                    </div>
                  );
                })}
              </div>
              <form action="" onSubmit={(e)=>{handleSubtmit(singleQuery?._id, e)}}>

                {singleQuery.status === "pending" && (
                  <textarea
                    onChange={(e) => setMsg(e.target.value)}
                    className="mt-0 mb-3 form-control rounded-4 p-3"
                    name="msg"
                    required
                    placeholder="Add Some Query"
                    value={msg}
                    rows="2"
                  >

                  </textarea>
                )}
                {singleQuery.status === "seen" ? (
                  <>
                    <hr />
                    <span className="text-white text-center d-block bg-danger rounded-4 p-3">
                      Admin Has Closed this ticket
                    </span>
                  </>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-success w-100 p-3 rounded-4"
                  >
                    SUBMIT
                  </button>
                )}
              </form>

              
            </div>
            </>
          )}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Query;
