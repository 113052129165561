import React, { useEffect, useRef, useState } from "react";
import "./SinglePaymentModalReport.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from "axios";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setSinglePurchase } from "../../redux/features/dataSlice";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PurchaseInvoice from "./PurchaseInvoice";

const SinglePurchaseModalReport = ({ onClose, item }) => {
  const invoiceRef = useRef();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false)
  const { singlePurchase } = useSelector((state) => state.data);
  const [loading, setLoading] = useState(false);
  const [showAmount, setShowAmount] = useState(false);
  const [showUserId, setShowUserId] = useState(false);

  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
      case "processing":
      case "inprogress":
        return "warning";
      case "success":
      case "completed":
        return "success";
      case "rejected":
      case "failure":
      case "failed":
        return "danger";
      case "refunded":
        return "primary";
      case "refilling":
        return "secondary";
      case "cancelled":
        return "dark";
      default:
        return "";
    }
  };

  const getOrderById = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        "/api/order/get-order-by-id",
        { orderId: item?.orderId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        const data = res.data.data;
        dispatch(setSinglePurchase(data));
        if (data?.iid && data.status !== "success") {
          getOrderStatus("indian", data);
        }
        if (data?.pid && data.status !== "Completed") {
          getOrderStatus("prime", data);
        }
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderById();
    // eslint-disable-next-line
  }, []);

  const getOrderStatus = async (api, data) => {
    try {
      const response = await axios.post(
        `/api/${api}/updateStatus`,
        { iid: data.iid, pid: data.pid, orderId: data.orderId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response && response.data.success) {
        dispatch(setSinglePurchase(response.data.data));
        message.success(response.data.message);
      } else if (response) {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching order status:", error);
      message.error("An error occurred while updating the order status.");
    }
  };

  const copyToClipboard = (event, text) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Order ID copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy!");
        console.error("Failed to copy: ", err);
      });
  };

 
  const downloadInvoice = async () => {
    setVisible(true);
  
    setTimeout(async () => {
      const invoiceElement = invoiceRef.current;
      
      // Capture the content as a canvas
      const canvas = await html2canvas(invoiceElement, {
        scale: 2,
        useCORS: true,
      });
  
      const imgData = canvas.toDataURL("image/png");
      
      // Get the width and height of the content in pixels
      const imgWidthPx = canvas.width;
      const imgHeightPx = canvas.height;
  
      // Convert pixels to millimeters
      const pxToMm = 0.264583;
      const imgWidthMm = imgWidthPx * pxToMm;
      const imgHeightMm = imgHeightPx * pxToMm;
  
      // Create a new jsPDF instance with custom page size
      const doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: [imgWidthMm, imgHeightMm],
      });
  
      // Add the image to the PDF
      doc.addImage(imgData, 'PNG', 0, 0, imgWidthMm, imgHeightMm);
  
      // Save the PDF
      doc.save(`Invoice_${singlePurchase?.orderId}.pdf`);
      setVisible(false);
    }, 100);
  };

  const haveAnyComplaint = (data) => {
    const whatsappNumber = '+917350712254';
  
    // Extract details from data
    const orderDetails = data.orderDetails || "N/A";
    const productinfo = data.productinfo || "N/A";
    const paymentMode = data.paymentMode || "N/A";
    const createdAt = new Date(data.createdAt).toLocaleString("default", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Use 12-hour format with AM/PM
    }) || "N/A";
    const amount = data.amount || "N/A";
    const zoneId = data.zoneId || "N/A";
    const userId = data.userId || "N/A";
    const orderId = data.orderId || "N/A";
    const status = data.status || "N/A";
  
    // Format the message with line breaks
    const message = `*Complaint Details:*\n\n` +
      `*Product Info:* ${productinfo}\n` +
      `*Order ID:* ${orderId}\n` +
      `*Quantity:* ${orderDetails}\n` +
      `*Amount:* ${amount}₹\n` +
      `*Status:* ${status}\n` +
      `*User ID:* ${userId}\n` +
      `*Zone ID:* ${zoneId}\n` +
      `*Created At:* ${createdAt}\n` +
      `*Mode:* ${paymentMode}\n`;
  
    // Encode the message for a URL
    const encodedMessage = encodeURIComponent(message);
  
    // Construct the WhatsApp link
    const whatsappLink = `https://wa.me/${whatsappNumber.replace('+', '')}?text=${encodedMessage}`;
  
    // Open the link in the browser (if running in a browser environment)
    window.open(whatsappLink, '_blank');
  };
  

  return (
    <div className="SinglePaymentModalReport-modal-overlay">
      {!visible && <div
        className={`SinglePaymentModalReport-modal-content border-bottom border-3 border-${getStatusClass(
          (singlePurchase?.status)?.toLowerCase()
        )}`}
      >
        {!loading ? (
          <div>
            <button
              className="SinglePaymentModalReport-close-button rounded-pill center"
              onClick={onClose}
            >
              +
            </button>
            <h2>Purchase Details</h2>

            <p className="d-flex justify-content-start align-items-center gap-2">
              <strong>Order ID:</strong>
              <span
                className="px-2 py-1 border bg-light rounded center gap-1"
                onClick={(event) => {
                  copyToClipboard(
                    event,
                    `Purchase Id: ${singlePurchase?.orderId}`
                  );
                }}
              >
                <span>{singlePurchase?.orderId}</span>
                <ContentCopyIcon className="fs-6" />
              </span>
            </p>

            {singlePurchase?.userId && (
              <p>
                <strong>
                  {singlePurchase?.userId.startsWith("http")
                    ? "Link"
                    : "UserId"}
                  :
                </strong>{" "}
                {singlePurchase?.userId.startsWith("http") ? (
                  <a
                    className="wrap"
                    href="#"
                    onClick={() => handleLinkClick(singlePurchase?.userId)}
                  >
                    {singlePurchase?.userId}
                  </a>
                ) : (
                  
                  <span
                    className="amount-mask"
                    onClick={() => setShowUserId(!showUserId)}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    title={showUserId ? "Hide user ID" : "Show full user ID"}
                  >
                    {showUserId
                      ? singlePurchase?.userId
                      : `${singlePurchase?.userId.slice(0, -5)}*****`}
                  </span>
                )}
              </p>
            )}

            {singlePurchase?.zoneId && (
              <p>
                <strong>ZoneId:</strong> {singlePurchase?.zoneId}
              </p>
            )}
            <p>
              <strong>Product Info:</strong> {singlePurchase?.productinfo}
            </p>
            <p>
              <strong>Amount:</strong>{" "}
              <span
                className="amount-mask"
                onClick={() => setShowAmount(!showAmount)}
                style={{ cursor: "pointer", textDecoration: "underline" }}
                title={showAmount ? "Hide amount" : "Show amount"}
              >
                {showAmount ? `₹${parseFloat(singlePurchase?.amount).toFixed(2)}` : "*****"}
              </span>
            </p>
            <p>
              <strong>Quantity:</strong> {singlePurchase?.orderDetails}
            </p>
            <p className={`text-${getStatusClass((singlePurchase?.status)?.toLowerCase())}`}>
              <strong>Status:</strong> <span className="fw-bold">{singlePurchase?.status === "success"? "Delivered Successfully" : singlePurchase?.status}</span>
            </p>
            <p>
              <strong>Date:</strong>{" "}
              {new Date(singlePurchase?.createdAt).toLocaleString()}
            </p>
            <p>
              <strong>Payment Mode:</strong> {singlePurchase?.paymentMode}
            </p>
            {/* Add more details as needed */}

            <div className="mt-3 d-flex gap-2">
              <button className={`btn btn-sm fw-bold rounded-3 btn-${getStatusClass((singlePurchase?.status)?.toLowerCase())}`} onClick={downloadInvoice}>
                Download Invoice
              </button>
              <button className={`btn btn-sm fw-bold rounded-3 btn-warning`} onClick={()=>{haveAnyComplaint(singlePurchase)}}>
                Any complaint?
              </button>
            </div>
          </div>
        ) : (
          <div>
            <button
              className="SinglePaymentModalReport-close-button rounded-pill center"
              onClick={onClose}
            >
              +
            </button>
            <h2>Purchase Details</h2>
            <div className="d-flex justify-content-center mt-4">
              <div className="spinner-border text-secondary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          </div>
        )}
      </div>}

      {visible &&
      <div>
        <PurchaseInvoice
          singlePurchase={singlePurchase}
          handleLinkClick={handleLinkClick}
          getStatusClass={getStatusClass}
          invoiceRef={invoiceRef}
        />
      </div>}
    </div>
  );
};

export default SinglePurchaseModalReport;
