import React, { useEffect, useState } from "react";
import "./Games.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const Games = ({ title }) => {
  const navigate = useNavigate();
  const { products } = useSelector((state) => state.data);

  const renderCategory = (category, maxItems) => {
    const filteredProducts = products?.filter(
      (item) => item.productCategory === category
    );
    if (filteredProducts && filteredProducts.length > 0) {
      return (
        <>
          <div className="container mb-5">
            <SectionTitle title={category} navigateTo={`/games/${category}`} />
            <div className="popular-games bg-white border py-4 px-4 rounded-4">
              <div className="games-list">
                {filteredProducts.slice(0, maxItems).map((item, index) => (
                  <div
                    key={index}
                    className="game bg-white shadow"
                    onClick={() => navigate(`/product/${item?.name}`)}
                  >
                    <div className="game-img p-2">
                      <img
                        src={`https://topupplayground.com/${item?.image}`}
                        alt="pro-img"
                        loading="lazy"
                      />
                      {item.stock !== "Yes" && (
                        <div className="out-of-stock px-2">
                          <p className="fs-6">Out of Stock</p>
                        </div>
                      )}
                    </div>
                    <div className="game-content pb-2">
                      <h5 className="m-0">{item?.name}</h5>
                      <span>
                        <small className="text-secondary">
                          Starts ₹{item.cost[0]?.price}
                        </small>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div
                onClick={() => navigate(`/games/${category}`)}
                className="text-center pointer mt-5"
              >
                <span className="d-flex justify-content-left align-items-center">
                  <span className="view-all p-2 px-4 rounded-pill center">
                    <span className="text-white fw-bold"> View All</span>
                    <svg
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="text-white"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707z"
                      />
                    </svg>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  const SectionTitle = ({ title, navigateTo, category }) => {
    const navigate = useNavigate();
    return (
      <div className="titlee w-100 my-4">
        <h4 className="fw-bold mb-0 border px-4 py-2 rounded-pill">{title}</h4>
      </div>
    );
  };

  SectionTitle.propTypes = {
    title: PropTypes.string.isRequired,
    navigateTo: PropTypes.string.isRequired,
  };

  return (
    <div className="explore-products-container">
      {renderCategory("Mobile Games", 100)}
      {renderCategory("Pc Games", 100)}
      {renderCategory("Game Vouchers", 100)}
      {renderCategory("OTT Accounts", 100)}
      {renderCategory("Social Media Services", 100)}
    </div>
  );
};

export default Games;
