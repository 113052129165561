import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tippy from "@tippyjs/react";
import LogoutTippy from "./LogoutTippy";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SearchContainer from "../SearchContainer";
import CartSideMenu from "../CartSideMenu";
import MobileHeader from "./MobileHeader.js";
import { setShowSearchModal } from "../../redux/features/footerMenuSlice.js";
import logo from "../../img/logo3.png";

const Header = () => {
  const { user } = useSelector((state) => state.user);
  const { showSearchModal } = useSelector((state) => state.footerMenu);
  const { balance } = useSelector((state) => state.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(false);
  const [cartMenu, setCartMenu] = useState(false);

  return (
    <>
      <div className="header-container w-100">
        <header className="header d-none d-md-block d-lg-block container w-100">
          <div className="header-main">
            <div
              className="burger-icon d-none d-lg-none"
              onClick={() => setSideMenu(!sideMenu)}
            >
              <MenuIcon className="icon" />
            </div>
            <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
            {/* <Backdrop sideMenu={sideMenu} setSideMenu={setSideMenu} /> */}
            <div className="logo gap-2" onClick={() => navigate("/")}>
              <img className="rounded-4" width={48} src={logo} alt="logo" loading="lazy"/>
              <span>Topup Playground</span>
            </div>
            <div className="action-btns gap-3">
              <div className="menus d-none d-md-none d-lg-block">
                <ul className="p-0">
                  <li>
                    <Link to="/games">Games</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  {!user && (
                    <li>
                      <Link to="/login">My Account</Link>
                    </li>
                  )}
                  {user && (
                    <li>
                      <Link to="/user-dashboard">Dashboard</Link>
                    </li>
                  )}
                  {/* <li>
                    <Link to="/about">About Us</Link>
                  </li> */}
                </ul>
              </div>
              {user && (
                <div
                  onClick={() => navigate("/wallet")}
                  className="p-1 pointer shadow-sm px-3 rounded-pill text-dark bg-white  d-flex jsutify-content-between align-items-center"
                >
                  <span>{parseFloat(balance).toFixed(2)}</span>
                  <span className="ms-2">
                    <AccountBalanceWalletIcon className="" />
                  </span>
                </div>
              )}
              <SearchIcon
                onClick={() => dispatch(setShowSearchModal(!showSearchModal))}
                className="fs-2 p-2 pointer center shadow-sm rounded-pill bg-white text-dark"
              />
              <Tippy
                interactive
                theme="light"
                content={<LogoutTippy user={user && user} />}
              >
                <span
                  // onClick={handleLogout}
                  className="menu-img-container d-flex"
                >
                  {user ? (
                    <>
                      <div className="p-1 pointer shadow-sm px-2 rounded-pill text-dark bg-warning d-flex jsutify-content-between align-items-center">
                        <PersonIcon
                          className=""
                          onClick={() => navigate("/user-dashboard")}
                        />
                        <KeyboardArrowDownIcon className="d-lg-block d-md-none d-none text-dark" />
                      </div>
                    </>
                  ) : (
                    <div
                      className="p-1 sing-up pointer bg-warning shadow-sm px-3 rounded-pill text-dark fw-bold d-flex jsutify-content-between align-items-center"
                      onClick={() => navigate("/login")}
                    >
                      <span>Sign up</span>
                    </div>
                  )}
                </span>
              </Tippy>
            </div>
          </div>
        </header>
      </div>

      <MobileHeader />
      <CartSideMenu cartMenu={cartMenu} setCartMenu={setCartMenu} />
      <SearchContainer />
    </>
  );
};

export default Header;
