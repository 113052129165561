import React, { useEffect, useState } from "react";
import { Layout, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { setActiveButton } from "../../redux/features/footerMenuSlice";
import HeroSvg from "../../components/Layout/HeroSvg";
import SideMenu from "../../components/Header/SideMenu";
import getUserData from "../../utils/userDataService";
import ProductInfoContinueButton from "./ProductInfoContinueButton";
import { submitPaymentForm } from "./submitPaymentForm";
import { setUser } from "../../redux/features/userSlice";
import InstructionModal from "./InstructionModal";
import "./ProductInfoMobile.css";
import UpdateMobileNumberModal from "../../components/Modal/UpdateMobileNumberModal";
import {
  ChevronLeftSvg,
  ListSvg,
} from "../../components/AllSvgModulesExport/LoginSvg";
import {
  CheckSvg,
  ExclamationMarkSvg,
  QuestionMarkSvg,
  TickMarkSvg,
  UserIdSvg,
  ZoneIdSvg,
} from "../../components/Header/AccountModalSvg";
import UserIdImageModal from "./UserIdImageModal";
import ReviewUsTrustPilot from "../../components/ReviewUsTrustPilot";

const ProductInfoMobile = ({ setWebsite }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const name = params.name;
  const category = params.category;
  const [sideMenu, setSideMenu] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [allowUser, setAllowUser] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [originalPrice, setOriginalPrice] = useState(null);
  const [singleProductPackagesCategories, setSingleProductPackagesCategories] =
    useState([]);
  const [packageCategory, setPackageCategory] = useState("");
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState("UPI");
  const [showPopupUpdateMobile, setShowUpdateMobileModal] = useState(false);
  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [balance, setBalance] = useState("");
  const [loader, setLoader] = useState(false);
  const [openUserIdZoneIdModal, setOpenUserIdZoneIdModal] = useState(false);
  // GATEWAY
  const [gateway, setGateway] = useState("BARCODE");

  const onClose = () => {
    dispatch(setActiveButton("home"));
    return navigate("/");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (name) {
      getProduct();
    }
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    if (category && product) {
      setPackageCategory(category);

      const filteredCost = product?.cost?.filter(
        (item) => item.category === category
      );

      const defaultAmount = filteredCost?.[0]?.amount;
      const defaultPrice =
        user && user?.reseller === "yes"
          ? filteredCost?.[0]?.resPrice
          : filteredCost?.[0]?.price;
      const defaultId = filteredCost?.[0]?.id;
      const defaultOriginalPrice = filteredCost?.[0]?.profit;
      setAmount(defaultAmount);
      setSelectedPrice(defaultPrice);
      setProductId(defaultId);
      setOriginalPrice(defaultOriginalPrice);
    }
    // eslint-disable-next-line
  }, [category, product]);

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";

    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
    // eslint-disable-next-line
  }, []);

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      const pf = product?.cost?.find((item) => item.amount === amount)?.profit;
      setOriginalPrice(pf);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      const pf = product?.cost?.find((item) => item.amount === amount)?.profit;
      setOriginalPrice(pf);
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post(
        "/api/product/get-product-by-name",
        {
          name: params.name,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setProduct(res.data.data);

        // Sort categories by position
        const sortedCategories = res.data.singleProductPackagesCategories.sort(
          (a, b) => a.position - b.position
        );
        setSingleProductPackagesCategories(sortedCategories);
        // Set default category to the first item in sorted categories
        if (sortedCategories.length > 0) {
          setPackageCategory(sortedCategories[0].category);
        }

        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user && user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        const defaultOriginalPrice = res.data.data?.cost?.[0]?.profit;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
        setOriginalPrice(defaultOriginalPrice);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, [params?.name]);

  async function handleCheckPlayer() {
    setCheckLoading(true);
    if (userId === "" || zoneId === "") {
      setCheckLoading(false);
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    setCheckLoading(true);
    try {
      const object = {
        userid: userId,
        zoneid: zoneId,
        apiName: product?.apiName,
        gameName: product?.gameName,
      };
      const res = await axios.post("/api/payment/get-role", object, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        if(res.data.apiName === "mg"){
          if(res.data.data.status === "false"){
            setAllowUser(false);
            setPlayerCheck(null);
            return message.error(res.data.data.message);
          } 
          if(res.data.data.status === "true"){
            setAllowUser(true);
            setPlayerCheck(res.data.data.username);
            return message.success(res.data.data.message);
          } 
        }
        if(res.data.apiName === "sm"){
          if (product?.use === res.data.use ) {
            setPlayerCheck(res.data.username);
            setAllowUser(true);
          } 
        }
      } else {
        setPlayerCheck(null);
        setAllowUser(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCheckLoading(false);
      setError(false);
    }
  }

  //* ================================= ORDER SYSTEM ==========================================
  function checkPlaceOrder(e, paymentMode, orderId) {
    setMode(paymentMode);
    if (product?.playerCheckBtn === "yes") {
      if (playerCheck === null) {
        setShowConfirmModal(false);
        setError(true);
        return message.error("Validate username");
      }
    }
    if (product?.api === "no") {
      if (userId === "") {
        setShowConfirmModal(false);
        setError(true);
        return message.error("Some Fields are missing");
      }
    } else if (product.api === "yes" && product?.apiName === "yokcash") {
    } else if (product.api === "yes" && product?.apiName === "indianSmart") {
      const userConfirmed = window.confirm("is your instagram account public?");
      if (!userConfirmed) {
        return message.error("Make your insta account public");
      }
      if (userId === "") {
        return message.error("Enter Link");
      }
    } else if (product.api === "yes" && product?.apiName === "primeSmm") {
      if (userId === "") {
        return message.error("Enter Link");
      }
      const userConfirmed = window.confirm("is your instagram account public?");
      if (!userConfirmed) {
        return message.error("Make your insta account public");
      }
    } else if (product?.apiName === "moogold") {
      if (product?.fields?.length === 1) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
      } else if (product?.fields?.length === 2) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
    }

    if (product?.api === "yes") {
      if (product?.apiName === "yokcash") {
        if (paymentMode === "UPI") {
          handleYokcashUpiOrder(e, orderId);
        } else {
          handleYokcashWalletOrder(e, orderId);
        }
      } else if (product?.apiName === "smileOne") {
        if (paymentMode === "UPI") {
          if (gateway === "BARCODE") {
            handleBarcodeSmileUpi(e, orderId);
          } else if (gateway === "PAYU") {
            handleSmileOneUpiOrder(e, orderId);
          }
        } else {
          handleSmileOneWalletOrder(e, orderId);
        }
      } else if (product?.apiName === "moogold") {
        if (paymentMode === "UPI") {
          if (gateway === "BARCODE") {
            handleBarcodeMoogoldUpi(e, orderId);
          } else if (gateway === "PAYU") {
            handleMoogoldUpiOrder(e, orderId);
          }
        } else {
          handleMoogoldWalletOrder(e, orderId);
        }
      } else if (product?.apiName === "indianSmart") {
        if (paymentMode === "UPI") {
          if (gateway === "BARCODE") {
            handleBarcodeIndianSmartUpiOrder(e, orderId);
          } else if (gateway === "PAYU") {
            handleIndianSmartUpiOrder(e, orderId);
          }
        } else {
          handleIndianSmartWalletOrder(e, orderId);
        }
      } else if (product?.apiName === "primeSmm") {
        if (paymentMode === "UPI") {
          if (gateway === "BARCODE") {
            handleBarcodePrimeSmmUpiOrder(e, orderId);
          } else if (gateway === "PAYU") {
            handlePrimeSmmUpiOrder(e, orderId);
          }
        } else {
          handlePrimeSmmWalletOrder(e, orderId);
        }
      }
    } else {
      if (paymentMode === "UPI") {
        if (gateway === "BARCODE") {
          handleBarcodeUpiOrder(e, orderId);
        } else if (gateway === "PAYU") {
          handleUpiOrder(e, orderId);
        }
      } else {
        handleWalletOrder(e, orderId);
      }
    }
  }

  // Yokcash
  async function handleYokcashUpiOrder(e, orderId) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/yokcash/status?orderId=${orderId}`,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          originalPrice,
      };

      setLoader(true);
      const response = await axios.post(
        "/api/payment/create-api-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }
  async function handleYokcashWalletOrder(e, orderId) {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      setShowConfirmModal(false);
      return message.error(
        "Insufficient wallet balance to complete this order."
      );
    }
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          originalPrice,
      };

      setLoading(true);
      setLoader(true);
      const res = await axios.post(
        "/api/yokcash/place-yokcash-from-wallet",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/user-dashboard");
        setLoading(false);
        setLoader(false);
      } else {
        setLoading(false);
        setLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  // SMILE
  const handleSmileOneUpiOrder = async (e, orderId) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        // product_details: product?.region + "#" + amount,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/smile/success",
        // failure_URL: "http://localhost:8080/api/smile/failure",
        success_URL: "https://topupplayground.com/api/smile/success",
        failure_URL: "https://topupplayground.com/api/smile/failure",
        udf1: product?.region + "@" + originalPrice, // region
        udf2: productId, // productId
        udf3: userId.trim(), // userId
        udf4: zoneId.trim(), // zoneId
        udf5: amount, // amount
      };
      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSmileOneWalletOrder = async (e, orderId) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error(
        "Insufficient wallet balance to complete this order."
      );
    }
    e.preventDefault();
    setLoader(true);
    try {
      const orderObject = {
        orderId: orderId,
        userid: userId.trim(),
        zoneid: zoneId.trim(),
        productid: productId,
        region: product.region,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        name: user?.fname,
        price: selectedPrice,
        originalPrice: originalPrice,
      };

      const res = await axios.post("/api/smile/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  };
  const handleBarcodeSmileUpi = async (e, orderId) => {
    e.preventDefault();
    try {
      setLoader(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/smile/status?orderId=${orderId}`,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          originalPrice,
      };

      const response = await axios.post("/api/smile/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  };

  // MOOGOLD
  async function handleMoogoldUpiOrder(e, orderId) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        // product_details: product?.region + "#" + amount,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/moogold/success",
        // failure_URL: "http://localhost:8080/api/moogold/failure",
        success_URL: "https://topupplayground.com/api/moogold/success",
        failure_URL: "https://topupplayground.com/api/moogold/failure",

        // product_details: product?.categoryId + "@" + product?.gameName,
        udf1: product?.gameName + "@" + originalPrice, // product gameName
        udf2: productId, // productId
        udf3: userId.trim(),
        udf4: zoneId.trim(),
        udf5: amount,
      };
      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  }
  async function handleMoogoldWalletOrder(e, orderId) {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error(
        "Insufficient wallet balance to complete this order."
      );
    }
    e.preventDefault();
    try {
      setLoader(true);
      const orderObject = {
        api: product?.api,
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.categoryId + "@" + product?.gameName,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        originalPrice: originalPrice,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const res = await axios.post("/api/moogold/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  }
  async function handleBarcodeMoogoldUpi(e, orderId) {
    e.preventDefault();
    try {
      setLoader(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.gameName,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/moogold/status?orderId=${orderId}`,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          originalPrice,
      };

      const response = await axios.post("/api/moogold/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  }

  // INDIANSMART
  async function handleIndianSmartUpiOrder(e, orderId) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/indian/success",
        // failure_URL: "http://localhost:8080/api/indian/failure",
        success_URL: "https://topupplayground.com/api/indian/success",
        failure_URL: "https://topupplayground.com/api/indian/failure",
        udf1: userId.trim(),
        udf2: productId,
        udf3: amount,
        udf4: originalPrice,
        udf5: "",
      };
      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  }
  async function handleIndianSmartWalletOrder(e, orderId) {
    e.preventDefault();
    try {
      if (parseInt(balance) < parseInt(selectedPrice)) {
        return message.error(
          "Insufficient wallet balance to complete this order."
        );
      }
      setLoader(true);
      const orderObject = {
        orderId: orderId,
        price: selectedPrice,
        pname: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        userid: userId.trim(),
        productid: productId,
        amount: amount,
        originalPrice: originalPrice,
      };
      const res = await axios.post("/api/indian/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  }
  async function handleBarcodeIndianSmartUpiOrder(e, orderId) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/indian/status?orderId=${orderId}&link=${userId}`,
        txn_note:
          userId.trim() + "@" + productId + "@" + amount + "@" + originalPrice,
      };

      setLoader(true);
      const res = await axios.post("/api/indian/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        window.location.href = res.data.data.results.payment_url;
      } else {
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  }

  // PRIME SMM
  async function handlePrimeSmmUpiOrder(e, orderId) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/indian/success",
        // failure_URL: "http://localhost:8080/api/indian/failure",
        success_URL: "https://topupplayground.com/api/prime/success",
        failure_URL: "https://topupplayground.com/api/prime/failure",
        udf1: userId.trim(),
        udf2: productId,
        udf3: amount,
        udf4: originalPrice,
        udf5: "",
      };
      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {}
  }
  async function handlePrimeSmmWalletOrder(e, orderId) {
    e.preventDefault();
    try {
      if (parseInt(balance) < parseInt(selectedPrice)) {
        return message.error(
          "Insufficient wallet balance to complete this order."
        );
      }
      const orderObject = {
        orderId: orderId,
        price: selectedPrice,
        pname: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        userid: userId.trim(),
        productid: productId,
        amount: amount,
        originalPrice: originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/prime/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLoader(false);
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
        navigate("/failure");
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleBarcodePrimeSmmUpiOrder(e, orderId) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/prime/status?orderId=${orderId}&link=${userId}`,
        txn_note:
          userId.trim() + "@" + productId + "@" + amount + "@" + originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/prime/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        window.location.href = res.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(res.data.message);
        navigate("/failure");
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // MANUAL ORDER
  const handleUpiOrder = async (e, orderId) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        // product_details: product?.region + "#" + amount,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/manual/success",
        // failure_URL: "http://localhost:8080/api/manual/failure",
        success_URL: "https://topupplayground.com/api/manual/success",
        failure_URL: "https://topupplayground.com/api/manual/failure",
        udf1: userId.trim(), // userId
        udf2: amount, // amount
        udf3: originalPrice,
        udf4: "",
        udf5: "",
      };

      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };
  const handleWalletOrder = async (e, orderId) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      setShowConfirmModal(false);
      return message.error(
        "Insufficient wallet balance to complete this order."
      );
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        orderId: orderId,
        userid: userId.trim(),
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
        originalPrice: originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/manual/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  };
  const handleBarcodeUpiOrder = async (e, orderId) => {
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/manual/status?orderId=${orderId}`,
        txn_note:
          userId.trim() + "@" + productId + "@" + amount + "@" + originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/manual/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        window.location.href = res.data.data.results.payment_url;
      } else {
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      // setLoader(false);
    }
  };

  const handlePackageCategoryChange = (value) => {
    setPackageCategory(value);
    const filteredCost = product?.cost?.filter(
      (item) => item.category === value
    );
    const defaultAmount = filteredCost?.[0]?.amount;
    const defaultPrice =
      user && user?.reseller === "yes"
        ? filteredCost?.[0]?.resPrice
        : filteredCost?.[0]?.price;
    const defaultId = filteredCost?.[0]?.id;
    const defaultOriginalPrice = filteredCost?.[0]?.profit;
    setAmount(defaultAmount);
    setSelectedPrice(defaultPrice);
    setProductId(defaultId);
    setOriginalPrice(defaultOriginalPrice);
  };

  const getUserPrice = (data) => {
    if (user?.reseller === "yes") {
      return data.resPrice;
    } else {
      return data.price;
    }
  };

  const HandleUserIdImageModal = () => {
    setOpenUserIdZoneIdModal(true);
  };

  return (
    <>
      <Layout>
        <div className={`account-modal-container d-lg-none d-md-none `}>
          <HeroSvg />
          <div className="account-modal-container-content">
            <div className="top-left-content">
              <div className="box-1 center" onClick={onClose}>
                <ChevronLeftSvg />
              </div>
              <h2 className="mb-0">Checkout</h2>
            </div>
            <div
              className="top-right-content box-2 center"
              onClick={() => setSideMenu(!sideMenu)}
            >
              <ListSvg />
            </div>
          </div>

          <div className="checkout-product-container px-3 mb-5">
            {/* HEADER TOP SECTION */}
            <div className="checkout-product-content px-3 py-3 mb-4">
              <div className="center gap-2">
                <div className="product-image">
                  <img
                    src={`https://topupplayground.com/${product?.image}`}
                    alt="loading-img"
                    loading="lazy"
                  />
                </div>
                <div className="align-self-center ps-1">
                  <h5 className="mb-1">{product?.name}</h5>
                  <p
                    className={`mb-0 font-14 opacity-70 ${
                      playerCheck ? "text-success" : "text-dark"
                    }`}
                  >
                    {playerCheck ? (
                      <>
                        {capitalizeFirstLetter(playerCheck)}
                        <CheckSvg />
                      </>
                    ) : (
                      "N.A"
                    )}
                  </p>
                </div>
              </div>
              <div
                className="p-2"
                onClick={() => {
                  setShowInstructions(true);
                }}
              >
                <ExclamationMarkSvg />
              </div>
            </div>

            {/* FORM SECTION */}
            <div
              className={`checkout-product-content form-content flex-column mb-3 position-relative`}
            >
              <div
                className="card-header w-100"
                onClick={HandleUserIdImageModal}
              >
                <p>Enter your informations</p>
                <div className="text-secondary">
                  <QuestionMarkSvg />
                </div>
              </div>
              {product?.fields === "1" ? (
                <form
                  className="content w-100 center flex-column gap-2 w-100 p-3"
                  onSubmit={handleSubmit}
                >
                  <div className="form-custom w-100">
                    <div className="form-input w-100">
                      <UserIdSvg />
                      <input
                        type="text"
                        autoComplete="off"
                        name="userId"
                        placeholder={`${product?.tagOne}`}
                        onChange={(e) => setUserId(e.target.value)}
                        value={userId}
                        className={`form-control w-100  ${
                          error ? "is-invalid" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className={`invalid-feedback mb-2 ${error && "d-flex"}`}>
                    Please enter your {product?.tagOne}
                  </div>
                </form>
              ) : product?.fields === "2" ? (
                <form
                  className="content w-100 center flex-column gap-2 w-100 p-3"
                  onSubmit={handleSubmit}
                >
                  <div className="form-custom w-100">
                    <div className="form-input w-100">
                      <UserIdSvg />
                      <input
                        type="text"
                        autoComplete="off"
                        name="userId"
                        placeholder={
                          product?.tagOne ? product?.tagOne : "USER ID"
                        }
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(
                            /[^a-zA-Z0-9]/g,
                            ""
                          ); // Allows only alphanumeric characters
                          setUserId(sanitizedValue);
                        }}
                        value={userId}
                        className={`form-control w-100  ${
                          error ? "is-invalid" : ""
                        }`}
                      />
                      {product?.playerCheckBtn === "yes" && (
                        <button
                          type="submit"
                          className="validateBtn text-white py-1 px-3 rounded-3"
                          id="validateBtn"
                          onClick={handleCheckPlayer}
                        >
                          {checkLoading ? (
                            <div
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Validate"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className={`invalid-feedback mb-2 ${error && "d-flex"}`}>
                    Please enter your{" "}
                    {product?.tagOne ? product?.tagOne : "USER ID"}
                  </div>
                  <div className="form-custom w-100">
                    <div className="form-input w-100">
                      <ZoneIdSvg />
                      <input
                        type="text"
                        autoComplete="off"
                        name="zoneid"
                        placeholder={
                          product?.tagTwo ? product?.tagTwo : "(  ZONE ID  )"
                        }
                        onChange={(e) => setZoneId(e.target.value)}
                        value={zoneId}
                        id="serverId"
                        className={`form-control w-100  ${
                          error ? "is-invalid" : ""
                        }`}
                      />
                      <span>(required)</span>
                    </div>
                  </div>
                  <div className={`invalid-feedback mb-2 ${error && "d-flex"}`}>
                    Please enter your{" "}
                    {product?.tagTwo ? product?.tagTwo : "(  ZONE ID  )"}
                  </div>
                  {error && (
                    <span className="text-white w-100 bg-danger px-1 py-1 rounded-3 text-center fw-bold">
                      <small>
                        Check your username by clicking on validate button
                      </small>
                    </span>
                  )}
                  <span className="text-success user-name">
                    {playerCheck && "Username: " + playerCheck}
                  </span>
                </form>
              ) : (
                product?.fields === "3" && (
                  <form
                    className="content w-100 center flex-column gap-2 w-100 p-3"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-custom w-100">
                      <div className="form-input w-100">
                        <UserIdSvg />
                        <input
                          type="text"
                          autoComplete="off"
                          name="userId"
                          placeholder={
                            product?.tagOne ? product?.tagOne : "USER ID"
                          }
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(
                              /[^a-zA-Z0-9]/g,
                              ""
                            ); // Allows only alphanumeric characters
                            setUserId(sanitizedValue);
                          }}
                          value={userId}
                          className={`form-control w-100  ${
                            error ? "is-invalid" : ""
                          }`}
                        />
                        {product?.playerCheckBtn === "yes" && (
                          <button
                            type="submit"
                            className="validateBtn text-white py-1 px-3 rounded-3"
                            id="validateBtn"
                            onClick={handleCheckPlayer}
                          >
                            {checkLoading ? (
                              <div
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              "Validate"
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                    <div
                      className={`invalid-feedback mb-2 ${error && "d-flex"}`}
                    >
                      Please enter your{" "}
                      {product?.tagOne ? product?.tagOne : "USER ID"}
                    </div>
                    <div className="form-custom w-100">
                      <div className="form-input w-100">
                        <ZoneIdSvg />
                        <select
                          onChange={(e) => setZoneId(e.target.value)}
                          className={`form-control w-100 ${
                            error ? "is-invalid" : ""
                          }`}
                          name="zoneId"
                        >
                          <option value="">SELECT SERVER</option>
                          {product?.fieldTwo?.split("+")?.map((item, index) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div
                      className={`invalid-feedback mb-2 ${error && "d-flex"}`}
                    >
                      Please enter your{" "}
                      {product?.tagTwo ? product?.tagTwo : "(  ZONE ID  )"}
                    </div>
                    {error && (
                      <span className="text-white w-100 bg-danger px-1 py-1 rounded-3 text-center fw-bold">
                        <small>
                          Check your username by clicking on validate button
                        </small>
                      </span>
                    )}
                    <span className="text-success user-name">
                      {playerCheck && "Username: " + playerCheck}
                    </span>
                  </form>
                )
              )}
            </div>
            {/* PACKAGE CATEGORY */}
            {singleProductPackagesCategories?.length !== 0 && (
              <div className="package-category d-flex gap-2 w-100 mb-4">
                {singleProductPackagesCategories?.map((data) => {
                  return (
                    <>
                      <div
                        className={`package-category-box shadow-sm ${
                          packageCategory === data?.category && "active"
                        }`}
                        onClick={() => {
                          handlePackageCategoryChange(data?.category);
                        }}
                      >
                        <img
                          className="img"
                          width={35}
                          src={data?.link}
                          alt=""
                        />
                        <div
                          className={`title ${
                            packageCategory === data?.category && "active"
                          }`}
                        >
                          {data?.category}
                        </div>
                        {packageCategory === data?.category && (
                          <>
                            <span
                              className="position-absolute"
                              style={{
                                background: "#e4fce2",
                                width: "40px",
                                height: "17px",
                                marginTop: "0",
                                top: "-5px",
                                right: "-10px",
                                transform: "rotate(40deg)",
                              }}
                            ></span>
                            <TickMarkSvg color="success" />
                          </>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            )}
            {/* PRODUCT SECTION */}
            <div
              className={`position-relative rounded-4 px-1 ${
                !product?.cost && "loading overflow-hidden"
              }`}
            >
              <div
                className={`checkout-product-content-package-container column w-100 ${
                  !product?.cost && "loading"
                }`}
                style={{ height: `${!product?.cost ? "400px" : "auto"}` }}
              >
                {product?.cost?.filter((item) =>
                  packageCategory ? item.category === packageCategory : true
                )?.length > 0 ? (
                  product?.cost
                    ?.filter((item) =>
                      packageCategory ? item.category === packageCategory : true
                    )
                    ?.map((item, index) => {
                      return (
                        <div
                          onClick={() => {
                            setAmount(item.amount);
                            setPriceAndId(item.amount);
                          }}
                          key={index}
                          className={`col-12 px-0 position-relative mb-3`}
                        >
                          <div
                            className={`product-info-item-card ${
                              amount === item?.amount && "highlighted"
                            } overflow-hidden`}
                          >
                            <div
                              className={`px-3 ${
                                amount === item?.amount
                                  ? "bg-highlighted"
                                  : "bg-white"
                              } py-3 d-flex justify-content-between align-items-center w-100 position-relative gap-2`}
                            >
                              <div className="center gap-2">
                                <span className="icon">
                                  {item?.pimg && (
                                    <img
                                      width={55}
                                      className="rounded-3"
                                      src={item?.pimg}
                                      alt=""
                                      loading="lazy"
                                    />
                                  )}
                                </span>
                                <div className="d-flex jsutify-content-start align-items-start gap-1 flex-column">
                                  <span className="price">{item.amount}</span>
                                  <div className="d-flex jsutify-content-center align-items-center gap-2">
                                    {item.tag && (
                                      <div
                                        className={`center no-wrap tag px-2 fw-bold text-white ${
                                          item.tag === "Best Seller"
                                            ? "bg-yellow"
                                            : item.tag === "On Sale"
                                            ? "bg-black"
                                            : item.tag === "Premium"
                                            ? "bg-premium"
                                            : "bg-green"
                                        }`}
                                      >
                                        {item.tag}
                                      </div>
                                    )}
                                    {item.discount && (
                                      <div
                                        className={`center no-wrap tag-2 px-2 fw-bold text-white bg-red`}
                                      >
                                        {item.discount}% OFF
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="">
                                  {item.discount ? (
                                    <div className="center gap-2 flex-wrap">
                                      <div className="cut-price price">
                                        {Math.floor(
                                          Number(getUserPrice(item)) +
                                            (Number(getUserPrice(item)) *
                                              Number(item.discount)) /
                                              100
                                        )}
                                        ₹
                                      </div>
                                      <div className="discounted-price price">
                                        {getUserPrice(item)}₹{" "}
                                        {/* Discounted price */}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="price">
                                      {getUserPrice(item)}₹
                                    </div>
                                  )}
                                </div>
                              </div>
                              {amount === item?.amount && (
                                <>
                                  <span
                                    className="position-absolute"
                                    style={{
                                      background: "rgb(250, 51, 51)",
                                      width: "40px",
                                      height: "17px",
                                      marginTop: "0",
                                      top: "-5px",
                                      right: "-15px",
                                      transform: "rotate(40deg)",
                                    }}
                                  ></span>
                                  <TickMarkSvg color="white" />
                                </>
                              )}
                            </div>
                            <span
                              className="position-absolute top-0 left-100 translate-middle badge rounded-pill"
                              style={{
                                background: "rgb(250, 51, 51)",
                                left: "25px",
                              }}
                            >
                              99%
                            </span>
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <div className="no-package-found text-center bg-white text-secondary border border-secondary rounded-4 p-4 mt-4">
                    No Package Found
                  </div>
                )}
              </div>
            </div>
            <ReviewUsTrustPilot />
          </div>
        </div>

        {
          <ProductInfoContinueButton
            setWebsite={setWebsite}
            loader={loader}
            checkPlaceOrder={checkPlaceOrder}
            selectedPrice={selectedPrice}
            product={product}
            playerCheck={playerCheck}
            amount={amount}
            setMode={setMode}
            showConfirmModal={showConfirmModal}
            setShowConfirmModal={setShowConfirmModal}
            userId={userId}
            allowUser={allowUser}
            setShowUpdateMobileModal={() => {
              setShowUpdateMobileModal(!showPopupUpdateMobile);
            }}
          />
        }
        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
        <InstructionModal
          showInstructions={showInstructions}
          setShowInstructions={setShowInstructions}
          product={product}
        />
        {showPopupUpdateMobile && (
          <UpdateMobileNumberModal
            path={location.pathname}
            handleClose={() => {
              setShowUpdateMobileModal(!showPopupUpdateMobile);
            }}
          />
        )}
        {product?.userIdImgLink && (
          <UserIdImageModal
            imgLink={product?.userIdImgLink}
            show={openUserIdZoneIdModal}
            onClose={() => {
              setOpenUserIdZoneIdModal(false);
            }}
          />
        )}
      </Layout>
    </>
  );
};

export default ProductInfoMobile;
