import React from "react";
import "./ReviewUsTrustPilot.css";
import image from "../img/trustpilot.png";
import image2 from "../img/trustpilot-logo-black-5-stars.png";
import Reviews from "./Reviews";

const ReviewUsTrustPilot = () => {
  const handleClick = () => {
    window.open("https://www.trustpilot.com/review/topupplayground.com", "_blank");
  };

  return (
    <>
      <div className="center my-4 mt-5">
        <img
          height={28} /* Reduced height */
          src={image2}
          alt="Trustpilot logo"
          onClick={handleClick}
        />
      </div>
      <div
        className="d-block ReviewUsTrustPilot-container mt-4 mb-5"
        onClick={handleClick}
      >
        <div className="p-3 center">
          <div className="align-items-center justify-content-center text-white gap-2 text-center">
            <h2 className="mb-1">Review us on</h2>
            <img className="my-1" width={24} src={image} alt="Trustpilot logo" />
            <h2 className="mb-1">Trustpilot</h2>
            <p className="mt-2 mb-0">
              Your feedback helps us improve!
            </p>
          </div>
        </div>
      </div>
      {/* <Reviews /> */}
    </>
  );
};

export default ReviewUsTrustPilot;
