import React from "react";
import { Modal } from "antd";
import './SinglePaymentModalReport.css'; 

const DateRangeFilter = ({
  onClose,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  clearFilters,
  handleDateChange
}) => {
  return (
    <Modal
      title="Filter"
      open={true}
      onOk={handleDateChange}
      onCancel={clearFilters}
      className="date-range-filter-modal"
      width={300}
      centered
      footer={null} // Custom footer to be used
    >
      <div className="date-range-filter-container bg-light p-3 py-3 rounded w-100">
        <div className="w-100">
          <label>Start Date</label>
          <input
            type="date"
            className="form-control w-100 mt-2 text-dark p-2"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="w-100">
          <label>End Date</label>
          <input
            type="date"
            className="form-control w-100 mt-2 text-dark p-2"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <div className="date-range-filter-footer mt-3">
        <button className="btn btn-sm fw-bold rounded-5 btn-warning w-100 py-2" onClick={clearFilters}>
          CLEAR FILTER
        </button>
        <button className="btn btn-sm fw-bold rounded-5 btn-success w-100 py-2" onClick={handleDateChange}>
          APPLY FILTER
        </button>
      </div>
    </Modal>
  );
};

export default DateRangeFilter;
