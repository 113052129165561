import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import "./Account.css";
import getUserData from "../utils/userDataService";

const Account = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [form, setForm] = useState({password: ''});

  const handleUpdate = async (e) => {
    e.preventDefault();

    // Password validation check
    const password = form?.password;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/; // Check for lowercase, uppercase, number, and minimum 8 characters
    if (password && !passwordPattern.test(password)) {
      message.error("Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.");
      return; // Prevent form submission if password doesn't meet the criteria
    }

    try {
      const res = await axios.post("/api/user/user-profile-update", form,  {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        setForm({ ...form, password: "" });
        getUserData(dispatch);
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setForm(user);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="">
          <div className="user-account-info mb-4">
            <div className="d-flex justify-content-start align-items-center gap-2">
              <div className={`${form?.reseller === "yes" ? "pro-member" : "normal-member"} account-name-box  rounded-pill center p-3`}>
                F {/*isme form?.fname ka first char add karo */}
              </div>
              <div className="">
                <h2 className="user-name mb-0">{form?.fname}</h2>
                <div className="user-status">
                  {form?.reseller === "yes" ? (
                    <span className="badge pro-member">Pro Member</span>
                  ) : (
                    <span className="badge normal-member">Normal Member</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row px-2 py-3 bg-light rounded-4 mx-0">
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Name
                  </label>
                  <input
                    value={form?.fname}
                    onChange={(e) =>
                      setForm({ ...form, fname: e.target.value })
                    }
                    type="text"
                    className="form-control p-3"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label htmlFor="" className="form-label ">
                    Email
                  </label>
                  <input
                    value={form?.email}
                    type="text"
                    className="form-control p-3"
                    readOnly
                    disabled
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label htmlFor="" className="form-label ">
                    Mobile
                  </label>
                  <input
                    value={form?.mobile}
                    readOnly
                    disabled
                    onChange={(e) =>
                      setForm({ ...form, mobile: e.target.value })
                    }
                    type="text"
                    className="form-control p-3"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label htmlFor="" className="form-label ">
                    Gender
                  </label>
                  <select
                    value={form?.gender}
                    onChange={(e) =>
                      setForm({ ...form, gender: e.target.value })
                    }
                    name="gender"
                    className="form-select p-3"
                  >
                    <option value="" disabled selected>
                      Select Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label htmlFor="" className="form-label ">
                    State
                  </label>
                  <select
                    value={form?.state}
                    onChange={(e) =>
                      setForm({ ...form, state: e.target.value })
                    }
                    className="form-select p-3"
                    name="state"
                    required
                  >
                    <option value="" disabled selected>
                      Select State
                    </option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label htmlFor="" className="form-label ">
                    Password
                  </label>
                  <input
                    onChange={(e) =>
                      setForm({ ...form, password: e.target.value })
                    }
                    value={form?.password}
                    type="text"
                    placeholder="New password"
                    className="form-control p-3"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-12 mt-3">
                <button onClick={handleUpdate} className="btn btn-success w-100 p-3">
                  Update
                </button>
              </div>
            </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Account;
