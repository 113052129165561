import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import "./Contact.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { message } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";

const Contact = () => {
  const { user } = useSelector((state) => state.user);
  const [query, setQuery] = useState([]);
  
  const generateTicketId = () => {
    const now = new Date();
    const date = now.toISOString().split('T')[0].replace(/-/g, ''); // Format: YYYYMMDD
    const time = now.toTimeString().split(' ')[0].replace(/:/g, ''); // Format: HHMMSS
    const randomNumber = Math.floor(Math.random() * 1000); // Generate a random number
  
    return `${date}${time}${randomNumber}`; // Combine to form the ticket ID
  };

  const [form, setForm] = useState({
    name: user?.fname || "",
    email: user?.email || "",
    mobile: user?.mobile || "",
    ticketId: generateTicketId(), 
    type: "",
  });

  const onQueryChange = (e) => {
    setQuery([
      {
        msg: e.target.value,
        person: "user",
      },
    ]);
  };


  const [error, setError] = useState({});

  // Update the form when user data changes
  useEffect(() => {
    if (user) {
      setForm((prev) => ({
        ...prev,
        name: user?.fname || "",
        email: user?.email || "",
        mobile: user?.mobile || "",
      }));
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.name) newErrors.name = "Name is required.";
    if (!form.email) newErrors.email = "Email is required.";
    if (!form.mobile) newErrors.mobile = "Mobile number is required.";
    if (!form.type) newErrors.type = "Query type is required.";
    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const res = await axios.post("/api/contact/add-contact-form", { ...form, msg: query, });
      if (res.data.success) {

        const messageContent = user
          ? "Message sent! You'll get a reply in your dashboard's query section."
          : "Message sent! You'll be contacted via email or WhatsApp.";

        message.success(messageContent);

        // Reset the form, keeping user data if logged in
        setForm({
          name: user?.fname || "",
          email: user?.email || "",
          mobile: user?.mobile || "",
          type: "",
          message: "",
        });
        setError({});
      } else {
        message.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error(error);
      message.error("Server error. Please try later.");
    }
  };

  return (
    <Layout>
      <div className="contact-page">
        <div className="contact-hero">
          <h1>Get in Touch</h1>
          <p>We'd love to hear from you. Fill out the form below or reach us through the provided contact details.</p>
        </div>

        <div className="contact-content">
          <div className="contact-info">
            <h2>Contact Details</h2>
            <div className="info-item">
              <LocationOnIcon />
              <p>Mumbai, Maharashtra, India</p>
            </div>
            <div className="info-item">
              <EmailIcon />
              <p>topupplaygroundofficial@gmail.com</p>
            </div>
            <div className="info-item">
              <PhoneIcon />
              <p>Only Whatsapp +91 7350712254</p>
            </div>
          </div>

          <div className="contact-form-container">
            <h2>Send Us a Message</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  disabled={!!user?.fname}
                  placeholder="Enter your name"
                />
                {error.name && <small className="error">{error.name}</small>}
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  disabled={!!user?.email}
                  placeholder="Enter your email"
                />
                {error.email && <small className="error">{error.email}</small>}
              </div>
              <div className="form-group">
                <label>Mobile</label>
                <input
                  type="text"
                  name="mobile"
                  value={form.mobile}
                  onChange={handleChange}
                  disabled={!!user?.mobile}
                  placeholder="Enter your mobile number"
                />
                {error.mobile && <small className="error">{error.mobile}</small>}
              </div>
              <div className="form-group">
                <label>Select Your Query Type</label>
                <select
                  className="form-select shadow-sm border px-3 py-2"
                  name="type"
                  onChange={handleChange}
                  value={form.type}
                >
                  <option value="">Select type of query</option>
                  <option value="Payment Related Query">Payment Related Queries</option>
                  <option value="In-Game Recharge Query">In-Game Recharge Query</option>
                  <option value="Wanted to be a Reseller">Wanted to be a Reseller</option>
                  <option value="Technical Support">Technical Support</option>
                  <option value="others">Other Query</option>
                </select>
                {error.type && <small className="error">{error.type}</small>}
              </div>
              <div className="form-group">
                <label>Message</label>
                <textarea
                  required
                  name="msg"
                  onChange={onQueryChange}
                  placeholder="Write your message here"
                />
                {error.message && <small className="error">{error.message}</small>}
              </div>
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
