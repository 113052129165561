import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import "./Register.css";
import axios from "axios";
import { message } from "antd";

const ForgotPass = () => {
  const [email, setEmail] = useState(null);
  // email otp
  const [otp, setOtp] = useState(null);
  // user enter otp
  const [userEnteredOtp, setUserEnteredOtp] = useState(null);
  const [tab, setTab] = useState(0);
  const [pass, setPass] = useState(null);
  const [cpass, setCpass] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const generateOTP = () => {
    const emailOtp = Math.floor(100000 + Math.random() * 900000);
    setOtp(emailOtp);
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post("/api/user/send-otp", {
        email,
        msg: "Reset your password easily! Your OTP for password recovery is:",
      });
      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        setTab(1);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/verify-otp", {
        email,
        userEnteredOtp,
      });
      if (res.data.success) {
        message.success(res.data.message);
        setTab(2);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    // Password validation
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
      
    if (!passwordRegex.test(pass)) {
      message.error("Password must be at least 8 characters long, contain uppercase, lowercase, a number");
      return;
    }

    if (pass === cpass) {
      try {
        const res = await axios.post("/api/user/update-pass", { email, pass });
        if (res.data.success) {
          message.success(res.data.message);
          navigate("/login");
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      message.error("Passwords does not match")
    }
  };

  useEffect(() => {
    generateOTP();
  }, []);

  return (
    <Layout>
      <div className="register-container-main">
        <div className="container-fluid register-container my-5">
          <div className="row  w-100">
            {tab === 0 && (
              <form action="" onSubmit={handleSendOtp}>
                <div className="d-block m-auto col-12 col-sm-12 col-md-12 col-lg-12 border rounded-4 p-3 p-lg-5 p-md-4 shadow-sm bg-white w-100">
                  <h4 className="text-dark fw-bold">Dont worry! Get Otp on Your Email</h4>
                  <hr />
                  <div className="mb-3 form-fields">
                    <label className="form-label text-dark" htmlFor="name">
                      Email
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Email Registered with us"
                      className="form-control rounded-3"
                      type="text"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <button type="submit" className="register-btn rounded-3 mt-3 border-0 btn btn-success shadow-sm">
                      {loading ? "Sending..." : "Send OTP"}
                    </button>
                  </div>
                  <hr />
                  <p className="text-dark fs-6 fw-bold">
                    Not a User? <Link to="/register">click here</Link>
                  </p>
                </div>
              </form>
            )}
            {tab === 1 && (
              <form action="" onSubmit={handleVerifyOtp}>
                <div className="d-block m-auto col-12 col-sm-12 col-md-12 col-lg-12 border rounded-4 p-3 p-lg-5 p-md-4 shadow-sm bg-white w-100">
                  <h4 className="text-dark fw-bold">Reset Your Password</h4>
                  <hr />
                  <div className="mb-3 form-fields">
                    <label className="form-label text-dark" htmlFor="name">
                      Verify Your Otp
                    </label>
                    <input
                      onChange={(e) => setUserEnteredOtp(e.target.value)}
                      placeholder="Enter Otp"
                      className="form-control rounded-3"
                      type="text"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <button type="submit" className="register-btn rounded-3 mt-3 border-0 btn btn-success shadow-sm">
                      Verify
                    </button>
                  </div>
                  <hr />
                  <p className="text-dark">
                    Not a User? <Link to="/register">click here</Link>
                  </p>
                </div>
              </form>
            )}
            {tab === 2 && (
              <form action="" onSubmit={handleUpdatePassword}>
                <div className="d-block m-auto col-12 col-sm-12 col-md-12 col-lg-12 border rounded-4 p-3 p-lg-5 p-md-4 shadow-sm bg-white w-100">
                  <h4 className="text-dark fw-bold">Set Your Password</h4>
                  <hr />
                  <div className="mb-3 form-fields">
                    <label className="form-label text-dark" htmlFor="name">
                      Enter Password
                    </label>
                    <input
                      onChange={(e) => setPass(e.target.value)}
                      className="form-control rounded-3"
                      type="text"
                      required
                    />
                  </div>
                  <div className="mb-3 form-fields">
                    <label className="form-label text-dark" htmlFor="name">
                      Confirm Password
                    </label>
                    <input
                      onChange={(e) => setCpass(e.target.value)}
                      className="form-control rounded-3"
                      type="text"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <button type="submit" className="register-btn rounded-3 mt-3 border-0 btn btn-success shadow-sm">
                      Update My Password
                    </button>
                  </div>
                  <hr />
                  <p className="text-dark fs-6">
                    Not a User? <Link to="/register">click here</Link>
                  </p>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPass;
