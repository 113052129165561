import React, { useEffect, useState } from "react";
import "./GamesForMobile.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setShowSearchModal } from "../redux/features/footerMenuSlice";

import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import ExploreIcon from "@mui/icons-material/Explore";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import MovieIcon from "@mui/icons-material/Movie";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import GamepadIcon from "@mui/icons-material/Gamepad";

const GamesForMobile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeCategory, setActiveCategory] = useState("All");
  const [categories, setCategories] = useState([]);
  const { products } = useSelector((state) => state.data);
  const { user } = useSelector((state) => state.user);

  // Helper function to get appropriate icon
  const getIconForCategory = (category) => {
    switch (category.toLowerCase()) {
      case "pc games":
        return <SportsSoccerIcon className="fs-5" />; // Sports icon
      case "action":
        return <FlashOnIcon className="fs-5" />; // Action icon
      case "adventure":
        return <ExploreIcon className="fs-5" />; // Adventure icon
      case "All":
        return <SportsEsportsIcon className="fs-5" />; // Games icon
      case "mobile games":
        return <PhoneAndroidIcon className="fs-5" />; // Mobile games icon
      case "ott accounts":
        return <MovieIcon className="fs-5" />; // OTT icon
      case "social media services":
        return <SocialDistanceIcon className="fs-5" />; // Social media icon
      default:
        return <GamepadIcon className="fs-5" />; // Default icon for other categories
    }
  };

  useEffect(() => {
    // Extract unique categories from products
    if (products && products.length > 0) {
      const uniqueCategories = [
        "All",
        ...new Set(products.map((item) => item.productCategory)),
      ];
      setCategories(uniqueCategories);
    }
  }, [products]);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const getUserPrice = (data) => {
    if (user?.reseller === "yes") {
      return data?.resPrice;
    } else {
      return data?.price;
    }
  };

  const renderCategory = (category, maxItems) => {
    const filteredProducts = products?.filter((item) => {
      return category === "All"
        ? item.productCategory !== category
        : item.productCategory === category;
    });
    if (filteredProducts && filteredProducts.length > 0) {
      return (
        <>
          {!category && (
            <SectionTitle
              title="Popular Games"
              navigateTo={`/games/${category}`}
            />
          )}
          <div
            className={`popular-games p-0 pt-3 bg-transparent position-relative overflow-hidden pb-4`}
          >
            <div className={`games-list  ${!products && "loading"}`}>
              {filteredProducts.slice(0, maxItems).map((item, index) => (
                <div
                  key={index}
                  className="game bg-white shadow-sm py-2"
                  onClick={() => navigate(`/product/${item?.name}`)}
                >
                  <div className="game-img">
                    <img
                      src={`https://topupplayground.com/${item?.image}`}
                      alt="pro-img"
                      loading="lazy"
                      className={`${
                        item.stock !== "Yes" && "image-low-opacity"
                      }`}
                    />
                    {item.stock !== "Yes" && (
                      <div className="out-of-stock">
                        <p>Out of Stock</p>
                      </div>
                    )}
                  </div>
                  <div className="game-content w-100">
                    <h5 className="m-0 text-center">{item?.name}</h5>
                    <span className="Space-Grotesk">
                      <small>Starts ₹{getUserPrice(item.cost[0])}</small>
                    </span>
                  </div>
                </div>
              ))}
            </div>
            {category === "All" ? (
              <Link
                to=""
                className="center gap-2 py-1 px-3 text-success border border-success rounded-pill mt-5 fs-6"
                onClick={() => {
                  dispatch(setShowSearchModal(true));
                }}
              >
                View All
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-up-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z" />
                </svg>
              </Link>
            ) : (
              <Link
                to={`/games/${category}`}
                className="center gap-2 py-1 px-3 text-success border border-success rounded-pill mt-5 fs-6"
              >
                View All {category}
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-up-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z" />
                </svg>
              </Link>
            )}
          </div>
        </>
      );
    }
    return null;
  };

  const SectionTitle = ({ title, navigateTo }) => {
    const navigate = useNavigate();
    return (
      <div className="titlee w-100">
        <h6 className="text-dark fw-bold mb-0">{title}</h6>
        <div
          className="center gap-2"
          onClick={() => navigate(navigateTo)}
          style={{ cursor: "pointer" }}
        >
          <span className="text-dark fw-bold"> View More </span>
          <svg
            width="16"
            height="16"
            fill="currentColor"
            className="text-dark"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707z"
            />
          </svg>
        </div>
      </div>
    );
  };

  SectionTitle.propTypes = {
    title: PropTypes.string.isRequired,
    navigateTo: PropTypes.string.isRequired,
  };

  return (
    <div className="explore-products-container">
      <div className="popular-games-slider d-md-none d-lg-none">
        {categories.map((category) =>
          products ? (
            products
              ?.filter(
                (item) => item?.category === category && item?.stock === "Yes"
              )
              .sort(() => Math.random() - 0.5) // Randomly shuffle the array
              .map((item, index) => (
                <div
                  key={index}
                  className="games-slider-list mb-2 shadow"
                  onClick={() => navigate(`/product/${item?.name}`)}
                >
                  <div className="games-slider-image-div">
                    <img
                      src={`https://topupplayground.com/${item?.image}`}
                      alt="pro-img"
                      loading="lazy"
                    />
                    <div className="item-name-container text-start ">
                      <div className="item-name-content">
                        <h2>{item?.name}</h2>
                        {item?.cost?.some((costItem) => costItem.discount) ? (
                          <span className="discount border border-white border-2">
                            On Sale
                          </span>
                        ) : (
                          <span className="tag border border-white border-2">
                            Popular
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div className="games-slider-list mb-2 shadow position-relative">
              <div
                className={`games-slider-image-div ${!products && "loading"}`}
              ></div>
            </div>
          )
        )}
      </div>

      {/* CATEGORY NAME SLIDER */}
      <div className="games-category-slider-container">
        <div className="games-category-slider gap-2">
          {categories.map((category) => (
            <span
              key={category}
              className={`games-category-slider-button d-flex gap-2 border border-2 shadow-sm border-white ${
                activeCategory === category
                  ? "active border-white border-2"
                  : ""
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {/* Placeholder icon, replace with your own icons based on category */}
              {/* <div className="icon">
                {getIconForCategory(category)}
              </div> */}
              <div
                className={`name ${
                  activeCategory === category ? "active" : ""
                }`}
              >
                {category === "All" ? "Top Pick" : category}
              </div>
            </span>
          ))}
        </div>
      </div>

      {renderCategory(activeCategory, 100)}
      {/* {renderCategory("Pc Games", 8)}
      {renderCategory("Game Vouchers", 8)}
      {renderCategory("OTT Accounts", 8)}
      {renderCategory("Social Media Services", 8)} */}
    </div>
  );
};

export default GamesForMobile;
