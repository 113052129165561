import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import HowItWorks from "../components/Home/HowItWorks";
import axios from "axios";
import { message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import "./ProductPage.css";
import { useSelector } from "react-redux";

const ProductPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState("Mobile Games");
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(false);
  const { products } = useSelector((state) => state.data);
  
  useEffect(() => {
    if (params?.name) {
      setCategory(params?.name);
    }
  }, [params?.name]);

  const onClose = () => {
    navigate("/");
  };

  return (
    <Layout>
      <div className="product-page-container">
        <div className="container">
          <div className="product-page-content">
            <div className="w-100 d-flex justify-content-between align-items-center gap-2 d-block d-lg-none d-md-flex">
              <div
                className="box-1 shadow-sm d-md-none d-lg-none"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  className="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
              </div>
              <div className={`category-filter w-100 d-lg-none bg-light rounded-5 px-3 shadow-sm`}>
                <span className="text-dark fw-bold w-100">{category}</span>
              </div>
              <div className={`category-filter filter d-lg-none bg-light rounded-5 px-2 shadow-sm gap-1`}
                onClick={() => setFilter(!filter)}
              >
                <svg
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-filter text-dark"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
                </svg>
                <span className="text-dark fw-bold w-100">Filter</span>
              </div>
            </div>
            <div className={`category-slider shadow ${filter && "active"}`}>
              <div className="d-md-none d-lg-none d-flex justify-content-between align-items-center mb-4">
                <h4 className="mb-0 text-dark fs-6 fw-bold mx-2">Select Category</h4>
                <div
                  className="mx-2"
                  onClick={() => {
                    setFilter(!filter);
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-x-lg text-dark"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                  </svg>
                </div>
              </div>
              <ul>
                <li
                  className={`${category === "Mobile Games" && "active"} border border-2`}
                  onClick={() => {
                    setCategory("Mobile Games");
                    setFilter(!filter);
                  }}
                >
                  <span>Mobile Games</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                    />
                  </svg>
                </li>
                <li
                  className={`${category === "Pc Games" && "active"} border border-2`}
                  onClick={() => {
                    setCategory("Pc Games");
                    setFilter(!filter);
                  }}
                >
                  <span>Pc Games</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                    />
                  </svg>
                </li>
                <li
                  className={`${category === "Game Vouchers" && "active"} border border-2`}
                  onClick={() => {
                    setCategory("Game Vouchers");
                    setFilter(!filter);
                  }}
                >
                  <span>Game Vouchers</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                    />
                  </svg>
                </li>
                <li
                  className={`${category === "OTT Accounts" && "active"} border border-2`}
                  onClick={() => {
                    setCategory("OTT Accounts");
                    setFilter(!filter);
                  }}
                >
                  <span>OTT Accounts</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                    />
                  </svg>
                </li>
                <li
                  className={`${category === "Social Media Services" && "active"} border border-2`}
                  onClick={() => {
                    setCategory("Social Media Services");
                    setFilter(!filter);
                  }}
                >
                  <span>Social Media Services</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                    />
                  </svg>
                </li>
              </ul>
            </div>
            <div className="products-container">
              {products?.filter((item) => item?.category === category).length > 0 ? (
                products
                  .filter((item) => item?.category === category)
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="product-box ms-lg-3 shadow bg-white p-2 gap-3 center flex-column mb-lg-2"
                        onClick={() => navigate(`/product/${item?.name}`)}
                      >
                        <div className="product-img">
                          <img
                            className="text-center"
                            src={`https://topupplayground.com/${item?.image}`}
                            alt={item?.name}
                            loading="lazy"
                          />
                        </div>
                        <h5 className="text-dark mb-2">{item?.name}</h5>
                      </div>
                    );
                  })
              ) : (
                <div
                  className="ms-lg-3 shadow bg-white p-2 w-100 h-100 center rounded-4"
                >
                  <h5>No game found</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <HowItWorks /> */}
    </Layout>
  );
};

export default ProductPage;
