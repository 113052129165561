import React, { useEffect, useState } from "react";
import "./TrackOrder.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { message, Modal } from "antd";
import Layout from "../components/Layout/Layout";
import HeroSvg from "../components/Layout/HeroSvg";
import SideMenu from "../components/Header/SideMenu";
import { setActiveButton } from "../redux/features/footerMenuSlice";
import TrackOrderModal from "./TrackOrderModal";

const TrackOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [sideMenu, setSideMenu] = useState(false);
  const [allPurchase, setAllPurchase] = useState(null);
  const [loading, setLoading] = useState(false);
  const [queryId, setQueryId] = useState('');

  // Close modal and navigate to home
  const onClose = () => {
    dispatch(setActiveButton("home"));
    return navigate("/");
  };


  // Fetch all user orders
  const searchOrderByUserId = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(
        "/api/order/get-orders-by-userid-or-orderid",
        { email: user?.email, queryId: queryId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setAllPurchase(res.data.data.reverse());
      } else {
        setAllPurchase(null)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "warning";
      case "processing":
        return "warning";
      case "success":
        return "success";
      case "Success":
        return "success";
      case "completed":
        return "success";
      case "rejected":
        return "danger";
      case "failure":
        return "danger";
      case "failed":
        return "danger";
      case "refunded":
        return "primary";
      case "inprogress":
        return "warning";
      case "refilling":
        return "secondary";
      case "cancelled":
        return "dark";
      default:
        return "";
    }
  };

  const handleItemClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const copyToClipboard = (event, text) => {
    event.stopPropagation(); 
    navigator.clipboard.writeText(text)
      .then(() => {
        message.success('Order ID copied to clipboard!');
      })
      .catch((err) => {
        message.error('Failed to copy!');
        console.error('Failed to copy: ', err);
      });
  };

  // ========= CLOSE ============

  return (
    <>
      <Layout>
        <div className={`account-modal-container d-lg-none d-md-none `}>
          <HeroSvg />
          <div className="account-modal-container-content">
            <div className="top-left-content">
              <div className="box-1 center" onClick={onClose}>
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
              </div>
              <h2 className="mb-0">{"Track Order"}</h2>
            </div>
            <div
              className="top-right-content box-2 center"
              onClick={() => setSideMenu(!sideMenu)}
            >
              <svg
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-list text-white"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                />
              </svg>
            </div>
          </div>

          <div className="all-reports-container px-3">

            <div className="all-reports-content px-2 py-2 mb-3">
              {/* Search Input and Button */}
              <form action="" onSubmit={searchOrderByUserId}>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <input
                    type="text"
                    className="search-input border p-1 ps-2 rounded-4 w-100"
                    placeholder="Enter UserId / OrderId"
                    onChange={(e)=>{setQueryId(e.target.value)}}
                    value={queryId}
                    required
                  />
                  <button type="submit" className="btn btn-sm btn-success rounded-pill search-button" >
                    Search
                  </button>
                </div>
              </form>
            </div>

            <div className="all-reports-content position-relative overflow-hidden">
              <p className='mb-0 border bg-light all-track-orders-content-heading px-3 d-flex justify-content-start align-items-center'>
                <span className='me-1'>Total Orders: </span>
                <span>{allPurchase? allPurchase?.length : "0"}</span>
              </p>
              {!loading?
                <div className="pb-3">
                  {allPurchase && allPurchase?.length > 0 ? (
                    <div className="active-reports-tab">
                      {allPurchase?.map((item, index) => (
                        <>
                          <div
                            key={item?.orderId}
                            className={` border-bottom py-2`}
                            onClick={handleItemClick}
                          >
                            <div className="px-2 list w-100 d-flex justify-content-between align-items-center">
                              <div className="center gap-2">
                                <div className="track-orders-icon-box center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-bag"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                                  </svg>
                                </div>
                                <div className="d-flex justify-content-center align-items-start flex-column">
                                  <span className="add-money">
                                    {item.productinfo?.toUpperCase()}
                                  </span>
                                  <span className="payment-order-id center gap-1" onClick={(event)=>{copyToClipboard(event, `Purchase Id: ${item?.orderId}`)}}>
                                    <ContentCopyIcon className='fs-6'/><span>{item?.orderId}</span>
                                  </span>
                                </div>
                              </div>

                              <div className="d-flex justify-content-center align-items-end flex-column">
                                <div className='center gap-2'>
                                  <h4 className="mb-0 payment-amount">
                                    ₹{parseFloat(item?.amount).toFixed(2)}
                                  </h4>
                                  <svg width="16" height="16" fill="currentColor" className={`text-${getStatusClass(item?.status?.toLowerCase())}`}  viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                                  </svg>
                                </div>
                                <div className="center gap-2">
                                  <span className="payment-date no-wrap">
                                    {new Date(item?.createdAt).toLocaleString("default", {
                                      day: "2-digit",
                                      month: "short",
                                    })}
                                  </span>
                                  <span className={`text-${getStatusClass(item?.status?.toLowerCase())} payment-status`}>
                                    {item?.status?.toLowerCase()}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                    
                          {/* {showModal && <TrackOrderModal onClose={handleCloseModal} item={item} />} */}
                        </>
                      ))}
                    </div>
                  ) : (
                      <div className="text-center" style={{minHeight: '300px'}}>
                        <div className="center my-4 text-secondary">
                          No order found
                        </div>
                      </div>
                  )}
                </div>
                :
                <div className="center py-3">
                  <div className="spinner-border m-5 text-center" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              }
            </div>

            <span className="center mt-4">
              <small>
                <p className="mb-0 fw-bold">
                  All Rights Reserved © 2024 | TOPUP PLAYGROUND
                </p>
                <p className="mb-0">
                  <span>Website designed & developed by</span>
                  <Link
                    target="_blank"
                    className="text-danger"
                    to="https://aashirdigital.com/"
                  >
                    ~@aashirdigital
                  </Link>
                </p>
              </small>
            </span>
          </div>
        </div>

        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
      </Layout>
    </>
  );
};

export default TrackOrder;
