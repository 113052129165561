import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPass from "./pages/ForgotPass";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Dashboard from "./user/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import ResetPassword from "./pages/ResetPassword";
import AdminUsers from "./admin/AdminUsers";
import EditUser from "./admin/EditUser";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import FrequentlyAskedQuestions from "./pages/FrequentlyAskedQuestions";
import ReferAndEarn from "./user/ReferAndEarn.js";
import Search from "./pages/Search";
import ProductInfo from "./pages/ProductInfo";
// import Cart from "./pages/Cart";
// import Checkout from "./pages/Checkout";
import TrackOrder from "./pages/TrackOrder";
import Orders from "./user/Orders";
import Address from "./user/Address";
import Account from "./user/Account";
import ViewOrder from "./user/ViewOrder";
import PhotoUpload from "./admin/PhotoUpload";
import Wallet from "./user/Wallet";
import ProductPage from "./pages/ProductPage";
import GiveAway from "./pages/GiveAway";
import Loader from "./pages/Loader";
import Maintenance from "./pages/Maintenance";
import Test from "./pages/Test";
import Query from "./user/Query.js";
import AllQueries from "./user/AllQueries.js";
import ReferAndEarnMobile from "./user/ReferAndEarnMobile.js";
import ProductInfoMobile from "./pages/ProductInfoMobile/ProductInfoMobile.js";
import { useDispatch } from "react-redux";
import getUserData from "./utils/userDataService.js";
import { setUser } from "./redux/features/userSlice.js";
import MobileLogin from "./pages/MobileLoginRegisterEtc/MobileLogin.js";
import MobileRegister from "./pages/MobileLoginRegisterEtc/MobileRegister.js";
import MobileForgotPass from "./pages/MobileLoginRegisterEtc/MobileForgotPass.js";
import PrivacyPolicyMobile from "./pages/PrivacyT&CRefundPolicy/PrivacyPolicyMobile.js";
import TermsAndConditionMobile from "./pages/PrivacyT&CRefundPolicy/TermsAndConditionMobile.js";
import RefundPolicyMobile from "./pages/PrivacyT&CRefundPolicy/RefundPolicyMobile.js";
//? ADMIN
import AdminDashboard from "./admin/AdminDashboard";
import AdminPayments from "./admin/AdminPayments";
import AdminBulkEmail from "./admin/AdminBulkEmail";
import AdminLogin from "./admin/AdminLogin";
import AdminProduct from "./admin/AdminProduct";
import AdminAddProduct from "./admin/AdminAddProduct";
import AdminEditProduct from "./admin/AdminEditProduct";
import AdminAddBrand from "./admin/AdminAddBrand";
import AdminOrder from "./admin/AdminOrder";
import AdminViewOrder from "./admin/AdminViewOrder";
import AdminAddCoupon from "./admin/AdminAddCoupon";
import AdminQueries from "./admin/AdminQueries";
import AdminBarcodePayments from "./admin/AdminBarcodePayments";
import AdminNotification from "./admin/AdminNotification";
import AdminBanners from "./admin/AdminBanners.js";
import AdminReferAndEarn from "./admin/AdminReferAndEarn.js";
import AdminGallery from "./admin/AdminGallery.js";

import { setBanners, setNotification } from "./redux/features/dataSlice.js";
import getAllProducts from "./utils/productDataService.js";
import Success from "./pages/SuccessAndFailure/Success.js";
import Failure from "./pages/SuccessAndFailure/Failure.js";
import Reports from "./user/Reports/Reports.js";
import AdminAddMoney from "./admin/AdminAddMoney.js";
import { setShowEventPopupModal, setShowPopup } from "./redux/features/alertSlice.js";
import ContactUs from "./pages/ContactUs.js";
import UserWallet from "./user/user-wallet/UserWallet.js";
import Announcement from "./pages/Announcement.js";
import Leaderboard from "./pages/Leaderboard.js";
import BlogList from "./pages/BlogList.js";
import BlogDetail from "./pages/BlogDetail.js";
import AddBlog from "./admin/AddBlog.js";
import AddRewardList from "./admin/AddRewardList.js";
import Maintenance2 from "./pages/Maintenance/Maintenance2.js";
import AddProductPackagesCategory from "./admin/AddProductPackagesCategory.js";
import AdminWalletHistory from "./admin/AdminWalletHistory.js";
import Payments from "./user/Payments.js";

function App() {
  const dispatch = useDispatch();
  const [website, setWebsite] = useState(true);
  const [loading, setLoading] = useState(false);

  async function getWebsite() {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-website");
      if (res.data.success) {
        setWebsite(res.data.data.website);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (website) {
      getUserData(dispatch);
      getAllProducts(dispatch);
      getWebsite();
    }
    // eslint-disable-next-line
  }, [website]);

  async function getBanners() {
    try {
      const res = await axios.get("/api/banner/get-banners", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        dispatch(setBanners(res.data.data));
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getNotification = async () => {
    try {
      const res = await axios.get("/api/noti/get-noti");
      if (res.data.success) {
        const { image, title, desc, link, display } = res.data.data[0];
        dispatch(setNotification({ image, title, desc, link, display }));
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNotification();
    getBanners();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
      dispatch(setShowEventPopupModal(true)); 
      dispatch(setShowPopup(true));
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      {loading ? (
        <Loader />
      ) : website ? (
        <Routes>
          {/* pages */}
          <Route path="/:token?" element={<Home />} />
          <Route
            path="/register"
            element={
              <PublicRoute>
                {isMobile ? <MobileRegister /> : <Register />}
              </PublicRoute>
            }
          />

          <Route
            path="/register/:sponsor"
            element={
              <PublicRoute>
                {isMobile ? <MobileRegister /> : <Register />}
              </PublicRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                {isMobile ? <MobileLogin /> : <Login />}
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={isMobile ? <MobileForgotPass /> : <ForgotPass />}
          />
          <Route path="/give-away" element={<GiveAway />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/games/:name?" element={<ProductPage />} />
          <Route path="/search" element={<Search />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/announcement" element={<Announcement />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/success" element={<Success />} />
          <Route path="/failure" element={<Failure />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:blogTitle" element={<BlogDetail />} />
          <Route
            path="/product/:name/:category"
            element={isMobile ? <ProductInfoMobile setWebsite={setWebsite}/> : <ProductInfo />}
          />
          <Route
            path="/product/:name"
            element={isMobile ? <ProductInfoMobile setWebsite={setWebsite}/> : <ProductInfo />}
          />
          {/* <Route path="/cart" element={<Cart />} /> */}
          {/* <Route path="/checkout" element={<Checkout />} /> */}
          {/* <Route path="/checking-status" element={<CheckStatus />} /> */}
          {/* <Route path="/faq" element={<FrequentlyAskedQuestions />} /> */}
          <Route
            path="/refer-and-earn"
            element={
              <ProtectedRoute>
                {isMobile ? <ReferAndEarnMobile /> : <ReferAndEarn />}
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wallet"
            element={
              <ProtectedRoute>
                <Wallet />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payments"
            element={
              <ProtectedRoute>
                <Payments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-wallet"
            element={
              <ProtectedRoute>
                <UserWallet />
              </ProtectedRoute>
            }
          />
          <Route
            path="/query"
            element={
              <ProtectedRoute>
                <Query />
              </ProtectedRoute>
            }
          />
          <Route
            path="/all-queries"
            element={
              <ProtectedRoute>
                <AllQueries />
              </ProtectedRoute>
            }
          />
          <Route
            path="/track-order"
            element={
              <ProtectedRoute>
                <TrackOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/address"
            element={
              <ProtectedRoute>
                <Address />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-order/:orderId?"
            element={
              <ProtectedRoute>
                <ViewOrder />
              </ProtectedRoute>
            }
          />
          {/* <Route path="/service" element={<Service />} /> */}
          <Route
            path="/terms-and-conditions"
            element={isMobile ? <TermsAndConditionMobile /> : <Terms />}
          />
          <Route
            path="/privacy-policy"
            element={isMobile ? <PrivacyPolicyMobile /> : <PrivacyPolicy />}
          />
          <Route
            path="/refund-policy"
            element={isMobile ? <RefundPolicyMobile /> : <RefundPolicy />}
          />
          {/* ======================== USER PAGES =============================== */}
          {/* ========== EMAIL VERIFY */}
          <Route
            path="/user-dashboard/:token?"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          {/* ======================== USER PAGES =============================== */}
          {/* ======================== ADMIN PAGES =============================== */}
          <Route
            path="/admin-login"
            element={
              <PublicRoute>
                <AdminLogin />
              </PublicRoute>
            }
          />
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-orders"
            element={
              <ProtectedRoute>
                <AdminOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-view-order/:orderId?"
            element={
              <ProtectedRoute>
                <AdminViewOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-products"
            element={
              <ProtectedRoute>
                <AdminProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-add-product"
            element={
              <ProtectedRoute>
                <AdminAddProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-edit-product/:id?"
            element={
              <ProtectedRoute>
                <AdminEditProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-add-brand"
            element={
              <ProtectedRoute>
                <AdminAddBrand />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-test"
            element={
              <ProtectedRoute>
                <PhotoUpload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-add-coupon"
            element={
              <ProtectedRoute>
                <AdminAddCoupon />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-users"
            element={
              <ProtectedRoute>
                <AdminUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-edit-user/:id?"
            element={
              <ProtectedRoute>
                <EditUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-payments"
            element={
              <ProtectedRoute>
                <AdminPayments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-payment"
            element={
              <ProtectedRoute>
                <AdminBarcodePayments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-wallet-history"
            element={
              <ProtectedRoute>
                <AdminWalletHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-bulk-email"
            element={
              <ProtectedRoute>
                <AdminBulkEmail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-queries"
            element={
              <ProtectedRoute>
                <AdminQueries />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-add-money"
            element={
              <ProtectedRoute>
                <AdminAddMoney />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-notification"
            element={
              <ProtectedRoute>
                <AdminNotification />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-refer-earn"
            element={
              <ProtectedRoute>
                <AdminReferAndEarn />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-banners"
            element={
              <ProtectedRoute>
                <AdminBanners />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-gallery"
            element={
              <ProtectedRoute>
                <AdminGallery />
              </ProtectedRoute>
            }
          />

          {/* Add Blog - Protected Route */}
        <Route 
          path="/add-blog" 
          element={
            <ProtectedRoute>
              <AddBlog />
            </ProtectedRoute>
          } 
        />

        {/* Update Blog - Protected Route */}
        <Route 
          path="/update-blog/:id" 
          element={
            <ProtectedRoute>
              <AddBlog />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/add-reward-list" 
          element={
            <ProtectedRoute>
              <AddRewardList />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/add-product-packages-category" 
          element={
            <ProtectedRoute>
              <AddProductPackagesCategory />
            </ProtectedRoute>
          } 
        />
          {/* ======================== ADMIN PAGES =============================== */}
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Maintenance2 setWebsite={setWebsite}/>} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin-login" element={<Login />} />
          <Route path="*" element={<Maintenance2 setWebsite={setWebsite}/>} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
