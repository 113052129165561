import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useNavigate } from "react-router-dom";
import axios, { all } from "axios";
import { useSelector } from "react-redux";
import { message, Spin } from "antd";
import "./Orders.css";
import FilterComponent from "./components/FilterComponent";

const Orders = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [allOrders, setAllOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orderLoader, setOrderLoader] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("thisWeek");

  const getAllUserOrders = async (start, end) => {
    setLoading(true);
    try {
      const res = await axios.post(
        "/api/order/get-user-orders",
        {
          email: user?.email,
          startDate: start,
          endDate: end,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
  
      if (res.data.success) {
        // If orders are found, reverse them (same as you did before)
        setAllOrders((res.data.data).reverse());
      } else {
        setAllOrders([]); // If no orders found, set empty array
        message.error(res.data.message);
      }
    } catch (error) {
      setAllOrders([]); // In case of error, set empty array
      message.error("Failed to fetch orders.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  
  const applyDefaultFilter = () => {
    const now = new Date();
    const start = new Date();
    start.setDate(now.getDate() - 7);
    const startISOString = start.toISOString();
    const endISOString = now.toISOString();
    setStartDate(startISOString);
    setEndDate(endISOString);
    setSelectedFilter("thisWeek");
    getAllUserOrders(startISOString, endISOString);
  };


  useEffect(() => {
    if (user) {
      applyDefaultFilter();
    }
    // eslint-disable-next-line
  }, [user]);

  async function getOrderStatus(orderId, iid, pid) {
    try {
      setOrderLoader(true);
      let response;
      if (iid) {
        response = await axios.post(
          "/api/indian/updateStatus",
          { iid: iid, orderId: orderId },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
      } else if (pid) {
        response = await axios.post(
          "/api/prime/updateStatus",
          { pid: pid, orderId: orderId },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
      }

      if (response.data.success) {
        getAllUserOrders();
        message.success(response.data.message);
        setOrderLoader(false);
      } else {
        message.error(response.data.message);
        setOrderLoader(false);
      }
    } catch (error) {
      console.log(error);
      setOrderLoader(false);
    }
  }

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-order-container">
          <h4 className="mb-3 fw-bold text-white">All Orders</h4>
          <FilterComponent
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            getUserPayments={getAllUserOrders}
          />
          {loading ? (
            <div className="rounded-4 center p-2 bg-white text-secondary overflow-scroll py-4">
              <Spin size="large" style={{ color: 'white' }}  />
            </div>
          ) : allOrders && allOrders?.length === 0 ? (

            <div className="rounded-4 gap-2 center p-2 bg-white text-secondary overflow-scroll py-4">
                Order history not available
                <button
                  className="btn text-decoration-underline"
                  onClick={() => navigate("/games")}
                >
                  Go to Shop
                </button>
            </div>
          ) : ( <div className="rounded-4 p-2 bg-white overflow-scroll">
            {allOrders?
              (<table className="table table-rounded w-100">
                <thead>
                  <tr>
                    <th className="no-wrap">
                      <small>Order ID</small>
                    </th>
                    <th>
                      <small>Product</small>
                    </th>
                    <th>
                      <small>Date</small>
                    </th>
                    <th>
                      <small>Status</small>
                    </th>
                    <th>
                      <small>Total</small>
                    </th>
                    <th>
                      <small>Action</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allOrders?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <small>{item?.orderId}</small>
                        </td>
                        <td>
                          <small className="no-wrap">{item?.productinfo}</small>
                        </td>
                        <td>
                          <small className="no-wrap">
                            {item?.createdAt
                              ? new Date(item?.createdAt).toLocaleString(
                                  "default",
                                  {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                  }
                                )
                              : ""}
                          </small>
                        </td>
                        <td>
                          <small
                            className={`fw-bold 
                                ${(item?.status === "pending" || item?.status === "processing") && "text-warning"}
                                ${item?.status === "success" && "text-success"}
                                ${item?.status === "refunded" && "text-primary"}
                                ${item?.status === "cancelled" && "text-dark"}
                                ${item?.status === "failed" && "text-danger"}
                            `}
                          >
                            {item?.status}
                          </small>
                        </td>
                        <td>
                          <small>{parseFloat(item?.amount).toFixed(2)}</small>
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              navigate(`/view-order/${item?.orderId}`)
                            }
                            className={`btn btn-sm
                                ${(item?.status === "pending" || item?.status === "processing") && "btn-warning"}
                                ${item?.status === "success" && "btn-success"}
                                ${item?.status === "refunded" && "btn-primary"}
                                ${item?.status === "cancelled" && "btn-dark"}
                                ${item?.status === "failed" && "btn-danger"}
                            `}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>)
              : (
                <div className="rounded-4 center p-2 bg-white text-secondary overflow-scroll py-4">
                    Order history not available
                </div>
                
              )
            }
            </div>
          )}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Orders;
